import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";
import constants from "../constants/constant";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ProfileMenu = () => {
  const studentImage = useSelector((state: any) => state.studentImage);
  const studentData = useSelector((state: any) => state.studentDetails);
  const imageUrl = studentImage.data;

  return (
    <>
      <Grid>
        <Grid
          sx={{
            background: constants.seconday,
            borderRadius: "8px",
            margin: "10px",
            height: "auto",
            justifyContent: "center",
            alignItems: "center",
            p: "0px 0px 10px 0px",
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              xs={4}
              sm={4}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
              alignItems="center"
              sx={{ p: "20px 0px 20px 0px" }}
            >
              <Avatar
                alt=""
                src={imageUrl ? imageUrl : ""}
                sx={{ width: 100, height: 100 }}
              />
            </Grid>
            <Grid container xs={8} sm={8} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "0px 10px 0px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    p: "0px 0px 0px 0px",
                    textTransform: "capitalize",
                    fontWeight: "500",
                    color: "#414348",
                    fontSize: "16px",

                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.first_name
                    ? studentData.data?.first_name
                    : ""}{" "}
                  {studentData?.data && studentData?.data?.last_name
                    ? studentData.data?.last_name
                    : ""}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "5px 10px 0px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                    color: " #667085",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.other_school
                    ? studentData.data?.other_school
                    : studentData.data?.school}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "5px 10px 0px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocationOnIcon sx={{ color: "#667085" }} />
                <Typography
                  sx={{
                    p: "0px 0px 0px 0px",
                    textTransform: "capitalize",
                    color: "#667085",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.city
                    ? studentData.data?.city
                    : ""}{" "}
                  /{" "}
                  {studentData?.data && studentData?.data?.state
                    ? studentData.data?.state
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileMenu;
