import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Lottie from "lottie-react";
import emailSent from "../assets/jsonData/emailSent.json";
import http from "../config/http-common";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import constants from "../constants/constant";
const PostRegisterPage = () => {
  const location = useLocation();
  const state = location.state;
  const verifyEmail = async () => {
    try {
      const response = await http.post("/api/sendverifyemail", {
        email: state,
      });
      const result: any = response.data;
      console.log(result.response.data);
      toast.success("Verfication email sent successfully");
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        toast.error(error.response.data.errorMessage);
      }
      if (error.response.data.errMsg) {
        console.log(error.response.data.errMsg);
        toast.error(error.response.data.errMsg);
      }
      if (error.response.data.message) {
        console.error("Error creating user:", error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        console.error("Network error:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Grid
        sx={{
          p: "50px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            background: constants.seconday,
            borderRadius: "10px",
            border: `2px solid ${constants.tertiary}`,
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(100vw - 100px)",
              lg: "calc(30vw - 100px)",
              xl: "calc(30vw - 100px)",
            },
          }}
        >
          <Grid
            sx={{
              p: "10px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "15px",
                color: "black",
              }}
            >
              Verify your email Address
            </Typography>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie animationData={emailSent} />
            </Grid>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "15px",
                color: "black",
              }}
            >
              check your email Inbox , please!
            </Typography>
          </Grid>

          <Grid
            sx={{
              p: "20px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                color: "black",
              }}
            >
              To complete your sign up,Please verify your email address
            </Typography>
          </Grid>
          <Grid
            sx={{
              p: "20px 0px 60px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                display: "flex",
                color: "black",
              }}
            >
              To send email verification link again
              <Grid
                sx={{
                  fontWeight: 600,
                  fontSize: "10px",
                  p: "0px 10px 0px 10px",
                  cursor: "pointer",
                  color: constants.tertiary,
                }}
                onClick={verifyEmail}
              >
                {" "}
                Click here!
              </Grid>
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default PostRegisterPage;
