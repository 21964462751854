import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import meloLogo from "../assets/images/logo/melo_logo.png";
import studentlogo from "../assets/images/loginRegister/student.png";
import teacherlogo from "../assets/images/loginRegister/teachers.png";
import collegelogo from "../assets/images/loginRegister/college.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logRegStyle } from "../constants/loginRegisterStyles";
import axiosInstance from "../config/http-common";
import { toast } from "react-toastify";
import constants from "../constants/constant";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ForgetPassForm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeButton, setActiveButton] = useState("student");
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    userType: activeButton,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleButtonChange = (e: any) => {
    const { value } = e.target;
    setActiveButton(value);
  };

  const handleShowPass = () => {
    setShowPass(!showPass);
  };
  const handleShowPass1 = () => {
    setShowPass1(!showPass1);
  };

  const see = showPass ? "text" : "password";
  const see1 = showPass1 ? "text" : "password";

  const imgSrc =
    activeButton === "student"
      ? studentlogo
      : activeButton === "teacher"
      ? teacherlogo
      : collegelogo;

  const location = useLocation();
  const parts = location.pathname.split("/");
  // const hashPass = parts[parts.length - 1];
  const id = parts[parts.length - 1];

  const changePassword = async (event: any) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
    } else {
      const changePass = {
        id: id,
        // hashPassword: hashPass,
        newPassword: formData.password,
      };

      try {
        await axiosInstance.post("/api/changepassword", changePass);
        toast.success("password changed successfully");
        navigate("../login");
      } catch (error: any) {
        if (error.response && error.response.data) {
          console.error("Error changing Password:", error.response.data);
        } else {
          console.error("Error changing password:", error.message);
        }
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { ...logRegStyle.containerFlexDirection },
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Paper
                elevation={3}
                sx={{
                  ...logRegStyle.paperStyle,
                }}
              >
                <img
                  src={meloLogo}
                  alt="melo logo"
                  style={{
                    height: isMobile ? "70px" : "30%",
                  }}
                />
                <img
                  src={imgSrc}
                  alt="student vector"
                  style={{
                    height: isMobile ? "100px" : "auto",
                    padding: isMobile ? "0px 0px 0px 30px" : "0px",
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box
                sx={{
                  ...logRegStyle.boxStyle,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    ...logRegStyle.gridSytles.styleOne,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      ...logRegStyle.fontSize.sizeOne,
                    }}
                  >
                    Sign Up as
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Button
                    name="userType"
                    value="student"
                    variant="outlined"
                    sx={{
                      size: { isMobile } ? "small" : "large",
                      background:
                        activeButton === "student" ? constants.tertiary : "",
                      color:
                        activeButton === "student"
                          ? "#FFF"
                          : constants.tertiary,
                      ...logRegStyle.roleButton,
                    }}
                    onClick={handleButtonChange}
                  >
                    Student
                  </Button>
                  <Button
                    name="userType"
                    value="teacher"
                    variant="outlined"
                    sx={{
                      size: { isMobile } ? "small" : "large",
                      background:
                        activeButton === "teacher" ? constants.tertiary : "",
                      color:
                        activeButton === "teacher"
                          ? "#FFF"
                          : constants.tertiary,
                      ...logRegStyle.roleButton,
                    }}
                    onClick={handleButtonChange}
                  >
                    Teacher
                  </Button>
                  <Button
                    name="userType"
                    value="college"
                    variant="outlined"
                    sx={{
                      size: { isMobile } ? "small" : "large",
                      background:
                        activeButton === "college" ? constants.tertiary : "",
                      color:
                        activeButton === "college"
                          ? "#FFF"
                          : constants.tertiary,
                      ...logRegStyle.roleButton,
                    }}
                    onClick={handleButtonChange}
                  >
                    College
                  </Button>
                </Grid>
                <Grid
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleTwo,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      ...logRegStyle.Typo,
                    }}
                  >
                    If you already have an account registered
                  </Typography>
                  <Typography
                    sx={{
                      ...logRegStyle.Typo,
                      display: "flex",
                    }}
                  >
                    You can
                    <Grid
                      sx={{
                        ...logRegStyle.logRegHereGrid,
                      }}
                      onClick={() => navigate("/login")}
                    >
                      {" "}
                      Login here!
                    </Grid>
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleThree,
                    },
                  }}
                >
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    variant="standard"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <MailOutlineIcon sx={{ color: constants.tertiary }} />
                      ),
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleThree,
                    },
                  }}
                >
                  <TextField
                    id="pass"
                    label="Password"
                    variant="standard"
                    type={see}
                    name="password"
                    placeholder="Enter Password"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <LockIcon sx={{ color: constants.tertiary }} />
                      ),
                      endAdornment: (
                        <IconButton onClick={handleShowPass}>
                          {showPass ? (
                            <VisibilityOffIcon color="disabled" />
                          ) : (
                            <VisibilityIcon color="disabled" />
                          )}
                        </IconButton>
                      ),
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleThree,
                    },
                  }}
                >
                  <TextField
                    id="conPass"
                    label="Confirm Password"
                    variant="standard"
                    type={see1}
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <LockIcon sx={{ color: constants.tertiary }} />
                      ),
                      endAdornment: (
                        <IconButton onClick={handleShowPass1}>
                          {showPass1 ? (
                            <VisibilityOffIcon color="disabled" />
                          ) : (
                            <VisibilityIcon color="disabled" />
                          )}
                        </IconButton>
                      ),
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                {/* <Grid
              sx={{
                p: "0px 30px 60px 0px",
                direction: "row",
                display: "flex",
                justifyContent: "space-between",
                color: "#4D4D4D",
              }}
            >
              <FormControlLabel control={<Checkbox />} label="Remember me" />
              <Typography sx={{ p: "10px 0px 0px 0px", color: "#4D4D4D" }}>
                Forger password?
              </Typography>
            </Grid> */}
                <Grid>
                  <Button
                    variant="contained"
                    sx={{
                      size: { isMobile } ? "small" : "large",
                      ...logRegStyle.logRegButton,
                      backgroundColor: constants.tertiary,
                    }}
                    fullWidth
                    onClick={changePassword}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: {
                ...logRegStyle.containerFlexDirection,
              },
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box
                sx={{
                  ...logRegStyle.boxStyle,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleOne,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: {
                        ...logRegStyle.fontSize.sizeOne,
                      },
                    }}
                  >
                    Enter New Password
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleThree,
                    },
                  }}
                >
                  <TextField
                    id="pass"
                    label="Password"
                    variant="standard"
                    type={see}
                    name="password"
                    placeholder="Enter Password"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <LockIcon sx={{ color: constants.tertiary }} />
                      ),
                      endAdornment: (
                        <IconButton onClick={handleShowPass}>
                          {showPass ? (
                            <VisibilityOffIcon color="disabled" />
                          ) : (
                            <VisibilityIcon color="disabled" />
                          )}
                        </IconButton>
                      ),
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid
                  sx={{
                    p: {
                      ...logRegStyle.gridSytles.styleThree,
                    },
                  }}
                >
                  <TextField
                    id="conPass"
                    label="Confirm Password"
                    variant="standard"
                    type={see1}
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <LockIcon sx={{ color: constants.tertiary }} />
                      ),
                      endAdornment: (
                        <IconButton onClick={handleShowPass1}>
                          {showPass1 ? (
                            <VisibilityOffIcon color="disabled" />
                          ) : (
                            <VisibilityIcon color="disabled" />
                          )}
                        </IconButton>
                      ),
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    sx={{
                      size: { isMobile } ? "small" : "large",
                      ...logRegStyle.logRegButton,
                      backgroundColor: constants.tertiary,
                    }}
                    fullWidth
                    onClick={changePassword}
                  >
                    Change password
                  </Button>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Paper
                elevation={3}
                sx={{
                  ...logRegStyle.paperStyle,
                }}
              >
                {/* <img
                  src={meloLogo}
                  alt="melo logo"
                  style={{
                    height: isMobile ? "70px" : "100px",
                  }}
                /> */}
                <img
                  src={imgSrc}
                  alt="student vector"
                  style={{
                    height: isMobile ? "100px" : "250px",
                    padding: isMobile ? "0px 0px 0px 30px" : "70px 0px 0px 0px",
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ForgetPassForm;
