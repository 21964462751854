import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import constants from "../constants/constant";
import { toast } from "react-toastify";
import { searchStudentData } from "../redux/slices/searchStudentSlice";
import { useNavigate } from "react-router-dom";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(TextField)(({ theme }: any) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Search = () => {
  const navigate = useNavigate()
  const allstudentData = useSelector((state: any) => state.allStudents);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef: any = useRef(null);

  const alldata = allstudentData.data;


  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setQuery(value);
    setShowSuggestions(true);
    if (value.trim() === "") {
      setSearchResults([]);
    } else {
      const filteredResults = alldata.filter(
        (student: any) =>
          student.state.toLowerCase().includes(value.toLowerCase()) ||
          student.city.toLowerCase().includes(value.toLowerCase()) ||
          student.school.toLowerCase().includes(value.toLowerCase()) ||
          student.other_school.toLowerCase().includes(value.toLowerCase()) ||
          student.graduating_class
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          student.first_name.toLowerCase().includes(value.toLowerCase()) ||
          student.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = async (id: any) => {
    try {
      dispatch(searchStudentData(id)).then(() => {
        navigate(`/search/student/${id}`);
      });
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
    setShowSuggestions(false)
  };

  return (
    <>
      <Grid sx={{ p: "10px 0px 10px 0px" }}>
        <div ref={wrapperRef} style={{ position: "relative", width: "auto" }}>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: constants.icons, zIndex: 1 }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            value={query}
            onChange={handleInputChange}
            inputProps={{ "aria-label": "search" }}
            sx={{ background: "#F9F9FC", borderRadius: "4px solid" }}
          />
          {showSuggestions && searchResults.length > 0 && (
            <Paper
              style={{
                position: "absolute",
                top: "40px",
                width: "100%",
                zIndex: 1,
                right:"0px"
              }}
            >
              <List>
                {searchResults.map((student: any, index: any) => (
                  <>
                    <ListItem
                      button
                      key={index}
                      onClick={() => handleSearch(student?.user_id)}
                    >
                      <ListItemText
                        primary={`${student.first_name} ${student.last_name}`}
                        secondary={`${student.school}, ${student.city}, ${student.state}`}
                        sx={{
                          "& .MuiTypography-body2": { fontSize: "8px" },
                        }}
                      />
                      <ListItemAvatar>
                        <Avatar src={student.studentImageURL}>
                          {`${student.first_name.charAt(
                            0
                          )}${student.last_name.charAt(0)}`}
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                  </>
                ))}
              </List>
            </Paper>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Search;
