import React, {  useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import http from "../config/http-common";
import cs from "../assets/images/formVector/cservices.png";
// import CsTable from "../layouts/CsTable";

import constants from "../constants/constant";
import LoadingPage from "../utils/Loading";
import CsDataList from "../layouts/CsDataList";
import { toast } from "react-toastify";
import AlertCsAddDialogSlide from "../layouts/AlertCsAddDialogSlide";

const CustomRadioButton = ({ value, selectedValue }: any) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ display: "none" }} />}
    label={
      <Typography
        sx={{
          fontSize: "12px",
          padding: "10px 20px",
          borderRadius: "25px",
          border:
            selectedValue === value ? "2px solid #FFA500" : "1px solid #ccc",
          backgroundColor: selectedValue === value ? "#FFF4E0" : "#F6F6F6",
          color: selectedValue === value ? "#FFA500" : "#000",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
    }
  />
);

const AddCsSection = ({ handleAdd, class_state }: any) => (
  <Grid
    container
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p: "50px 0px 0px 0px",
    }}
  >
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "0px 0px 0px 0px",
      }}
    >
      <img
        src={cs}
        alt="melo logo"
        style={{
          height: "70px",
        }}
      />
    </Grid>

    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        p: "20px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: constants.notAdded,
        }}
      >
        No Community services added
      </Typography>
    </Grid>
  </Grid>
);

const SummaryCsList = ({ actData }: any) => {
  const activity = actData;
  const loading = false;

  const [activeClassButton, setActiveClassButton] = useState("Freshman");

  const [selectedValue, setSelectedValue] = useState("Freshman");

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    setActiveClassButton(event.target.value);
  };

  const freshData =
    activity && activity.filter((item: any) => item.class === "Freshman");
  const sophmoreData =
    activity && activity.filter((item: any) => item.class === "Sophmore");
  const jnrData =
    activity && activity.filter((item: any) => item.class === "Junior");
  const snrData =
    activity && activity.filter((item: any) => item.class === "Senior");

  const [addClass, setAddClass] = useState(null);
  const [addOpen, setAddOpen] = useState(false);

  const handleAdd = (class_state: any) => {
    setAddClass(class_state);
    setAddOpen(true);
  };

  const handleAddClose = async (confirmed: any, data: any) => {
    setAddOpen(false);
    if (confirmed && addClass) {
      try {
        const response = await http.post("/api/community/create", data);
        console.log(response);
        // toast.success("Awards Data Saved Successfully");
        window.location.reload();
      } catch (error: any) {
        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error saving data:", error.message);
          toast.error(error.message);
        }
      }
    } else {
      setAddClass(null);
    }
  };

  const count = activity.filter((item: any) => item.toDisplay).length;

  return (
    <div>
      {loading ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(50vw - 100px)",
              lg: "calc(50vw - 100px)",
              xl: "calc(50vw - 100px)",
            },
          }}
        >
          <LoadingPage />
        </Grid>
      ) : (
        <>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(50vw - 100px)",
                lg: "calc(50vw - 100px)",
                xl: "calc(50vw - 100px)",
              },
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "20px 0px 20px 0px" }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: constants.text,
                    fontWeight: "500",
                  }}
                >
                  Community services
                </Typography>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup row value={selectedValue} onChange={handleChange}>
                  <Grid
                    container
                    // xs={12}
                    // sm={12}
                    // md={6} // For medium and up, two buttons per row
                    // lg={6}
                    // xl={6}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: "10px",
                    }}
                  >
                    {["Freshman", "Sophmore", "Junior", "Senior"].map(
                      (label) => (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={3}
                          key={label}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CustomRadioButton
                            value={label}
                            selectedValue={selectedValue}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {activeClassButton === "Freshman" &&
              freshData &&
              freshData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Freshman List
                    </Typography>
                  </Grid>
                </>
              )}
            {activeClassButton === "Sophmore" &&
              sophmoreData &&
              sophmoreData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Sophmore List
                    </Typography>
                  </Grid>
                </>
              )}
            {activeClassButton === "Junior" &&
              jnrData &&
              jnrData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Junior List
                    </Typography>
                  </Grid>
                </>
              )}
            {activeClassButton === "Senior" &&
              snrData &&
              snrData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Senior List
                    </Typography>
                  </Grid>
                </>
              )}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              p: "10px 0px 0px 0px",
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
            }}
          >
            <Box sx={{ overflow: "auto" }}>
              {activeClassButton === "Freshman" &&
                freshData &&
                freshData.length !== 0 && (
                  <>
                    {/* <CsTable data={freshData} /> */}
                    {Array.isArray(freshData) &&
                      freshData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <CsDataList
                            data={data}
                            count={count}
                            isSummary={true}
                          />
                        ))}
                  </>
                )}
              {activeClassButton === "Freshman" &&
                freshData &&
                freshData.length === 0 && (
                  <>
                    <AddCsSection
                      handleAdd={handleAdd}
                      class_state="Freshman"
                    />
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                sophmoreData &&
                sophmoreData.length !== 0 && (
                  <>
                    {/* <CsTable data={sophmoreData} /> */}
                    {Array.isArray(sophmoreData) &&
                      sophmoreData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <CsDataList
                            data={data}
                            count={count}
                            isSummary={true}
                          />
                        ))}
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                sophmoreData &&
                sophmoreData.length === 0 && (
                  <>
                    <AddCsSection
                      handleAdd={handleAdd}
                      class_state="Sophmore"
                    />
                  </>
                )}
              {activeClassButton === "Junior" &&
                jnrData &&
                jnrData.length !== 0 && (
                  <>
                    {/* <CsTable data={jnrData} /> */}
                    {Array.isArray(jnrData) &&
                      jnrData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <CsDataList
                            data={data}
                            count={count}
                            isSummary={true}
                          />
                        ))}
                  </>
                )}
              {activeClassButton === "Junior" &&
                jnrData &&
                jnrData.length === 0 && (
                  <>
                    <AddCsSection handleAdd={handleAdd} class_state="Junior" />
                  </>
                )}
              {activeClassButton === "Senior" &&
                snrData &&
                snrData.length !== 0 && (
                  <>
                    {/* <CsTable data={snrData} /> */}
                    {Array.isArray(snrData) &&
                      snrData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <CsDataList
                            data={data}
                            count={count}
                            isSummary={true}
                          />
                        ))}
                  </>
                )}
              {activeClassButton === "Senior" &&
                snrData &&
                snrData.length === 0 && (
                  <>
                    <AddCsSection handleAdd={handleAdd} class_state="Senior" />
                  </>
                )}
            </Box>
          </Grid>
          {addClass && (
            <AlertCsAddDialogSlide
              open={addOpen}
              onClose={handleAddClose}
              data={addClass}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SummaryCsList;
