import { Button,  Grid, IconButton, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import meloLogo from "../assets/images/logo/melo_logo.png";
import lock from "../assets/images/loginRegister/siginin.png";
import constants from "../constants/constant";
import { logRegStyle } from "../constants/loginRegisterStyles";
import { toast } from "react-toastify";
import { getStudentData } from "../redux/slices/studentDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getLoginSession } from "../redux/slices/loginSessionSlice";
import http from "../config/http-common";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingPage from "../utils/Loading";


const LoginV2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const user = useSelector((state: any) => state.loginDetails);
  const studentData = useSelector((state: any) => state.studentDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [showPass, setShowPass] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const path = location.pathname;
  console.log(path);
  const state = localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(false);
    const see = showPass ? "text" : "password";

  useEffect(() => {
    const storedFormData = Cookies.get("formData");
    const storedRememberMe = Cookies.get("rememberMe");

    if (storedRememberMe === "true" && storedFormData) {
      setFormData(JSON.parse(storedFormData));
      setRememberMe(true);
    }
    if (user && user?.data?.accessToken) {
      const accessToken = user?.data?.accessToken;
      const refreshToken = user.data.refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    }
    if (state) {
      try {
        dispatch(getStudentData());
      } catch (error: any) {
        // 50x code will come here
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";

        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error Logging in user:", error.message);
          toast.error(error.message);
        }
      }
    }

    if (user && user.isLoggedIn && user.data && !studentData.data) {
      navigate("../studentform");
    } else if (studentData && studentData.isDataFetched && studentData.data) {
      navigate("../home");
    }
  }, [navigate, user, state, dispatch, studentData]);


    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleShowPass = () => {
      setShowPass(!showPass);
    };

    function validateEmail(email: any) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }

    const mailvalidate = validateEmail(formData.email);

    const handleLogin = async (event: any) => {
      if (!formData.email) {
        toast.error("Please Enter Email Address");
      }
      if (!mailvalidate) {
        toast.error("Please Enter Valid Email Address");
      }

      if (!formData.password) {
        toast.error("Please Enter Password");
      }
      event.preventDefault();
      Cookies.set("rememberMe", rememberMe ? "true" : "false", {
        expires: 365,
      });
      if (rememberMe) {
        Cookies.set("formData", JSON.stringify(formData), { expires: 365 });
      }
      if (formData.password && formData.email  && mailvalidate) {
        const logData = {
          email: formData.email,
          password: formData.password,

        };
        try {
          dispatch(getLoginSession(logData))
            .then(() => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                navigate("../studentform");
              }, 2000);
            })
            .catch((error: any) => {
              console.error("Error dispatching action:", error);
            });
        } catch (error: any) {
          // 50x code will come here
          if (error.response && error.response.data) {
            console.error("Error LogResponse in user:", error.response.data);
            toast.error(error.response.data.errorMessage);
          } else {
            console.error("Error Logging in user:", error.message);
            toast.error(error.message);
          }
        }
      }
    };

    const forgetPassword = async () => {
      if (formData.email) {
        try {
          const response = await http.post("/api/forgetpassword/link", {
            email: formData.email,
          });
          const result: any = response.data;
          console.log(result.response.data);
          toast.success("Password Reset Mail sent Successfully!");
        } catch (error: any) {
          if (error.response.data.errorMessage) {
            console.log(error.response.data.errorMessage);
            toast.error(error.response.data.errorMessage);
          }
          if (error.response.data.errMsg) {
            console.log(error.response.data.errMsg);
            toast.error(error.response.data.errMsg);
          }
          if (error.response) {
            console.error("Error creating user:", error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            console.error("Network error:", error.message);
            toast.error(error.message);
          }
        }
      } else {
        toast.error("Please Enter email");  
      }
    };

    const handleJoinNow =()=> {
      navigate("../register")
    }

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  p: "20px 20px 20px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={meloLogo}
                  alt="melo logo"
                  style={{
                    height: isMobile ? "40px" : isTablet ? "40px" : "60px",
                  }}
                />
              </Grid>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  backgroundColor: "white",
                  borderRadius: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "5px",
                    xl: "5px",
                  },
                  margin: {
                    xs: "0px",
                    sm: "0px",
                    md: "0px",
                    lg: "20px",
                    xl: "20px",
                  },
                  maxWidth: {
                    lg: "440px",
                    xl: "440px",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "center",
                    xl: "center",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={lock}
                    alt="lock"
                    style={{
                      // height: isMobile ? "40px" : isTablet ? "40px" : "0px",
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>Sign In</Typography>
                </Grid>
                <Grid sx={{ p: "10px 0px 0px 0px" }}>
                  <Grid
                    sx={{
                      p: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <TextField
                      size="small"
                      id="email"
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <MailOutlineIcon sx={{ color: constants.tertiary }} />
                        ),
                        sx: {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: constants.tertiary,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: constants.tertiary,
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: constants.tertiary,
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={formData.email}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      p: "20px 0px 0px 0px",
                    }}
                  >
                    <TextField
                      size="small"
                      id="pass"
                      label="Password"
                      variant="outlined"
                      type={see}
                      name="password"
                      placeholder="Enter Password"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <LockIcon sx={{ color: constants.tertiary }} />
                        ),

                        endAdornment: (
                          <IconButton onClick={handleShowPass}>
                            {showPass ? (
                              <VisibilityOffIcon color="disabled" />
                            ) : (
                              <VisibilityIcon color="disabled" />
                            )}
                          </IconButton>
                        ),

                        sx: {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: constants.tertiary,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: constants.tertiary,
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: constants.tertiary,
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={formData.password}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      p: "10px 0px 40px 0px",
                      direction: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#4D4D4D",
                    }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      Remember Me
                    </label>

                    <Typography
                      sx={{
                        ...logRegStyle.forgetPasswordTypo,
                        color: constants.tertiary,
                      }}
                      onClick={forgetPassword}
                    >
                      Forget password?
                    </Typography>
                  </Grid>

                  <Grid>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "10px",
                        borderColor: constants.tertiary,
                        border: "1px solid #FE8D02",
                        fontSize: isMobile ? "0.875rem" : "1rem",
                        color: "white",
                        background:
                          "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        backgroundSize: "cover",
                        padding: isMobile ? "6px 16px" : "8px 24px",
                        "&:hover": {
                          background:
                            "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        },
                      }}
                      fullWidth
                      onClick={handleLogin}
                    >
                      Sign In
                    </Button>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      p: "20px 20px 20px 20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      Don't have an account?{" "}
                      <Link
                        sx={{
                          cursor: "pointer",
                          color: constants.tertiary,
                          textDecoration: "underline",
                          textDecorationColor: constants.tertiary,
                          textDecorationThickness: "2px",
                          textUnderlineOffset: "3px",
                          "&:hover": {
                            textDecorationColor: constants.tertiary,
                          },
                        }}
                        onClick={handleJoinNow}
                      >
                        Join Now
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default LoginV2;
