import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import http from "../config/http-common";
import { useLocation, useNavigate } from "react-router-dom";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import { styled } from "@mui/material/styles";
import constants from "../constants/constant";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const RadioBut = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));

const TestFormComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { class_state } = location.state || {};
  const [formData, setFormData] = useState<any>({
    test: class_state,
    english: "",
    maths: "",
    science: "",
    reading: "",
  });
  const [activeClassButton, setActiveClassButton] = useState(class_state);

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    var requiredKeys =
      formData.test === "ACT"
        ? ["test", "english", "maths", "science", "reading"]
        : ["test", "maths", "reading"];

    const checkFormCompleteness = () => {
      return requiredKeys.every(
        (key) =>
          formData[key] !== "" && formData[key] !== null && formData[key] !== 0
      );
    };

    setIsFormComplete(checkFormCompleteness());
  }, [formData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let newValue: any = parseInt(value, 10);

    if (isNaN(newValue)) {
      newValue = "";
    } else if (newValue < 0) {
      newValue = 0;
    }

    setFormData((prevFormData: any) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      if (
        prevFormData.test === "ACT" &&
        ((updatedFormData.english !== "" && updatedFormData.english > 36) ||
          (updatedFormData.maths !== "" && updatedFormData.maths > 36) ||
          (updatedFormData.science !== "" && updatedFormData.science > 36) ||
          (updatedFormData.reading !== "" && updatedFormData.reading > 36))
      ) {
        toast.error("Scores cannot exceed 36");
        updatedFormData[name] = 0;
      } else if (
        prevFormData.test === "SAT" &&
        ((updatedFormData.maths !== "" && updatedFormData.maths > 800) ||
          (updatedFormData.reading !== "" && updatedFormData.reading > 800))
      ) {
        toast.error("Scores cannot exceed 800");
        updatedFormData[name] = 0;
      }

      return updatedFormData;
    });
  };

  const handleExamButtonChange = (e: any) => {
    const { name, value } = e.target;
    setActiveClassButton(value);
    setFormData({ ...formData, [name]: value });
    if (value === "ACT") {
      setFormData({
        ...formData,
        test: "ACT",
        maths: "",
        reading: "",
        english: "",
        science: "",
      });
    }
    if (value === "SAT") {
      setFormData({
        ...formData,
        test: "SAT",
        maths: "",
        reading: "",
        english: "",
        science: "",
      });
    }
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      const response = await http.post("/api/testscores/create", formData);
      console.log(response);
      // toast.success("Awards Data Saved Successfully");
      navigate("../testscores/summary");
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
    // window.location.reload();
  };

  const maths = parseFloat(formData.maths ? formData.maths : 0);
  const english = parseFloat(formData.english ? formData.english : 0);
  const science = parseFloat(formData.science ? formData.science : 0);
  const reading = parseFloat(formData.reading ? formData.reading : 0);
  const averageTest =
    activeClassButton === "ACT"
      ? (maths + english + science + reading) / 4
      : maths + reading;
  const outOff = activeClassButton === "ACT" ? 36 : 1600;

  const handleBack = () => {
    navigate("./summary");
  };

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            variant="text"
            sx={{ color: constants.tertiary }}
            onClick={handleBack}
          >
            <KeyboardBackspaceIcon /> Back
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <RadioGroup
              aria-label="test"
              name="test-buttons"
              value={activeClassButton}
              onChange={(e) => setActiveClassButton(e.target.value)}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", p: "5px 10px 20px 10px" }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ p: "0px 10px 0px 0px" }}
                  onClick={handleExamButtonChange}
                >
                  <Grid
                    item
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="ACT"
                      control={<RadioBut />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              m: "2px",
                              p: "2px",
                              borderRadius: {
                                xs: "0",
                                sm: "0",
                                md: "0",
                                lg: "25px",
                                xl: "25px",
                              },
                            }}
                          >
                            ACT
                          </Typography>

                          <img
                            src={freshman}
                            alt="melo logo"
                            style={{
                              height: "30px",
                            }}
                          />
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ p: "0px 10px 0px 10px" }}
                  onClick={handleExamButtonChange}
                >
                  <Grid
                    item
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="SAT"
                      control={<RadioBut />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              m: "2px",
                              p: "2px",
                              borderRadius: {
                                xs: "0",
                                sm: "0",
                                md: "0",
                                lg: "25px",
                                xl: "25px",
                              },
                            }}
                          >
                            SAT
                          </Typography>

                          <img
                            src={sophmore}
                            alt="melo logo"
                            style={{
                              height: "30px",
                            }}
                          />
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography sx={{ fontWeight: "bold" }}>ACT Scores</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            {" "}
            <Typography sx={{ fontWeight: "bold" }}>
              Overall Scores:{averageTest}/{outOff}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>Maths Score*</Typography>
            </Grid>
            <TextField
              // size="small"
              id="outlined-basic"
              label="Maths Score"
              name="maths"
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              sx={{ fontSize: "12px" }}
              type="number"
              value={formData.maths}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Reading/Writing Score*
              </Typography>
            </Grid>
            <TextField
              // size="small"
              id="outlined-basic"
              label="Reading/Writing Score"
              name="reading"
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              sx={{ fontSize: "12px" }}
              type="number"
              value={formData.reading}
            />
          </Grid>
          {activeClassButton === "ACT" && (
            <>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "5px 0px 5px 0px" }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    English Score*
                  </Typography>
                </Grid>
                <TextField
                  // size="small"
                  id="outlined-basic"
                  label="English Score"
                  name="english"
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  sx={{ fontSize: "12px" }}
                  type="number"
                  value={formData.english}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "5px 0px 5px 0px" }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Science Score*
                  </Typography>
                </Grid>
                <TextField
                  // size="small"
                  id="outlined-basic"
                  label="Science Score"
                  name="science"
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  sx={{ fontSize: "12px" }}
                  type="number"
                  value={formData.science}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container sx={{ p: "5px 0px   0px 0px" }}>
          <Grid xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: "flex",
              // alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Grid sx={{ p: "0px 5px 0px 5px" }}>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{
                  backgroundColor: isFormComplete
                    ? constants.tertiary
                    : "default",
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TestFormComponent;
