import React, { useState } from "react";
import http from "../config/http-common";
import {
  Grid,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import constants from "../constants/constant";
// import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LoadingPage from "../utils/Loading";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import AlertDialogSlide from "../layouts/AlertDialogSlide";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import upload from "../assets/images/formVector/upload.png";
import pdf from "../assets/images/formVector/pdf2.png";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CustomRadioButton = ({ value, selectedValue }: any) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ display: "none" }} />}
    label={
      <Typography
        sx={{
          fontSize: "12px",
          padding: "10px 20px",
          borderRadius: "25px",
          border:
            selectedValue === value ? "2px solid #FFA500" : "1px solid #ccc",
          backgroundColor: selectedValue === value ? "#FFF4E0" : "#F6F6F6",
          color: selectedValue === value ? "#FFA500" : "#000",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
    }
  />
);

const TranscriptList = () => {
  const [loading, setLoading] = useState(false);
  const [activeClassButton, setActiveClassButton] = useState("Freshman");
  const [selectedValue, setSelectedValue] = useState("Freshman");

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    setActiveClassButton(event.target.value);
  };
  // const navigate = useNavigate();

  const marksheet = useSelector(
    (state: any) => state.studentFiles?.data[0]?.marksheet
  );

  const [doc, setDoc] = useState<any>(null);
  const [docBlob, setDocBlob] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        const arrayBufferReader: any = new FileReader();
        const dataURLReader: any = new FileReader();

        arrayBufferReader.onloadend = () => {
          const blob = new Blob([arrayBufferReader.result], {
            type: "application/pdf",
          });
          const blobUrl = URL.createObjectURL(blob);
          setDocBlob(blobUrl);
        };

        dataURLReader.onloadend = () => {
          setDoc(dataURLReader.result);
        };

        arrayBufferReader.readAsArrayBuffer(file);
        dataURLReader.readAsDataURL(file);
      } else {
        toast.error("Unsupported file type. Please upload a PDF.");
      }
    }
  };

  const freshData =
    marksheet && marksheet.filter((item: any) => item.class === "Freshman");
  const sophmoreData =
    marksheet && marksheet.filter((item: any) => item.class === "Sophmore");
  const jnrData =
    marksheet && marksheet.filter((item: any) => item.class === "Junior");
  const snrData =
    marksheet && marksheet.filter((item: any) => item.class === "Senior");

  const handleRemove = () => {
    setDoc(null);
  };

  const handleUpload = async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      const formData = {
        class: activeClassButton,
        doc: doc,
      };

      const response = await http.post("/api/student-transcript/upload", {
        formData,
      });
      console.log(response);
      setLoading(false);
      // toast.success("Awards Data Saved Successfully");
      window.location.reload();
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
  };

  const handleShow = (className: any) => {
    if (className === "freshman") {
      window.open(freshData[0]?.studentFileURL, "_blank");
    }
    if (className === "sophmore") {
      window.open(sophmoreData[0]?.studentFileURL, "_blank");
    }
    if (className === "jnr") {
      window.open(jnrData[0]?.studentFileURL, "_blank");
    }
    if (className === "snr") {
      window.open(snrData[0]?.studentFileURL, "_blank");
    }
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenDialog = (id: any) => {
    setDeleteId(id);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed: any) => {
    setDialogOpen(false);
    if (confirmed && deleteId) {
      try {
        const response = await http.delete(
          `/api/transcript/delete/${deleteId}`
        );
        const result = response.data;
        console.log(result.response.data);
        // toast.success("ACT Score deleted Successfully!");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } catch (error: any) {
        if (error.response) {
          console.error("Error deleting grade:", error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          console.error("Network error:", error.message);
          toast.error(error.message);
        }
      }
    }
  };



  return (
    <div>
      {loading ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <LoadingPage />
        </Grid>
      ) : (
        <>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "20px 0px 20px 0px" }}
          >
            <Grid>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: constants.text,
                  fontWeight: "500",
                }}
              >
                Transcripts
              </Typography>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioGroup row value={selectedValue} onChange={handleChange}>
                <Grid
                  container
                  // xs={12}
                  // sm={12}
                  // md={6} // For medium and up, two buttons per row
                  // lg={6}
                  // xl={6}
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "10px",
                  }}
                >
                  {["Freshman", "Sophmore", "Junior", "Senior"].map((label) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={3}
                      key={label}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CustomRadioButton
                        value={label}
                        selectedValue={selectedValue}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              p: "20px",
              border: "2px dashed #CCCCCC",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {!doc && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={upload} alt="upload icon" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                  xl={10}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    sx={{
                      fontSize: "14px",
                      color: "#FE8D02",
                      textTransform: "none",
                      padding: "5px 10px",
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: constants.primary,
                        // color: "white",
                      },
                    }}
                    size="small"
                    component="label"
                    variant="text"
                    startIcon={<CloudUploadIcon />}
                  >
                    Click to Upload{" "}
                    <span style={{ color: constants.typo }}>(PDF Format)</span>
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
              </>
            )}
            {doc && docBlob && (
              <>
                <Grid
                  item
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src={pdf} alt="pdf" style={{ height: "50px" }} />
                  <a
                    href={docBlob}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: constants.typo }}
                  >
                    <Typography variant="body2">View PDF</Typography>
                  </a>
                  <Button
                    sx={{
                      fontSize: "12px",
                      color: constants.typo,
                      marginTop: "10px",
                    }}
                    size="small"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    Remove
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      mt: "10px",
                      borderRadius: "10px",
                      borderColor: constants.tertiary,
                      border: "1px solid #FE8D02",
                      color: "white",
                      background:
                        "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                      "&:hover": {
                        background:
                          "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                      },
                      // width: "100%",
                    }}
                    onClick={handleUpload}
                  >
                    Submit
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                p: "20px 0px 0px 0px",
              }}
            >
              {((freshData && freshData.length > 0) ||
                (sophmoreData && sophmoreData.length > 0) ||
                (jnrData && jnrData.length > 0) ||
                (snrData && snrData.length > 0)) && (
                <Grid>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: constants.text,
                      fontWeight: "500",
                    }}
                  >
                    Uploads
                  </Typography>
                </Grid>
              )}
              <Grid container spacing={2}>
                {marksheet &&
                  marksheet.map((file: any, index: any) => (
                    <>
                    <Grid container direction="column" sx={{ padding: "0 10px" }}>

                      <Grid
                        container
                        key={index}
                        alignItems="center"
                        sx={{ padding: "10px 0" }}
                      >
                        <Grid
                          item
                          onClick={() => handleShow(file.name)}
                          sx={{ p: "10px 10px 10px 10px" }}
                        >
                          <Tooltip title="Click to open" arrow>
                            <img
                              src={pdf}
                              alt="pdf"
                              style={{ height: "32px" }}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs sx={{ p: "10px 10px 10px 10px" }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              color: constants.text,
                              fontWeight: "500",
                            }}
                          >
                            {file.class}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              color: constants.notAdded,
                              fontWeight: "500",
                            }}
                          >
                            uploaded on{" "}
                            {`${
                              new Date(Number(file?.uploadedAt)).getMonth() + 1
                            }/${new Date(
                              Number(file?.uploadedAt)
                            ).getDate()}/${new Date(
                              Number(file?.uploadedAt)
                            ).getFullYear()}`}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ p: "10px 0px 10px 10px" }}>
                          <IconButton
                            onClick={() => handleOpenDialog(file._id)}
                            sx={{ color: "#ff3333" }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{
                          borderColor: "rgba(0, 0, 0, 0.12)",
                          borderWidth: "1px",
                        }}
                      />
                    </Grid>
                    </>
                  ))}
              </Grid>
            </Grid>
            <AlertDialogSlide open={dialogOpen} onClose={handleDialogClose} />
          </Grid>
        </>
      )}
    </div>
  );
};

export default TranscriptList;
