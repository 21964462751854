import { Avatar, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import http from "../config/http-common";
import { useNavigate } from "react-router-dom";
import constants from "../constants/constant";
import EditIcon from "@mui/icons-material/Edit";
import LoadingPage from "../utils/Loading";

const StudentFormViewComponent = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>();
  const [imgData, setImgData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const state = localStorage.getItem("accessToken");
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear + 26; year++) {
    years.push(year);
  }

  useEffect(() => {
    const fetchAwards = async () => {
      setLoading(true);
      try {
        const response = await http.get("/api/student/data/");
        const imgResponse = await http.get("/api/student/files/");

        const result: any = response.data;
        const imgResult: any = imgResponse.data;
        setData(result.response.data[0]);
        setImgData(imgResult.response.data[0]);
      } catch (error: any) {
        if (error.response) {
          console.error("Error fetching awards:", error.response.data.message);
        } else {
          console.error("Network error:", error.message);
        }
      }
      setLoading(false);
    };

    if (state) {
      fetchAwards();
    }
  }, [state]);

  const studentImg = imgData ? imgData?.studentImageURL : "";

  const handleEdit = async (event: any) => {
    event.preventDefault();
    navigate("../account/edit");
  };
  // const handleOk = async (event: any) => {
  //   event.preventDefault();
  //   navigate("../home");
  // };

  const date = new Date(data?.class_year);
  const year = date.getUTCFullYear();
  return (
    <div>
      {loading ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <LoadingPage />
        </Grid>
      ) : (
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                width: {
                  xs: "calc(100vw - 100px)",
                  sm: "calc(100vw - 100px)",
                  md: "calc(55vw - 100px)",
                  lg: "calc(55vw - 100px)",
                  xl: "calc(55vw - 100px)",
                },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "20px 0px 0px 0px" }}
              >
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", color: constants.text }}
                    >
                      Account
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ color: "black" }}
                      onClick={handleEdit}
                    >
                      <EditIcon sx={{ fontSize: "15px" }} />
                      edit
                    </Button>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography sx={{ color: constants.typo }}>
                        Photo
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        sx={{ p: "20px 0px 20px 0px" }}
                      >
                        <Avatar
                          alt=""
                          src={studentImg ? studentImg : ""}
                          sx={{ width: 100, height: 100 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex" }}
                >
                  <Grid
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: "5px 0px 5px 0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      First Name{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.first_name}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: "5px 0px 5px 0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      Last Name :{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.last_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex" }}
                >
                  <Grid
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{ p: "5px 0px 5px 0px" }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      Student ID :{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.student_id}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{ p: "5px 0px 5px 0px" }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      Class :{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.graduating_class}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{ p: "5px 0px 5px 0px" }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      Class of :{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {year}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex" }}
                >
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ p: "5px 0px 5px 0px" }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      Bio :{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.bio}
                    </Typography>
                  </Grid>
                </Grid>

                {/* school data */}
                <Divider />

                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex" }}
                >
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      p: "5px 0px 5px 0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      School Name{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data && data?.other_school
                        ? data?.other_school
                        : data?.school}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex" }}
                >
                  <Grid
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: "5px 0px 5px 0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      State{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.state}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: "5px 0px 5px 0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      City{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.city}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex" }}
                >
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      p: "5px 0px 25px 0px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: constants.typo }}
                    >
                      School Address{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: constants.text,
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.school_address}, {data?.school_address2}{" "}
                      {data?.zipCode}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            p: "25px 0px 10px 0px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleOk}
            sx={{
              backgroundColor: constants.tertiary,
            }}
          >
            home
          </Button>
        </Grid> */}
          </Grid>
        </>
      )}
    </div>
  );
};

export default StudentFormViewComponent;
