import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../constants/constant";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { searchStudentData } from "../redux/slices/searchStudentSlice";
import { toast } from "react-toastify";
import SummaryDetailedViewComponent from "./SummaryDetailedViewComponent";

const SearchSummaryComponent = () => {
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split("/");
  const id = parts.pop();

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const studentData = useSelector((state: any) => state.searchStudentData);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    try {
      dispatch(searchStudentData(id)).then(() => {});
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
  }, [id, dispatch]);

  const searchData = studentData?.data[0];
  const imageUrl = searchData?.studentImageURL;

  const examDetails = searchData?.exam[0]?.examDetails || [];
  const testDetails = searchData?.test || [];
  const awardsDetails = searchData?.awards || [];
  const CsDetails = searchData?.communityService || [];
  const ecaDetails = searchData?.eca || [];
  const bio = searchData?.bio || "";

  const awardsData =
    awardsDetails.length > 0 &&
    awardsDetails.filter((item: any) => item.toDisplay === true);
  const csData =
    CsDetails.length > 0 &&
    CsDetails.filter((item: any) => item.toDisplay === true);
  const ecaData =
    ecaDetails.length > 0 &&
    ecaDetails.filter((item: any) => item.toDisplay === true);
  const awardNames =
    awardsData.length > 0
      ? awardsData.map((item: any) => item.award).join(" | ")
      : "";
  const csNames =
    csData.length > 0
      ? csData.map((item: any) => item.activity).join(" | ")
      : "";
  const ecaNames =
    ecaData.length > 0
      ? ecaData
          .map((item: any) =>
            item.ecaName === "Others" ? item.otherName : item.ecaName
          )
          .join(" | ")
      : "";

  const actData =
    testDetails.length > 0 &&
    testDetails.filter((item: any) => item.test === "ACT");
  const satData =
    testDetails.length > 0 &&
    testDetails.filter((item: any) => item.test === "SAT");

  const actScore = actData[0]?.total;
  const satScore = satData[0]?.total;

  const apFreshData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Freshman"
    );

  const apSophmoreData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Sophmore"
    );
  const apJnrData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Junior"
    );
  const apSnrData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Senior"
    );

  function calculateAverage(examDetails: any) {
    let sum = 0;

    for (let i = 0; i < examDetails?.length; i++) {
      sum += examDetails[i].subject[0].average_GPA;
    }
    const numberOfRows = examDetails?.length;
    const average = sum / numberOfRows;
    return average.toFixed(2);
  }

  const averageFreshGpa: number = Number(calculateAverage(apFreshData));
  const averageSophmoreGpa: number = Number(calculateAverage(apSophmoreData));
  const averageJnrGpa: number = Number(calculateAverage(apJnrData));
  const averageSnrGpa: number = Number(calculateAverage(apSnrData));

  const unWeightedFreshGpa = isNaN(averageFreshGpa) ? 0 : averageFreshGpa;
  const unWeightedSophmoreGpa = isNaN(averageSophmoreGpa)
    ? 0
    : averageSophmoreGpa;
  const unWeightedJnrGpa = isNaN(averageJnrGpa) ? 0 : averageJnrGpa;
  const unWeightedSnrGpa = isNaN(averageSnrGpa) ? 0 : averageSnrGpa;

  const gpas = [
    unWeightedFreshGpa,
    unWeightedSophmoreGpa,
    unWeightedJnrGpa,
    unWeightedSnrGpa,
  ];
  const nonZeroGpas = gpas.filter((gpa) => gpa !== 0);

  const UnWeGpa =
    (unWeightedFreshGpa +
      unWeightedSophmoreGpa +
      unWeightedJnrGpa +
      unWeightedSnrGpa) /
    nonZeroGpas.length;
  let totalUnWGpa: any = UnWeGpa.toFixed(2);

  if (isNaN(totalUnWGpa)) {
    totalUnWGpa = "";
  }

  const totalUnWeightedGpa = totalUnWGpa;

  const firstName = searchData?.first_name || "";
  const lastName = searchData?.last_name || "";

  const handleHome = () => {
    navigate("../home");
  };
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Paper
          elevation={3}
          sx={{
            background: "white",
            borderRadius: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "10px",
              xl: "10px",
            },
            margin: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "25px",
              xl: "25px",
            },
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(100vw - 100px)",
              lg: "calc(65vw - 100px)",
              xl: "calc(65vw - 100px)",
            },
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "center",
            },
            p: {
              xs: "30px",
              sm: "30px",
              md: "30px",
              lg: "20px 20px 20px 50px",
              xl: "20px 20px 20px 50px",
            },
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(65vw - 100px)",
                lg: "calc(65vw - 100px)",
                xl: "calc(65vw - 100px)",
              },
            }}
          >
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                sx={{ p: "20px 0px 20px 0px" }}
              >
                <Avatar
                  alt=""
                  src={imageUrl ? imageUrl : ""}
                  sx={{ width: 100, height: 100 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={10}
                xl={10}
                sx={{ p: "50px 10px 0px 10px" }}
              >
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: constants.text,
                  }}
                >
                  {" "}
                  {firstName ? firstName : ""} {lastName ? lastName : ""}
                </Typography>
                <Typography
                  sx={{
                    p: "10px 10px 0px 10px",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: constants.typo,
                  }}
                >
                  {" "}
                  {searchData && searchData?.other_school
                    ? searchData?.other_school
                    : searchData?.school}{" "}
                  , {searchData && searchData?.city ? searchData?.city : ""} ,{" "}
                  {searchData && searchData?.state ? searchData?.state : ""}
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, p: 1 }}>
                  {/* Graduating Class */}
                  {searchData?.graduating_class && (
                    <Chip
                      // icon={<ClassIcon />}
                      label={searchData.graduating_class}
                      sx={{
                        borderRadius: "16px",
                        p: "5px 10px",
                        fontSize: "14px",
                        backgroundColor: "#FFFBD7",
                        border: "1px solid #F6DE9D",
                      }}
                    />
                  )}

                  {/* Unweighted GPA */}
                  {totalUnWeightedGpa && (
                    <>
                      <Chip
                        // icon={<ScoreboardIcon />}
                        label={`Unweighted GPA: ${totalUnWeightedGpa}`}
                        sx={{
                          borderRadius: "16px",
                          p: "5px 10px",
                          fontSize: "14px",
                          backgroundColor: "#FFFBD7",
                          border: "1px solid #F6DE9D",
                        }}
                      />
                    </>
                  )}

                  {/* ACT Score */}
                  {actScore && (
                    <Chip
                      label={`ACT: ${actScore}/36`}
                      sx={{
                        borderRadius: "16px",
                        p: "5px 10px",
                        fontSize: "14px",
                        backgroundColor: "#FFFBD7",
                        border: "1px solid #F6DE9D",
                      }}
                    />
                  )}

                  {/* SAT Score */}
                  {satScore && (
                    <Chip
                      label={`SAT: ${satScore}/1600`}
                      sx={{
                        borderRadius: "16px",
                        p: "5px 10px",
                        fontSize: "14px",
                        backgroundColor: "#FFFBD7",
                        border: "1px solid #F6DE9D",
                      }}
                    />
                  )}

                  {/* Awards */}
                  {awardNames && (
                    <Chip
                      icon={
                        <EmojiEventsIcon sx={{ "&&": { color: "#F5A200" } }} />
                      }
                      label={awardNames}
                      sx={{
                        borderRadius: "16px",
                        p: "5px 10px",
                        fontSize: "14px",
                        backgroundColor: "#FFFBD7",
                        border: "1px solid #F6DE9D",
                      }}
                    />
                  )}

                  {/* Community Service */}
                  {csNames && (
                    <Chip
                      icon={
                        <Diversity2Icon sx={{ "&&": { color: "#F5A200" } }} />
                      }
                      label={csNames}
                      sx={{
                        borderRadius: "16px",
                        p: "5px 10px",
                        fontSize: "14px",
                        backgroundColor: "#FFFBD7",
                        border: "1px solid #F6DE9D",
                      }}
                    />
                  )}

                  {ecaNames && (
                    <Chip
                      icon={
                        <WorkspacePremiumOutlinedIcon
                          sx={{ "&&": { color: "#F5A200" } }}
                        />
                      }
                      label={ecaNames}
                      sx={{
                        borderRadius: "16px",
                        p: "5px 10px",
                        fontSize: "14px",
                        backgroundColor: "#FFFBD7",
                        border: "1px solid #F6DE9D",
                      }}
                    />
                  )}
                </Box>
              </Grid>

              {isMobile || isTablet ? (
                <></>
              ) : (
                <>
                  {bio && (
                    <>
                      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            m: "2px",
                            p: "2px",
                            borderRadius: {
                              xs: "0",
                              sm: "0",
                              md: "0",
                              lg: "25px",
                              xl: "25px",
                            },
                          }}
                        >
                          Bio:
                        </Typography>
                        <Typography
                          sx={{
                            p: "0px 10px 0px 5px",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: constants.text,
                          }}
                        >
                          {" "}
                          {bio}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>

            {showMore && (
              <>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SummaryDetailedViewComponent data={searchData} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            sx={{
              p: "10px 10px 0px 0px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              item
              sx={{
                p: "10px 10px 0px 0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={handleHome}
            >
              <Button variant="text" sx={{ color: constants.tertiary }}>
                Back to Home
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                p: "10px 10px 0px 0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={handleShowMore}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  borderColor: constants.tertiary,
                  border: "1px solid #FE8D02",
                  fontSize: "14px",
                  color: "white",
                  background:
                    "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                  backgroundSize: "cover",
                  "&:hover": {
                    background:
                      "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                  },
                }}
              >
                {showMore ? "Show Less" : "show more"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default SearchSummaryComponent;
