import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../config/http-common";
// import { toast } from "react-toastify";

export interface studentDataSlice {
  data: {} | null;
  isDataFetched: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: studentDataSlice = {
  data: {},
  isDataFetched: false,
  loading: false,
  error: "",
};

export const getStudentImage: any = createAsyncThunk(
  "studentImage",
  async () => {
    try {
      const res = await axiosInstance.get("/api/student/files/");
      return res.data.response.data[0].studentImageURL;
    } catch (err: any) {
      if (err.response.data.errorMessage) {
        console.log(err.response.data.errorMessage);
        // toast.error(err.response.data.errorMessage);
      }
      if (err.response.data.errMsg) {
        console.log(err.response.data.errMsg);
        // toast.error(err.response.data.errMsg);
      } else {
        console.log(err);
      }
    }
  }
);

export const studentImageSlice = createSlice({
  name: "studentImage",
  initialState,
  reducers: {
    remove: (state, action) => {
      state.data = {};
      state.isDataFetched = false;
      state.loading = false;
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStudentImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getStudentImage.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.isDataFetched = true;
          state.data = action.payload;
        }
      )
      .addCase(
        getStudentImage.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.isDataFetched = false;
          state.error = action.payload;
          state.data = {};
        }
      );
  },
});

export const { remove } = studentImageSlice.actions;
export default studentImageSlice.reducer;
