import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import StudentFormComponentV2 from "../components/StudentFormComponentV2";

const StudentForm = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.loginDetails);
  const studentData = useSelector((state: any) => state.studentDetails);

  useEffect(() => {
    if (user && user.isLoggedIn && user.data && !studentData.data) {
      navigate("../studentform");
    } else if (studentData && studentData.isDataFetched && studentData.data) {
      navigate("../home");
    } else {
      navigate("../login");
    }
  }, [navigate, user, dispatch, studentData]);

  return (
    <>

        <>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StudentFormComponentV2 />
          </Grid>
        </>
    </>
  );
};

export default StudentForm;
