import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, Tooltip, Typography } from "@mui/material";
import constants from "../constants/constant";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomCard = styled(Card)({
  elevation: 0,
  boxShadow: "none", // This removes the shadow
  borderRadius: "0px",
});

export default function PostCards({
  post,
  userName,
}: {
  post: any;
  userName: string;
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = (url:any) => {
    window.open(url, "_blank");
  };

  const [copiedText, setCopiedText] = React.useState<any>();


const handleCopy = (url: any) => {
  setCopiedText(url);
  setTimeout(() => {
    setCopiedText("");
  }, 1000); 
};
  return (
    <CustomCard sx={{ maxWidth: "100%", width: "100%", borderRadius: "0px" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {userName.charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={userName}
        subheader={new Date(post.updatedTime).toLocaleDateString()}
      />
      <CardMedia
        sx={{ borderRadius: "10px" }}
        component="img"
        height="100%"
        image={post.image}
        alt={post.caption}
      />
      <CardContent>
        <Typography sx={{ color: "#3498eb" }}>
          {post.tags.map((tag: string) => `#${tag}`).join(" ")}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>{post.title}</Typography>
        <Link
          sx={{ color: constants.tertiary }}
          onClick={() => handleLinkClick(post.url)}
        >
          {post.url}
        </Link>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton> */}
        <CopyToClipboard text={post.url} onCopy={() => handleCopy(post.url)}>
          <Tooltip
            title={copiedText === post.url ? "Copied!" : "Copy To Clipboard"}
            placement="top"
          >
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {" "}
          <Typography>{post.description}</Typography>
        </CardContent>
      </Collapse>
    </CustomCard>
  );
}
