const constants = {
  primary: "#E8E8EA",
  seconday: "#ffffff",
  tertiary: "#FE8D02",
  table: "#ededed",
  background: "#F4F2EE",
  text: "#414348",
  icons: "#878484",
  typo: "#52677A",
  notAdded: "#667085",
  tablebg: "#F4F4F4",
  tableTxt: "#667085",
};

export default constants;