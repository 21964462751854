import { Grid } from "@mui/material";
import React from "react";
import constants from "../constants/constant";
import Link from "@mui/material/Link";

const AgreementMenu = () => {
  return (
    <>
      <Grid>
        <Grid
          sx={{
            // background: constants.seconday,
            // borderRadius: "10px",
            // margin: "25px",
            // height: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Link
            href="#"
            underline="hover"
            color="black"
            sx={{
              marginBottom: "10px",
              color: constants.typo,
              "&:hover": {
                color: constants.tertiary,
              },
            }}
          >
            {"Terms and Condition"}
          </Link>
          <Link
            href="#"
            underline="hover"
            sx={{
              color: constants.typo,  
              "&:hover": {
                color: constants.tertiary,
              },
            }}
          >
            {"Privacy Policy"}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default AgreementMenu;
