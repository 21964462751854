import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import junior from "../assets/images/formVector/jnr.png";
import senior from "../assets/images/formVector/snr.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import http from "../config/http-common";
// import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { logRegStyle } from "../constants/loginRegisterStyles";
import gradesData from "../assets/jsonData/ipScore.json";
import subjectsData from "../assets/jsonData/rawSubject.json";
import { toast } from "react-toastify";
// import classList from "../assets/jsonData/class.json";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const GradeIbFormComponent = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeExamButton, setActiveExamButton] = useState("semester");

  const handleExamButtonChange = (e: any) => {
    const { value } = e.target;
    setActiveExamButton(value);
  };

  const gradesField: any = gradesData;
  const subjectField: any = subjectsData;
  // const classData: any = classList;

  const [formData, setFormData] = useState<any>({
    class: "",
    subject: "",
    otherSubject: "",
    examType: "IB",
    examDivision: activeExamButton,
    s1: "",
    s2: "",
  });

  const annualScore =
    formData.s1 && formData.s2
      ? (parseFloat(formData.s1) + parseFloat(formData?.s2)) / 2
      : "";

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      const response = await http.post("/api/gradeform", formData);
      console.log(response);
      // toast.success("Student grades Data Saved Successfully");
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  };
  // const handleNext = () => {
  //   navigate("../summary");
  // };

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "0px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>Class*</Typography>
            </Grid>

            <Grid container>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    class: "Freshman",
                  })
                }
              >
                <img
                  src={freshman}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background: formData.class === "Freshman" ? "#2D438E" : "",
                    color: formData.class === "Freshman" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Freshman
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",

                  flexDirection: "column",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    class: "Sophmore",
                  })
                }
              >
                <img
                  src={sophmore}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background: formData.class === "Sophmore" ? "#2D438E" : "",
                    color: formData.class === "Sophmore" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Sophmore
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",

                  flexDirection: "column",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    class: "Junior",
                  })
                }
              >
                <img
                  src={junior}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background: formData.class === "Junior" ? "#2D438E" : "",
                    color: formData.class === "Junior" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Junior
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    class: "Senior",
                  })
                }
              >
                <img
                  src={senior}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background: formData.class === "Senior" ? "#2D438E" : "",
                    color: formData.class === "Senior" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Senior
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "0px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>Subject Name*</Typography>
            </Grid>
            <FormControl fullWidth>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.subject}
                name="subject"
                label="Subject"
                onChange={handleInputChange}
              >
                {Object.keys(subjectField).map(
                  (subjectKey) =>
                    Array.isArray(subjectField[subjectKey]) &&
                    subjectField[subjectKey].map((subject: any) => (
                      <MenuItem key={subject} value={subject}>
                        {subject}
                      </MenuItem>
                    ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {formData.subject === "Others" && (
          <>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "5px 0px 5px 0px" }}
              >
                <Typography sx={{ fontSize: "12px" }}>
                  Subject Name *{" "}
                </Typography>
                <TextField
                  size="small"
                  id="outlined-multiline-flexible"
                  label=""
                  name="otherSubject"
                  multiline
                  rows={1}
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              p: "5px 0px 0px 0px",
            }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {/* <Button
                  size="small"
                  name="examType"
                  value="quarter"
                  variant="outlined"
                  sx={{
                    size: { isMobile } ? "small" : "large",
                    background: activeExamButton === "quarter" ? "#2D438E" : "",
                    color: activeExamButton === "quarter" ? "#FFF" : "#2D438E",
                    ...logRegStyle.roleButton,
                  }}
                  onClick={handleExamButtonChange}
                >
                  quarter
                </Button> */}
              <Button
                size="small"
                name="examType"
                value="semester"
                variant="outlined"
                sx={{
                  size: { isMobile } ? "small" : "large",
                  background: activeExamButton === "semester" ? "#2D438E" : "",
                  color: activeExamButton === "semester" ? "#FFF" : "#2D438E",
                  ...logRegStyle.roleButton,
                }}
                onClick={handleExamButtonChange}
              >
                semester
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ p: "0px 0px 5px 0px" }}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "0px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>Scores*</Typography>
            </Grid>
            {activeExamButton === "semester" ? (
              <>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: "20px 10px 0px 10px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: "12px" }}
                      >
                        Semester 1
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.s1}
                        label="S1"
                        name="s1"
                        onChange={handleInputChange}
                      >
                        {Object.keys(gradesField).map((grade) => (
                          <MenuItem key={grade} value={grade}>
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: "20px 10px 0px 10px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: "12px" }}
                      >
                        Semester 2
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.s2}
                        label="S2"
                        name="s2"
                        onChange={handleInputChange}
                      >
                        {Object.keys(gradesField).map((grade) => (
                          <MenuItem key={grade} value={grade}>
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: "0px 10px 0px 10px" }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Annual Score*
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField size="small" value={annualScore} />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ p: "0px 5px 0px 5px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      quarter 1
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.state}
                      label="State"
                      name="state"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ p: "0px 5px 0px 5px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      quarter 2
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.state}
                      label="State"
                      name="state"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ p: "0px 5px 0px 5px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      quarter 3
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.state}
                      label="State"
                      name="state"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ p: "0px 5px 0px 5px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      quarter 4
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.state}
                      label="State"
                      name="state"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid container>
              <Grid xs={8} sm={8} md={8} lg={8} xl={8}>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "5px 0px 5px 0px" }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Upload Transcript*
                  </Typography>
                </Grid>
                <Button
                  sx={{ fontSize: "12px" }}
                  size="small"
                  component="label"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Transcript*
                  <VisuallyHiddenInput type="file" />
                </Button>
              </Grid>
            </Grid>
            <Grid container sx={{ p: "0px 0px 0px 0px" }}>
              <Grid xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
              <Grid
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  // alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <Button variant="contained" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
                {/* <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <Button
                    variant="contained"
                    sx={{ alignItems: "flex-end" }}
                    onClick={handleNext}
                  >
                    skip for now
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GradeIbFormComponent;
