import React, { useLayoutEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, useLocation } from "react-router-dom";
// import Register from "./pages/Register";
// import Login from "./pages/Login";
import StudentForm from "./pages/StudentForm";
import SummaryPage from "./pages/SummaryPage";
import ForgetPassForm from "./pages/ForgetPassForm";
import MenuAppBar from "./layouts/MenuAppBar";
import EmailVerificationPage from "./pages/EmailVerificationPage";
// import BreadcrumbsComponent from "./components/BreadcrumbsComponent";
import PostRegisterPage from "./pages/PostRegisterPage";
import constant from "./constants/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SearchSummaryComponent from "./components/SearchSummaryComponent";
import NavigationPage from "./pages/NavigationPage";
import LoginV2 from "./pages/LoginV2";
import RegisterV2 from "./pages/RegisterV2";
import AccountCompletePage from "./pages/AccountCompletePage";

const theme = createTheme({
  palette: {
    primary: {
      main: constant.primary,
    },
    background: {
      default: constant.background,
    },
  },
  typography: {
    fontFamily: "Inter, Arial",
  },
});

function App() {
  const location = useLocation();
  const path = location.pathname;
  console.log(path);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#F4F2EE";
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        {path === "/success-register" ||
        path === "/login" ||
        path === "/register" ||
        path === "/" ||
        path === "/api/verifyemail/:id/:token/:exptime" ||
        path === "/api/verifyemail/*" ||
        path === "/setpassword/:id/*" ||
        path === "/success-register" ||
        path === "/api/verifyemail/:usertype/:email" ||
        path === "/api/verifyemail/*" ||
        path.startsWith("/api/verifyemail/") ||
        path.startsWith("/setpassword/") ||
        path.startsWith("/forgetpassword/") ||
        path === "/forgetpassword/:id/*" ? (
          <></>
        ) : (
          <>
            <MenuAppBar />
          </>
        )}
        <Routes>
          <Route path="/" element={<LoginV2 />}></Route>
          <Route path="/login" element={<LoginV2 />}></Route>
          <Route path="/register" element={<RegisterV2 />}></Route>
          <Route
            path="/setup-complete"
            element={<AccountCompletePage />}
          ></Route>
          <Route path="/studentform" element={<StudentForm />}></Route>
          <Route
            path="/search/student/:id"
            element={<SearchSummaryComponent />}
          ></Route>
          <Route path="/optionspage" element={<NavigationPage />}></Route>
          <Route path="/grades/ap" element={<NavigationPage />}></Route>
          <Route path="/grades/ap/summary" element={<NavigationPage />}></Route>
          <Route path="/grades/ib" element={<NavigationPage />}></Route>
          <Route path="/grades/ib/summary" element={<NavigationPage />}></Route>
          <Route path="/grades" element={<NavigationPage />}></Route>
          <Route path="/grades/summary" element={<NavigationPage />}></Route>
          <Route path="/awardspage" element={<NavigationPage />}></Route>
          <Route
            path="/awardspage/summary"
            element={<NavigationPage />}
          ></Route>
          <Route path="/communityservices" element={<NavigationPage />}></Route>
          <Route
            path="/communityservices/summary"
            element={<NavigationPage />}
          ></Route>
          <Route path="/testscores" element={<NavigationPage />}></Route>
          <Route
            path="/transcript/summary"
            element={<NavigationPage />}
          ></Route>
          <Route
            path="/testscores/summary"
            element={<NavigationPage />}
          ></Route>
          <Route
            path="/testscores/summary/edit"
            element={<NavigationPage />}
          ></Route>
          <Route path="/summary" element={<NavigationPage />}></Route>
          <Route
            path="/extracurricularactivities"
            element={<NavigationPage />}
          ></Route>
          <Route
            path="/extracurricularactivities/summary"
            element={<NavigationPage />}
          ></Route>
          <Route path="/home" element={<SummaryPage />}></Route>
          <Route path="/account" element={<NavigationPage />}></Route>
          <Route path="/account/edit" element={<NavigationPage />}></Route>
          <Route path="/form-check" element={<NavigationPage />}></Route>
          <Route
            path="/success-register"
            element={<PostRegisterPage />}
          ></Route>
          <Route
            path="/forgetpassword/:id/*"
            element={<ForgetPassForm />}
          ></Route>
          <Route path="/setpassword/:id/*" element={<ForgetPassForm />}></Route>
          <Route
            path="/api/verifyemail/:usertype/:email"
            element={<EmailVerificationPage />}
          ></Route>
          <Route
            path="/api/verifyemail/:id/:token/:exptime"
            element={<EmailVerificationPage />}
          ></Route>
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ThemeProvider>
    </>
  );
}

export default App;
