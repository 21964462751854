import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import http from "../config/http-common";
import eca from "../assets/images/formVector/formeca.png";
import constants from "../constants/constant";
import LoadingPage from "../utils/Loading";
import EcaDataList from "../layouts/EcaDataList";
import AlertEcaAddDialogSlide from "../layouts/AlertEcaAddDialogSlide";
import { toast } from "react-toastify";

const CustomRadioButton = ({ value, selectedValue }:any) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ display: "none" }} />}
    label={
      <Typography
        sx={{
          fontSize: "12px",
          padding: "10px 20px",
          borderRadius: "25px",
          border:
            selectedValue === value ? "2px solid #FFA500" : "1px solid #ccc",
          backgroundColor: selectedValue === value ? "#FFF4E0" : "#F6F6F6",
          color: selectedValue === value ? "#FFA500" : "#000",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
    }
  />
);

const AddEcaSection = ({ handleAdd, class_state }: any) => (
  <Grid
    container
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p: "50px 0px 0px 0px",
    }}
  >
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "0px 0px 0px 0px",
      }}
    >
      <img
        src={eca}
        alt="melo logo"
        style={{
          height: "70px",
        }}
      />
    </Grid>

    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        p: "20px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: constants.notAdded,
        }}
      >
        No Extracurricular Activities added
      </Typography>
    </Grid>
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        p: "20px 0px 60px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        size="small"
        variant="contained"
        sx={{
          borderRadius: "10px",
          borderColor: constants.tertiary,
          border: "1px solid #FE8D02",
          fontSize: "12px",
          color: "white",
          background:
            "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
          backgroundSize: "cover",
          "&:hover": {
            background:
              "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
          },
        }}
        onClick={() => handleAdd(class_state)}
      >
        ADD NEW
      </Button>
    </Grid>
  </Grid>
);

const CompetitionList = () => {
  const [awards, setAwards] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const state = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchAwards = async () => {
      setLoading(true);
      try {
        const response = await http.get("/api/eca/all");

        const result: any = response.data;
        console.log(result.response.data);
        setAwards(result.response.data);
      } catch (error: any) {
        if (error.response) {
          console.error("Error fetching awards:", error.response.data.message);
        } else {
          console.error("Network error:", error.message);
        }
      }
      setLoading(false);
    };

    if (state) {
      fetchAwards();
    }
  }, [state]);

  const [activeClassButton, setActiveClassButton] = useState("Freshman");

  const [selectedValue, setSelectedValue] = useState("Freshman");

  const handleChange = (event:any) => {
    setSelectedValue(event.target.value);
    setActiveClassButton(event.target.value);
  };

  const freshData =
    awards && awards.filter((item: any) => item.class === "Freshman");
  const sophmoreData =
    awards && awards.filter((item: any) => item.class === "Sophmore");
  const jnrData =
    awards && awards.filter((item: any) => item.class === "Junior");
  const snrData =
    awards && awards.filter((item: any) => item.class === "Senior");



  const [addClass, setAddClass] = useState(null);
  const [addOpen, setAddOpen] = useState(false);

    const handleAdd = (class_state: any) => {
      setAddClass(class_state);
      setAddOpen(true);
    };

    const handleAddClose = async (confirmed: any, data: any) => {
      setAddOpen(false);
      if (confirmed && addClass) {
        try {
            const response = await http.post("/api/eca/create", data);
            console.log(response);
            // toast.success("ECA Data Saved Successfully");
             window.location.reload();
          
        } catch (error: any) {
          if (error.response && error.response.data) {
            console.error("Error LogResponse in user:", error.response.data);
            toast.error(error.response.data.errorMessage);
          } else {
            console.error("Error saving data:", error.message);
            toast.error(error.message);
          }
        }
      } else {
        setAddClass(null);
      }
    };

      const count = awards.filter((item: any) => item.toDisplay).length;

  return (
    <div>
      {loading ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <LoadingPage />
        </Grid>
      ) : (
        <>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "20px 0px 20px 0px" }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: constants.text,
                    fontWeight: "500",
                  }}
                >
                  Extracurricular Activities
                </Typography>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup row value={selectedValue} onChange={handleChange}>
                  <Grid
                    container
                    // xs={12}
                    // sm={12}
                    // md={6} // For medium and up, two buttons per row
                    // lg={6}
                    // xl={6}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: "10px",
                    }}
                  >
                    {["Freshman", "Sophmore", "Junior", "Senior"].map(
                      (label) => (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={3}
                          key={label}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CustomRadioButton
                            value={label}
                            selectedValue={selectedValue}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {activeClassButton === "Freshman" &&
              freshData &&
              freshData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Freshman List
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        borderColor: constants.tertiary,
                        border: "1px solid #FE8D02",
                        fontSize: "12px",
                        color: "white",
                        background:
                          "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        backgroundSize: "cover",

                        "&:hover": {
                          background:
                            "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        },
                      }}
                      onClick={() => handleAdd("Freshman")}
                    >
                      + Add
                    </Button>
                  </Grid>
                </>
              )}
            {activeClassButton === "Freshman" &&
              freshData &&
              freshData.length === 0 && (
                <>
                  <AddEcaSection handleAdd={handleAdd} class_state="Freshman" />{" "}
                </>
              )}
            {activeClassButton === "Sophmore" &&
              sophmoreData &&
              sophmoreData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Sophmore List
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        borderColor: constants.tertiary,
                        border: "1px solid #FE8D02",
                        fontSize: "12px",
                        color: "white",
                        background:
                          "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        backgroundSize: "cover",

                        "&:hover": {
                          background:
                            "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        },
                      }}
                      onClick={() => handleAdd("Sophmore")}
                    >
                      + add
                    </Button>
                  </Grid>
                </>
              )}
            {activeClassButton === "Sophmore" &&
              sophmoreData &&
              sophmoreData.length === 0 && (
                <>
                  <AddEcaSection handleAdd={handleAdd} class_state="Sophmore" />
                </>
              )}
            {activeClassButton === "Junior" &&
              jnrData &&
              jnrData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Junior List
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        borderColor: constants.tertiary,
                        border: "1px solid #FE8D02",
                        fontSize: "12px",
                        color: "white",
                        background:
                          "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        backgroundSize: "cover",

                        "&:hover": {
                          background:
                            "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        },
                      }}
                      onClick={() => handleAdd("Junior")}
                    >
                      + add
                    </Button>
                  </Grid>
                </>
              )}
            {activeClassButton === "Junior" &&
              jnrData &&
              jnrData.length === 0 && (
                <>
                  <AddEcaSection handleAdd={handleAdd} class_state="Junior" />
                </>
              )}
            {activeClassButton === "Senior" &&
              snrData &&
              snrData.length !== 0 && (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography sx={{ color: constants.text }}>
                      Senior List
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        borderColor: constants.tertiary,
                        border: "1px solid #FE8D02",
                        fontSize: "12px",
                        color: "white",
                        background:
                          "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        backgroundSize: "cover",

                        "&:hover": {
                          background:
                            "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                        },
                      }}
                      onClick={() => handleAdd("Senior")}
                    >
                      + add
                    </Button>
                  </Grid>
                </>
              )}
            {activeClassButton === "Senior" &&
              snrData &&
              snrData.length === 0 && (
                <>
                  <AddEcaSection handleAdd={handleAdd} class_state="Senior" />
                </>
              )}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              p: "10px 0px 0px 0px",
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
            }}
          >
            <Box sx={{ maxHeight: "auto", overflow: "auto" }}>
              {activeClassButton === "Freshman" &&
                freshData &&
                freshData.length !== 0 && (
                  <>
                    {/* <EcaTable data={freshData} /> */}
                    {Array.isArray(freshData) &&
                      freshData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <EcaDataList data={data} count={count} />
                        ))}
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                sophmoreData &&
                sophmoreData.length !== 0 && (
                  <>
                    {/* <EcaTable data={sophmoreData} /> */}
                    {Array.isArray(sophmoreData) &&
                      sophmoreData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <EcaDataList data={data} count={count} />
                        ))}
                  </>
                )}
              {activeClassButton === "Junior" &&
                jnrData &&
                jnrData.length !== 0 && (
                  <>
                    {/* <EcaTable data={jnrData} /> */}
                    {Array.isArray(jnrData) &&
                      jnrData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <EcaDataList data={data} count={count} />
                        ))}
                  </>
                )}
              {activeClassButton === "Senior" &&
                snrData &&
                snrData.length !== 0 && (
                  <>
                    {/* <EcaTable data={snrData} /> */}
                    {Array.isArray(snrData) &&
                      snrData
                        .slice()
                        .reverse()
                        .map((data: any, index: number) => (
                          <EcaDataList data={data} count={count} />
                        ))}
                  </>
                )}
            </Box>
          </Grid>
          {addClass && (
            <AlertEcaAddDialogSlide
              open={addOpen}
              onClose={handleAddClose}
              data={addClass}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CompetitionList;
