import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HomeIcon from "@mui/icons-material/Home";
// import MoreIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, Grid } from "@mui/material";
import meloLogo from "../assets/images/logo/melo_logo.png";
import { getStudentData } from "../redux/slices/studentDataSlice";
import { getStudentImage } from "../redux/slices/studentImageSlice";
import { getStudentExam } from "../redux/slices/studentExamSlice";
import { getStudentEca } from "../redux/slices/studentEcaSlice";
import { getStudentCs } from "../redux/slices/studentCsSlice";
import { getStudentAwards } from "../redux/slices/studentAwardsSlice";
import constants from "../constants/constant";
import { toast } from "react-toastify";
import { getStudentTestScores } from "../redux/slices/studentTestScoresSlice";
import { getPosts } from "../redux/slices/postDataSlice";
import Search from "../utils/Search";
import { getAllStudentsData } from "../redux/slices/allStudentSlice";
import { getStudentFiles } from "../redux/slices/studentFilesSlice";

export default function MenuAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const studentImage = useSelector((state: any) => state.studentImage);
  const studentData = useSelector((state: any) => state.studentDetails);
  const imageUrl = studentImage.data;
  const state = localStorage.getItem("accessToken");
  useEffect(() => {
    if (state) {
      try {
        dispatch(getStudentData());
        dispatch(getStudentImage());
        dispatch(getStudentExam());
        dispatch(getStudentEca());
        dispatch(getStudentCs());
        dispatch(getStudentAwards());
        dispatch(getStudentTestScores());
        dispatch(getPosts());
        dispatch(getAllStudentsData());
        dispatch(getStudentFiles());
      } catch (error: any) {
        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error Logging in user:", error.message);
          toast.error(error.message);
        }
      }
    }
  }, [dispatch, state]);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
  };

  // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const handleSelect = (name: any) => {
    if (name === "profile") {
      setAnchorEl("");
      navigate("../account");
    }
    if (name === "grades") {
      setAnchorEl("");
      navigate("../grades/ap/summary");
    }
    if (name === "awards") {
      setAnchorEl("");
      navigate("../awardspage/summary");
    }
    if (name === "eca") {
      setAnchorEl("");
      navigate("../extracurricularactivities/summary");
    }
    if (name === "cs") {
      setAnchorEl("");
      navigate("../communityservices/summary");
    }
    if (name === "sumamry") {
      setAnchorEl("");
      navigate("../summary");
    }
    if (name === "trans") {
      setAnchorEl("");
      navigate("../transcript/summary");
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {studentData?.data?.first_name && (
        <>
          <MenuItem sx={{ cursor: "text" }}>
            Hi{" "}
            {studentData?.data?.first_name && studentData?.data?.first_name
              ? studentData.data?.first_name
              : ""}
            !
          </MenuItem>
        </>
      )}
      <MenuItem onClick={() => handleSelect("profile")}>Profile</MenuItem>
      {/* <MenuItem onClick={() => handleSelect("grades")}>Grades</MenuItem>

      <MenuItem onClick={() => handleSelect("awards")}>Awards</MenuItem>
      <MenuItem onClick={() => handleSelect("eca")}>
        Extracurricular Activities
      </MenuItem>
      <MenuItem onClick={() => handleSelect("cs")}>Community Services</MenuItem>
      <MenuItem onClick={() => handleSelect("sumamry")}>Summary</MenuItem>
      <MenuItem onClick={() => handleSelect("trans")}>Transcript</MenuItem> */}
      <MenuItem onClick={handleLogOut}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <>
      {location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/register" ? (
        <>
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
          >
            <MenuItem onClick={() => navigate("../register")}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <p>Join Now</p>
            </MenuItem>
            <MenuItem onClick={() => navigate("../login")}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <p>Sign In</p>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
          >
            <MenuItem onClick={handleProfileMenuOpen}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <p>Profile</p>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );

  return (
    <Box sx={{ flexGrow: 1, boxShadow: "none" }}>
      <AppBar position="static" sx={{ background: "white", boxShadow: "none" }}>
        <Toolbar>
          <Grid
            sx={{
              p: {
                xs: "10px 0px 10px 0px",
                sm: "10px 0px 10px 0px",
                md: "10px 0px 10px 50px",
                lg: "10px 0px 10px 50px",
                xl: "10px 0px 10px 50px",
              },
            }}
          >
            <a href="/">
              <img
                src={meloLogo}
                alt="melo logo"
                style={{
                  width: "40px",
                }}
              />
            </a>
          </Grid>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {location.pathname === "/" ||
            location.pathname === "/login" ||
            location.pathname === "/register" ? (
              <></>
            ) : (
              <>
                <Grid container sx={{ p: "0px 80px 0px 0px" }}>
                  <Search />
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={() => navigate("../home")}
                  >
                    <Badge color="error">
                      <HomeIcon sx={{ color: constants.icons }} />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <NotificationsIcon sx={{ color: constants.icons }} />
                    {/* <Badge badgeContent={""} color="error">
                  </Badge> */}
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      alt=""
                      src={imageUrl ? imageUrl : ""}
                      sx={{ width: 40, height: 40, color: "black" }}
                    />
                  </IconButton>
                </Grid>
              </>
            )}
          </Box>
          {location.pathname === "/" ||
          location.pathname === "/login" ||
          location.pathname === "/register" ? (
            <>
              <Grid sx={{ p: "0px 10px 0px 0px" }}>
                <Button
                  size="small"
                  sx={{
                    background: constants.tertiary,
                    borderRadius: "25px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    height: "30px",
                    "&:hover": {
                      background: "black",
                    },
                    textTransform: "capitalize",
                    color: "white",
                  }}
                  onClick={() => navigate("../register")}
                >
                  Join Now
                </Button>
              </Grid>
              <Grid sx={{ p: "0px 0px 0px 0px" }}>
                <Button
                  size="small"
                  sx={{
                    background: constants.tertiary,
                    borderRadius: "25px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    height: "30px",
                    "&:hover": {
                      background: "black",
                    },
                    textTransform: "capitalize",
                    color: "white",
                  }}
                  onClick={() => navigate("../login")}
                >
                  Sign In
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Box sx={{ display: { xs: "flex", md: "none" }, ml: "auto" }}>
              <Grid sx={{ p: "0px 0px 0px 10px" }}>
                <Search />
              </Grid>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => navigate("../home")}
                >
                  <Badge color="error">
                    <HomeIcon sx={{ color: constants.icons }} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge color="error">
                    <NotificationsIcon sx={{ color: constants.icons }} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar
                    alt=""
                    src={imageUrl ? imageUrl : ""}
                    sx={{ width: 40, height: 40, color: "black" }}
                  />
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
