import FormLayout from "../layouts/FormLayout";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import FormLayoutMob from "../layouts/FormLayoutMob";

const NavigationPage = () => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  return (
    <>
      {/* {isMobile || isTablet ? (
        <>
          <FormLayoutMob />
        </>
      ) : (
      )} */}
      <>
        <FormLayout />
      </>
    </>
  );
};

export default NavigationPage;
