// APGradesTable.js
import React, { useState } from "react";
import {
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import http from "../config/http-common";
import constants from "../constants/constant";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import AlertDialogSlide from "./AlertDialogSlide";
import AlertGradesEditDialogSlide from "./AlertGradesEditDialogSlide";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ApGradesTable = ({ data, isSummary }: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDialog = (id: any) => {
    setDeleteId(id);
    setDialogOpen(true);
  };

  const handleOpenEdit = (id: any, subId: any) => {
    setEditId(id);
    setSubjectId(subId);
    setEditOpen(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const raw = data.filter((dat: any) => dat._id === editId);
  const subRaw = raw
    ? raw[0]?.subject?.filter((dat: any) => dat._id === subjectId)
    : "";

  const handleDialogClose = async (confirmed: any) => {
    setDialogOpen(false);
    if (confirmed && deleteId) {
      try {
        const response = await http.delete(`/api/grade/delete/${deleteId}`);
        const result = response.data;
        console.log(result.response.data);
        // toast.success("Grades deleted Successfully!");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } catch (error: any) {
        if (error.response) {
          console.error("Error deleting grade:", error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          console.error("Network error:", error.message);
          toast.error(error.message);
        }
      }
    }
  };

  const handleEditClose = async (confirmed: any, data: any) => {
    setEditOpen(false);
    if (confirmed && editId) {
      try {
        const response = await http.post(`/api/grade/update/${editId}`, data);
        const result = response.data;
        console.log(result.response.data);
        // toast.success("Grades data updated Successfully!");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } catch (error: any) {
        if (error.response) {
          console.error("Error deleting grade:", error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          console.error("Network error:", error.message);
          toast.error(error.message);
        }
      }
    }
    if (!confirmed) {
      setEditId(null);
      setSubjectId(null);
    }
  };

  return (
    <>
      <TableContainer
        sx={{
          border: `1px solid ${constants.primary}`,
          borderRadius: "5px",
          width: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Subject
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Type
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Q1
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Q2
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Q3
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Q4
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                S1
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                S2
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  // width: "150px",
                  // height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Un.GPA
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                W.GPA
              </TableCell>
              {!isSummary && (
                <>
                  <TableCell
                    sx={{
                      p: "5px",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "center",
                      width: "150px",
                      height: "20px",
                      // borderRight: "2px solid #ccc",
                      // borderLeft: "2px solid #ccc",
                      // borderTop: "2px solid #ccc"
                      // borderBottom: "2px solid #ccc",
                      backgroundColor: constants.tablebg,
                      color: constants.tableTxt,
                    }}
                  ></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item: any, index: number) =>
                item.subject.map((subjectItem: any) => (
                  <TableRow key={subjectItem._id}>
                    {subjectItem.subjectName === "Others" ? (
                      <TableCell
                        sx={{
                          p: "5px",
                          fontSize: "12px",
                          fontWeight: "400",
                          textAlign: "center",
                          width: "150px",
                          height: "20px",
                          // borderRight: "2px solid #ccc",
                          // borderLeft: "2px solid #ccc",
                          // borderTop: "2px solid #ccc"
                          // borderBottom: "2px solid #ccc",
                          // backgroundColor: constants.tablebg,
                          color: constants.text,
                        }}
                      >
                        {subjectItem.otherSubjectName}
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          p: "5px",
                          fontSize: "12px",
                          fontWeight: "400",
                          textAlign: "center",
                          width: "150px",
                          height: "20px",
                          // borderRight: "2px solid #ccc",
                          // borderLeft: "2px solid #ccc",
                          // borderTop: "2px solid #ccc"
                          // borderBottom: "2px solid #ccc",
                          // backgroundColor: constants.tablebg,
                          color: constants.text,
                        }}
                      >
                        {subjectItem.subjectName}
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {subjectItem.subjectType}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {item.examDivision === "quarter"
                        ? subjectItem.quater.q1
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {item.examDivision === "quarter"
                        ? subjectItem.quater.q2
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {item.examDivision === "quarter"
                        ? subjectItem.quater.q3
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {item.examDivision === "quarter"
                        ? subjectItem.quater.q4
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {item.examDivision === "semester"
                        ? subjectItem.semester.s1
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {item.examDivision === "semester"
                        ? subjectItem.semester.s2
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {subjectItem.average_GPA}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {subjectItem.weighted_GPA}
                    </TableCell>
                    {!isSummary && (
                      <>
                        <TableCell
                          sx={{
                            p: "5px",
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "center",
                            width: "150px",
                            height: "20px",
                            // borderRight: "2px solid #ccc",
                            // borderLeft: "2px solid #ccc",
                            // borderTop: "2px solid #ccc"
                            // borderBottom: "2px solid #ccc",
                            // backgroundColor: constants.tablebg,
                            color: constants.text,
                          }}
                        >
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <IconButton
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: 40,
                                height: 40,
                              }}
                              aria-label="settings"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                sx={{ color: constants.text }}
                                onClick={() =>
                                  handleOpenEdit(item._id, subjectItem._id)
                                }
                              >
                                <ListItemIcon>
                                  <EditIcon
                                    sx={{
                                      color: constants.text,
                                      fontSize: "20px",
                                    }}
                                  />
                                </ListItemIcon>
                                Edit
                              </MenuItem>
                              <MenuItem
                                sx={{ color: "red" }}
                                onClick={() => handleOpenDialog(data?._id)}
                              >
                                <ListItemIcon>
                                  <DeleteOutlineIcon
                                    sx={{ color: "red", fontSize: "20px" }}
                                  />
                                </ListItemIcon>
                                Delete
                              </MenuItem>
                            </Menu>
                          </Grid>
                          <AlertDialogSlide
                            open={dialogOpen}
                            onClose={handleDialogClose}
                          />
                          {editId && (
                            <>
                              <AlertGradesEditDialogSlide
                                open={editOpen}
                                onClose={handleEditClose}
                                data={raw}
                                subData={subRaw}
                              />
                            </>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ApGradesTable;
