import React from "react";
import { CircularProgress, Grid } from "@mui/material";

export default function Loading() {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      {/* <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
        }}
      >
      </Grid> */}
      <CircularProgress sx={{ color: "#fe8d01" }} />
    </Grid>
  );
}
