import { Button,Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import meloLogo from "../assets/images/logo/melo_logo.png";
import React from 'react'
import constants from '../constants/constant';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import setupSuccess from "../assets/jsonData/setupSuccess.json";

const AccountCompletePage = () => {
      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
      const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
        const navigate = useNavigate();

      const handleContinue = ()=>{
        navigate("../home")
      }
  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              p: "20px 20px 0px 20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={meloLogo}
              alt="melo logo"
              style={{
                height: isMobile ? "40px" : isTablet ? "40px" : "60px",
              }}
            />
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              backgroundColor: "white",
              borderRadius: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "5px",
                xl: "5px",
              },
              margin: {
                xs: "0px",
                sm: "0px",
                md: "0px",
                lg: "20px",
                xl: "20px",
              },
              maxWidth: {
                lg: "440px",
                xl: "440px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "center",
              },
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                backgroundColor: "#F6F6F6",
                border: " 1px solid #E8E8EA",
                borderRadius: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "5px",
                  xl: "5px",
                },
                margin: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "20px",
                  xl: "20px",
                },
                // maxWidth: {
                //   lg: "440px",
                //   xl: "440px",
                // },
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "center",
                  xl: "center",
                },
              }}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "10px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Lottie
                    animationData={setupSuccess}
                    style={{
                      width: "25%",
                      height: "25%",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: constants.text,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Account Setup Complete
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: "0px 0px 20px 0px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#667085",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    You can later edit your details on your profile section
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <Typography
                sx={{
                  p: "10px 10px 10px 10px",
                  color: "#667085",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Complete your profile by adding your awards , Grades , services
                , scores and more ....
              </Typography>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: "10px 0px 20px 0px",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  borderColor: constants.tertiary,
                  border: "1px solid #FE8D02",
                  color: "white",
                  background:
                    "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                  backgroundSize: "cover",
                  "&:hover": {
                    background:
                      "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                  },
                }}
                onClick={handleContinue}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AccountCompletePage
