import React, { useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import http from "../config/http-common";
import test from "../assets/images/formVector/testScores.png";
import constants from "../constants/constant";

import { useNavigate } from "react-router-dom";
import ActTable from "../layouts/ActTable";
import SatTable from "../layouts/SatTable";
import LoadingPage from "../utils/Loading";
import AlertTestAddDialogSlide from "../layouts/AlertTestAddDialogSlide";
import { toast } from "react-toastify";

const CustomRadioButton = ({ value, selectedValue }: any) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ display: "none" }} />}
    label={
      <Typography
        sx={{
          fontSize: "12px",
          padding: "10px 20px",
          borderRadius: "25px",
          border:
            selectedValue === value ? "2px solid #FFA500" : "1px solid #ccc",
          backgroundColor: selectedValue === value ? "#FFF4E0" : "#F6F6F6",
          color: selectedValue === value ? "#FFA500" : "#000",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
    }
  />
);

const AddAwardSection = ({ handleAdd, class_state }: any) => (
  <Grid
    container
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p: "50px 0px 0px 0px",
    }}
  >
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "0px 0px 0px 0px",
      }}
    >
      <img
        src={test}
        alt="melo logo"
        style={{
          height: "70px",
        }}
      />
    </Grid>

    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        p: "20px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: constants.notAdded,
        }}
      >
        No Test Scores added
      </Typography>
    </Grid>
  </Grid>
);

const SummaryTestList = ({ testData }: any) => {
  const awards = testData;
  const [activeClassButton, setActiveClassButton] = useState("ACT");
  const isLoading = false;
  const [addClass, setAddClass] = useState(null);
  const [addOpen, setAddOpen] = useState(false);

  const handleAdd = (class_state: any) => {
    setAddClass(class_state);
    setAddOpen(true);
  };

 

  const actData = awards && awards.filter((item: any) => item.test === "ACT");
  const satData = awards && awards.filter((item: any) => item.test === "SAT");

  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState("ACT");

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    setActiveClassButton(event.target.value);
  };

  const handleAddClose = async (confirmed: any, data: any) => {
    setAddOpen(false);
    if (confirmed && addClass) {
      try {
        if (activeClassButton === "ACT") {
          const response = await http.post(`/api/testscores/create`, data);
          console.log(response);
          // toast.success("Awards Data Saved Successfully");
          navigate("../testscores/summary");
        }
        if (activeClassButton === "SAT") {
          const response = await http.post(`/api/testscores/create`, data);
          console.log(response);
          // toast.success("Awa Data Saved Successfully");
          navigate("../testscores/summary");
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error saving data:", error.message);
          toast.error(error.message);
        }
      }
      window.location.reload();
    } else {
      setAddClass(null);
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
            }}
          >
            <Grid>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: constants.text,
                  fontWeight: "500",
                }}
              >
                Test Scores
              </Typography>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioGroup row value={selectedValue} onChange={handleChange}>
                <Grid
                  container
                  // xs={12}
                  // sm={12}
                  // md={6} // For medium and up, two buttons per row
                  // lg={6}
                  // xl={6}
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "10px",
                  }}
                >
                  {["ACT", "SAT"].map((label) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      key={label}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CustomRadioButton
                        value={label}
                        selectedValue={selectedValue}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {activeClassButton === "ACT" && actData && actData.length !== 0 && (
              <>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    ACT Score Summary
                  </Typography>
                </Grid>
              </>
            )}
            {activeClassButton === "SAT" && satData && satData.length !== 0 && (
              <>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    SAT Score Summary
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {activeClassButton === "ACT" && actData && actData.length !== 0 && (
              <>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    width: {
                      xs: "calc(100vw - 100px)",
                      sm: "calc(100vw - 100px)",
                      md: "calc(55vw - 100px)",
                      lg: "calc(55vw - 100px)",
                      xl: "calc(55vw - 100px)",
                    },
                  }}
                >
                  <ActTable data={actData} isSummary={true} />
                </Grid>
              </>
            )}
            {activeClassButton === "ACT" && actData && actData.length === 0 && (
              <>
                <AddAwardSection handleAdd={handleAdd} class_state="ACT" />
              </>
            )}
            {activeClassButton === "SAT" && satData && satData.length !== 0 && (
              <>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    width: {
                      xs: "calc(100vw - 100px)",
                      sm: "calc(100vw - 100px)",
                      md: "calc(55vw - 100px)",
                      lg: "calc(55vw - 100px)",
                      xl: "calc(55vw - 100px)",
                    },
                  }}
                >
                  <SatTable data={satData} isSummary={true} />
                </Grid>
              </>
            )}
            {activeClassButton === "SAT" && satData && satData.length === 0 && (
              <>
                <AddAwardSection handleAdd={handleAdd} class_state="SAT" />
              </>
            )}
          </Grid>

          {addClass && (
            <AlertTestAddDialogSlide
              open={addOpen}
              onClose={handleAddClose}
              data={addClass}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SummaryTestList;
