import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import constants from "../constants/constant";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide({ open, onClose } : any) {
  const handleClose = (result: any) => {
    onClose(result);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to delete this data?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{ color: constants.tertiary }}
          onClick={() => handleClose(false)}
        >
          No
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "10px",
            borderColor: constants.tertiary,
            border: "1px solid #FE8D02",
            fontSize: "14px",
            color: "white",
            background:
              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            backgroundSize: "cover",
            "&:hover": {
              background:
                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            },
          }}
          onClick={() => handleClose(true)}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialogSlide;
