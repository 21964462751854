import React, { useState } from "react";
import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import http from "../config/http-common";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import constants from "../constants/constant";
import { toast } from "react-toastify";
import AlertDialogSlide from "./AlertDialogSlide";
import AlertTestEditDialogSlide from "./AlertTestEditDialogSlide";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ActTable = ({ data, isSummary }: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDialog = (id: any) => {
    setDeleteId(id);
    setDialogOpen(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = async (confirmed: any) => {
    setDialogOpen(false);
    console.log(deleteId);
    if (confirmed && deleteId) {
      try {
        const response = await http.delete(
          `/api/testscores/delete/${deleteId}`
        );
        const result = response.data;
        console.log(result.response.data);
        // toast.success("ACT Score deleted Successfully!");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } catch (error: any) {
        if (error.response) {
          console.error("Error deleting grade:", error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          console.error("Network error:", error.message);
          toast.error(error.message);
        }
      }
    }
  };

  let count = 1;

  const [toggleCount, setToggleCount] = useState(count);

  const handleToggle = async (state: any, id: any) => {
    console.log("toggle count before if ", toggleCount);
    // if (toggleCount >= 2 && !state) {
    //   toast.error("You can only display up to 3 awards at a time.");
    //   return;
    // }
    const updatedIsDisplayed = !state;
    console.log(updatedIsDisplayed);

    try {
      const response = await http.post(`/api/testscores/update/${id}`, {
        toDisplay: updatedIsDisplayed,
      });
      console.log(response);
      // toast.success("Score Data Saved Successfully");

      setToggleCount((prevCount) =>
        updatedIsDisplayed ? prevCount + 1 : prevCount - 1
      );
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
    window.location.reload();
  };

  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  const handleOpenEdit = (id: any) => {
    setEditId(id);
    setEditOpen(true);
  };

  const handleEditClose = async (confirmed: any, data: any) => {
    setEditOpen(false);
    if (confirmed && editId) {
      try {
        const response = await http.post(
          `/api/testscores/update/${editId}`,
          data
        );
        console.log(response);
      } catch (error: any) {
        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error saving data:", error.message);
          toast.error(error.message);
        }
      }
      window.location.reload();
    } else {
      setEditId(null);
    }
  };

  console.log(data);
  return (
    <>
      <TableContainer
        sx={{
          border: `1px solid ${constants.primary}`,
          borderRadius: "5px",
          width: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Maths
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Reading/ Writing
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                English
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Science
              </TableCell>
              <TableCell
                sx={{
                  p: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "center",
                  width: "150px",
                  height: "20px",
                  // borderRight: "2px solid #ccc",
                  // borderLeft: "2px solid #ccc",
                  // borderTop: "2px solid #ccc"
                  // borderBottom: "2px solid #ccc",
                  backgroundColor: constants.tablebg,
                  color: constants.tableTxt,
                }}
              >
                Overall
              </TableCell>
              {!isSummary && (
                <>
                  <TableCell
                    sx={{
                      p: "5px",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "center",
                      width: "150px",
                      height: "20px",
                      // borderRight: "2px solid #ccc",
                      // borderLeft: "2px solid #ccc",
                      // borderTop: "2px solid #ccc"
                      // borderBottom: "2px solid #ccc",
                      backgroundColor: constants.tablebg,
                      color: constants.tableTxt,
                    }}
                  >
                    Display
                  </TableCell>
                  <TableCell
                    sx={{
                      p: "5px",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "center",
                      width: "150px",
                      height: "20px",
                      // borderRight: "2px solid #ccc",
                      // borderLeft: "2px solid #ccc",
                      // borderTop: "2px solid #ccc"
                      // borderBottom: "2px solid #ccc",
                      backgroundColor: constants.tablebg,
                      color: constants.text,
                    }}
                  ></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((award: any, index: number) => (
                <TableRow key={award._id}>
                  <>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {award.maths}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {award.reading}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {award.english}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {award.science}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: "5px",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "center",
                        width: "150px",
                        height: "20px",
                        // borderRight: "2px solid #ccc",
                        // borderLeft: "2px solid #ccc",
                        // borderTop: "2px solid #ccc"
                        // borderBottom: "2px solid #ccc",
                        // backgroundColor: constants.tablebg,
                        color: constants.text,
                      }}
                    >
                      {award.total}
                    </TableCell>
                    {!isSummary && (
                      <>
                        <TableCell
                          sx={{
                            p: "5px",
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "center",
                            width: "150px",
                            height: "20px",
                            // borderRight: "2px solid #ccc",
                            // borderLeft: "2px solid #ccc",
                            // borderTop: "2px solid #ccc"
                            // borderBottom: "2px solid #ccc",
                            // backgroundColor: constants.tablebg,
                            color: constants.text,
                          }}
                        >
                          <Switch
                            size="small"
                            checked={award.toDisplay}
                            onClick={() =>
                              handleToggle(award.toDisplay, award._id)
                            }
                            inputProps={{ "aria-label": "toggle display" }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            p: "5px",
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "center",
                            width: "150px",
                            height: "20px",
                            // borderRight: "2px solid #ccc",
                            // borderLeft: "2px solid #ccc",
                            // borderTop: "2px solid #ccc"
                            // borderBottom: "2px solid #ccc",
                            // backgroundColor: constants.tablebg,
                            color: constants.text,
                          }}
                        >
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <IconButton
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: 40,
                                height: 40,
                              }}
                              aria-label="settings"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                sx={{ color: constants.text }}
                                onClick={() => handleOpenEdit(award._id)}
                              >
                                <ListItemIcon>
                                  <EditIcon
                                    sx={{
                                      color: constants.text,
                                      fontSize: "20px",
                                    }}
                                  />
                                </ListItemIcon>
                                Edit
                              </MenuItem>
                              <MenuItem
                                sx={{ color: "red" }}
                                onClick={() => handleOpenDialog(award?._id)}
                              >
                                <ListItemIcon>
                                  <DeleteOutlineIcon
                                    sx={{ color: "red", fontSize: "20px" }}
                                  />
                                </ListItemIcon>
                                Delete
                              </MenuItem>
                            </Menu>
                          </Grid>
                          <AlertDialogSlide
                            open={dialogOpen}
                            onClose={handleDialogClose}
                          />
                          {editId && (
                            <>
                              <AlertTestEditDialogSlide
                                open={editOpen}
                                onClose={handleEditClose}
                                data={data[0]}
                              />
                            </>
                          )}
                        </TableCell>
                      </>
                    )}
                  </>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ActTable;
