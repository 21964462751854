import StudentFormViewComponent from "../components/StudentFormViewComponent";
import { Grid } from "@mui/material";

const StudentFormViewPage = () => {

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StudentFormViewComponent />
      </Grid>
    </>
  );
};

export default StudentFormViewPage;
