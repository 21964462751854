import {

  Button,
  Card,
 FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import meloLogo from "../assets/images/logo/melo_logo.png";
import constants from "../constants/constant";
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { styled } from "@mui/material/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { toast } from "react-toastify";
import axiosInstance from "../config/http-common";
import { useNavigate } from "react-router-dom";

const RadioBut = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));

const RegisterV2 = () => {
    const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

   const [activeButton, setActiveButton] = useState("");

   const [formData, setFormData] = useState({
     email: "",
     password: "",
     confirmPassword: "",
     userType: activeButton,
   });

   const handleInputChange = (e: any) => {
     const { name, value } = e.target;
     setFormData({ ...formData, [name]: value });
   };

   const handleRegister = async (event: any) => {
     event.preventDefault();
     if (formData.password !== formData.confirmPassword) {
       toast.error("Passwords do not match");
     }
     if (activeButton === "") {
       toast.error("Please Select your Account Type");
     }
     if (formData.email === "") {
       toast.error("Please Enter your email Address");
     } else {
       const regData = {
         email: formData.email,
         password: formData.password,
         userType: activeButton,
       };

       try {
         await axiosInstance.post("/api/register", regData);
         await axiosInstance.post("/api/sendverifyemail", regData);
        //  toast.success("User created successfully");
         toast.success("Verfication email successfully");
         navigate("../success-register", { state: formData.email });
       } catch (error: any) {
         console.log(error);
         if (error.response && error.response.data) {
           console.error(
             "Error creating User:",
             error.response.data.errorMessage
           );
           toast.error(error.response.data.errorMessage);
         } else {
           console.error("Error creating User:", error.message);
           toast.error(error.message);
         }
       }
     }
   };

   const handleSignIn = ()=> {
    navigate("../login")
   }


  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              p: "20px 20px 0px 20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={meloLogo}
              alt="melo logo"
              style={{
                height: isMobile ? "40px" : isTablet ? "40px" : "60px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "30px 0px 0px 0px" }}
          >
            <Typography
              sx={{
                color: constants.text,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Choose Your Account Type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <RadioGroup
              aria-label="join-as"
              name="join-as-buttons"
              value={activeButton}
              onChange={(e) => setActiveButton(e.target.value)}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  p: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "24px 10px 20px 10px",
                    lg: "24px 10px 20px 10px",
                    xl: "24px 10px 20px 10px",
                  },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{
                    p: {
                      xs: "10px 5px 5px 5px",
                      sm: "10px 5px 5px 5px",
                      md: "10px 10px 0px 0px",
                      lg: "10px 10px 0px 0px",
                      xl: "10px 10px 0px 0px",
                    },
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Card
                    sx={{
                      maxHeight: 120,
                      maxWidth: 200,
                      border: "1px solid #FFA500",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{ p: "5px 5px 5px 5px" }}
                      value="student"
                      control={<RadioBut />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <IconButton>
                            <SchoolIcon sx={{ fontSize: 30 }} />
                          </IconButton>
                          {isMobile || isTablet ? (
                            <>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "16px" }}
                              >
                                Student
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "16px" }}
                              >
                                Join as a Student
                              </Typography>
                            </>
                          )}
                        </div>
                      }
                    />
                  </Card>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{
                    p: {
                      xs: "10px 5px 5px 5px",
                      sm: "10px 5px 5px 5px",
                      md: "10px 10px 0px 0px",
                      lg: "10px 10px 0px 0px",
                      xl: "10px 10px 0px 0px",
                    },
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      maxHeight: 120,
                      maxWidth: 200,
                      border: "1px solid #FFA500",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{ p: "5px 5px 5px 5px" }}
                      value="teacher"
                      control={<RadioBut />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <IconButton>
                            <ClassIcon sx={{ fontSize: 30 }} />
                          </IconButton>
                          {isMobile || isTablet ? (
                            <>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "16px" }}
                              >
                                Teacher
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "16px" }}
                              >
                                Join as a Teacher
                              </Typography>
                            </>
                          )}
                        </div>
                      }
                    />
                  </Card>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{
                    p: {
                      xs: "10px 5px 5px 5px",
                      sm: "10px 5px 5px 5px",
                      md: "10px 10px 0px 0px",
                      lg: "10px 10px 0px 0px",
                      xl: "10px 10px 0px 0px",
                    },
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Card
                    sx={{
                      maxHeight: 120,
                      maxWidth: 200,
                      border: "1px solid #FFA500",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      sx={{ p: "5px 5px 5px 5px" }}
                      value="college"
                      control={<RadioBut />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <IconButton>
                            <AccountBalanceIcon sx={{ fontSize: 30 }} />
                          </IconButton>
                          {isMobile || isTablet ? (
                            <>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "16px" }}
                              >
                                College
                              </Typography>
                            </>
                          ) : (
                            <>  
                              <Typography
                                sx={{ textAlign: "center", fontSize: "16px" }}
                              >
                                Join as a College
                              </Typography>
                            </>
                          )}
                        </div>
                      }
                    />
                  </Card>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>

          <Grid>
            <Grid sx={{ p: "40px 0px 0px 0px" }}>
              <Grid
                sx={{
                  p: {
                    borderRadius: "10px",
                  },
                }}
              >
                <TextField
                  size="small"
                  id="email"
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <MailOutlineIcon sx={{ color: constants.tertiary }} />
                    ),
                  }}
                  onChange={handleInputChange}
                  value={formData.email}
                />
              </Grid>
              <Grid sx={{ p: "20px 0px 0px 0px" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    borderColor: constants.tertiary,
                    border: "1px solid #FE8D02",

                    color: "white",
                    background:
                      "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                    backgroundSize: "cover",
                    padding: isMobile ? "6px 16px" : "8px 24px",
                    "&:hover": {
                      background:
                        "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                    },
                  }}
                  fullWidth
                  onClick={handleRegister}
                >
                  Join Now
                </Button>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  p: "20px 20px 20px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Already have an account?{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      color: constants.tertiary,
                      textDecoration: "underline",
                      textDecorationColor: constants.tertiary,
                      textDecorationThickness: "2px",
                      textUnderlineOffset: "3px",
                      "&:hover": {
                        textDecorationColor: constants.tertiary,
                      },
                    }}
                    onClick={handleSignIn}
                  >
                    Sign In
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterV2;
