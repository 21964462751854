import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import constants from "../constants/constant";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import gradesData from "../assets/jsonData/grades.json";
import subjectsData from "../assets/jsonData/rawSubject.json";
import gpaData from "../assets/jsonData/gpaList.json";
import { styled } from "@mui/material/styles";
import { logRegStyle } from "../constants/loginRegisterStyles";

const RadioBut = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));

const RadioBut1 = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertGradesAddDialogSlide({ open, onClose, data }: any) {
  const gradesField: any = gradesData;
  const subjectField: any = subjectsData;
  const gpaList: any = gpaData;

  const [activeTypeButton, setActiveTypeButton] = useState<any>();
 const [activeExamButton, setActiveExamButton] = useState("quarter");

  const [formData, setFormData] = useState<any>({
    class: data,
    subject: "",
    otherSubject: "",
    subjectType: "",
    examType: "AP",
    examDivision: activeExamButton,
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    s1: "",
    s2: "",
    finalScore: "",
  });


  const handleExamButtonChange = (e: any) => {
    const { name, value } = e.target;
    setActiveExamButton(value);
    setFormData({ ...formData, [name]: value });
    if (value === "quarter") {
      setFormData({
        ...formData,
        examDivision: "quarter",
        s1: "",
        s2: "",
      });
    }
    if (value === "semester") {
      setFormData({
        ...formData,
        examDivision: "semester",
        q1: "",
        q2: "",
        q3: "",
        q4: "",
      });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const getGPA = (grade: any) => {
    return gpaList[grade]?.gpa || 0;
  };

  const gpaQ1 = getGPA(formData.q1);
  const gpaQ2 = getGPA(formData.q2);
  const gpaQ3 = getGPA(formData.q3);
  const gpaQ4 = getGPA(formData.q4);
  const gpaS1 = getGPA(formData.s1);
  const gpaS2 = getGPA(formData.s2);

  const quarterGPAs: number[] = [gpaQ1, gpaQ2, gpaQ3, gpaQ4];
  const semesterGPAs: number[] = [gpaS1, gpaS2];

  const countNonZero = (gpas: number[]): number =>
    gpas.filter((gpa: number) => gpa > 0).length;

  const nonZeroQuarterCount = countNonZero(quarterGPAs);
  const nonZeroSemesterCount = countNonZero(semesterGPAs);

  const averageGpa =
    activeExamButton === "quarter"
      ? quarterGPAs.reduce((sum: number, gpa: number) => sum + gpa, 0) /
        (nonZeroQuarterCount || 1)
      : semesterGPAs.reduce((sum: number, gpa: number) => sum + gpa, 0) /
        (nonZeroSemesterCount || 1);

  const weightedGpa =
    formData.subjectType === "Honors"
      ? averageGpa + 0.5
      : formData.subjectType === "AP"
      ? averageGpa + 1
      : averageGpa;

  const handleClose = (result: any, data: any) => {
    onClose(result, data);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(false, formData)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Add {data} Grades</DialogTitle>
        <DialogContent>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "0px 0px 5px 0px" }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  Subject Name
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:'small'}}>
                    Subject Name
                  </InputLabel> */}
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.subject}
                  name="subject"
                  label="Subject"
                  onChange={handleInputChange}
                >
                  {Object.keys(subjectField).map(
                    (subjectKey) =>
                      Array.isArray(subjectField[subjectKey]) &&
                      subjectField[subjectKey].map((subject: any) => (
                        <MenuItem key={subject} value={subject}>
                          {subject}
                        </MenuItem>
                      ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {formData.subject === "Others" && (
            <>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "5px 0px 5px 0px" }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Subject Name <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-multiline-flexible"
                    label=""
                    name="otherSubject"
                    multiline
                    rows={1}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "5px 0px 5px 0px" }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              Is this an Honor's or AP class?*{" "}
            </Typography>
            <RadioGroup
              aria-label="subjectType"
              name="subject-buttons"
              value={activeTypeButton}
              onChange={(e) => setActiveTypeButton(e.target.value)}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", p: "5px 10px   0px 0px" }}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    p: {
                      xs: "0px 10px 0px 0px",
                      sm: "0px 10px 0px 0px",
                      md: "0px 10px 0px 10px",
                      lg: "0px 10px 0px 10px",
                      xl: "0px 10px 0px 10px",
                    },
                  }}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      subjectType: "No",
                    })
                  }
                >
                  <Grid
                    item
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="No"
                      control={<RadioBut1 />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",

                              borderRadius: {
                                xs: "0",
                                sm: "0",
                                md: "0",
                                lg: "25px",
                                xl: "25px",
                              },
                            }}
                          >
                            No
                          </Typography>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    p: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 10px 0px 10px",
                      lg: "0px 10px 0px 10px",
                      xl: "0px 10px 0px 10px",
                    },
                  }}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      subjectType: "Honors",
                    })
                  }
                >
                  <Grid
                    item
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="Honors"
                      control={<RadioBut1 />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",

                              borderRadius: {
                                xs: "0",
                                sm: "0",
                                md: "0",
                                lg: "25px",
                                xl: "25px",
                              },
                            }}
                          >
                            Honors
                          </Typography>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    p: {
                      xs: "0px 0px 0px 10px",
                      sm: "0px 0px 0px 10px",
                      md: "0px 0px 0px 10px",
                      lg: "0px 0px 0px 10px",
                      xl: "0px 0px 0px 10px",
                    },
                  }}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      subjectType: "AP",
                    })
                  }
                >
                  <Grid
                    item
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="AP"
                      control={<RadioBut1 />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",

                              borderRadius: {
                                xs: "0",
                                sm: "0",
                                md: "0",
                                lg: "25px",
                                xl: "25px",
                              },
                            }}
                          >
                            AP
                          </Typography>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              p: "5px 0px 0px 0px",
            }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  p: "0px 20px 0px 0px",
                }}
              >
                Exam Type:*
              </Typography>
              <FormControlLabel
                control={<RadioBut color="primary" />}
                label="Quarter"
                labelPlacement="end"
                value="quarter"
                checked={activeExamButton === "quarter"}
                onChange={handleExamButtonChange}
                sx={{ ...logRegStyle.roleButton }}
              />
              <FormControlLabel
                control={<RadioBut color="primary" />}
                label="Semester"
                labelPlacement="end"
                value="semester"
                checked={activeExamButton === "semester"}
                onChange={handleExamButtonChange}
                sx={{ ...logRegStyle.roleButton }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ p: "0px 0px 5px 0px" }}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "0px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Grades
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            {activeExamButton === "semester" ? (
              <>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    // alignItems: "center",
                    // justifyContent: "space-evenly",
                  }}
                >
                  <Grid sx={{ p: "0px 5px 0px 5px" }}>
                    <FormControl sx={{ width: "100px" }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: "12px" }}
                      >
                        Semester 1
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.s1}
                        label="s1"
                        name="s1"
                        onChange={handleInputChange}
                      >
                        {Object.keys(gradesField).map((grade) => (
                          <MenuItem key={grade} value={grade}>
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <FormControl sx={{ width: "100px" }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: "12px" }}
                      >
                        Semester 2
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.s2}
                        label="S2"
                        name="s2"
                        onChange={handleInputChange}
                      >
                        {Object.keys(gradesField).map((grade) => (
                          <MenuItem key={grade} value={grade}>
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Quarter 1
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.q1}
                      label="Q1"
                      name="q1"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Quarter 2
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.q2}
                      label="Q2"
                      name="q2"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Quarter 3
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.q3}
                      label="Q3"
                      name="q3"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Quarter 4
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.q4}
                      label="Q4"
                      name="q4"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: "10px 0px 0px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    p: "0px 5px 0px 5px",
                  }}
                >
                  AP Score:
                </Typography>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="AP"
                  name="ap"
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{ width: "100px", p: "0px 5px 0px 5px" }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: "10px 0px 0px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    p: "0px 5px 0px 5px",
                  }}
                >
                  Final Grade:
                </Typography>
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Final Grade
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.finalScore}
                      label="finalScore"
                      name="finalScore"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: "10px 0px 10px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    p: "0px 5px 0px 5px",
                  }}
                >
                  Unweighted GPA:
                </Typography>
                <TextField
                  size="small"
                  id="outlined-basic"
                  name="total_GPA"
                  value={averageGpa}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{ width: "100px", color: "black", p: "0px 5px 0px 5px" }}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: "10px 0px 10px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    p: "0px 5px 0px 5px",
                  }}
                >
                  Weighted GPA:
                </Typography>
                <TextField
                  size="small"
                  id="outlined-basic"
                  name="weighted_GPA"
                  value={weightedGpa}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{ width: "100px", color: "black", p: "0px 5px 0px 5px" }}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            sx={{ color: constants.tertiary }}
            onClick={() => handleClose(false, formData)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              borderColor: constants.tertiary,
              border: "1px solid #FE8D02",
              fontSize: "12px",
              color: "white",
              background:
                "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
              backgroundSize: "cover",

              "&:hover": {
                background:
                  "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
              },
            }}
            onClick={() => handleClose(true, formData)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AlertGradesAddDialogSlide;
