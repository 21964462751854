import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import constants from "../constants/constant";
import goProfile from "../assets/images/summary/goProfile.png"
import ProgressBar from "../utils/ProgressBar";
import { useNavigate } from "react-router-dom";


const GoProfile = () => {
    const navigate = useNavigate()

  const handleClick = ()=>{
    navigate("../grades/summary");
  }

  return (
    <>
      <Grid>
        <Grid
          sx={{
            background: constants.seconday,
            borderRadius: "8px",
            margin: "10px",
            height: "auto",
            justifyContent: "center",
            alignItems: "center",
            p: "0px 0px 10px 0px",
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
              alignItems="center"
              sx={{ p: "10px 0px 10px 0px" }}
            >
              <img src={goProfile} style={{ height: 100}} alt="Uploaded" />
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "5px 10px 0px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    p: "0px 5px 0px 5px",
                    // textTransform: "capitalize",
                    color: " #667085",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Standout by adding your awards , Grades , services, scores and
                  more ....
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "5px 10px 0px 20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ProgressBar />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "5px 20px 0px 20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    borderColor: constants.tertiary,
                    border: "1px solid #FE8D02",
                    color: "white",
                    background:
                      "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                    backgroundSize: "cover",
                    
                    "&:hover": {
                      background:
                        "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                    },
                  }}
                  fullWidth
                  onClick={handleClick}
                >
                  Go to Profile
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GoProfile;
