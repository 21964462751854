import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import http from "../config/http-common";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";

import cslist from "../assets/images/formVector/cslist.png";
import constants from "../constants/constant";
import { useState } from "react";
import { toast } from "react-toastify";
import AlertDialogSlide from "./AlertDialogSlide";
import AlertCsEditDialogSlide from "./AlertCsEditDialogSlide";

const CustomCard = styled(Card)({
  elevation: 0,
  boxShadow: "none",
  borderRadius: "0px",
});

export default function CsDataList({ data, count, isSummary }: any) {
  const activity = data?.activity;
  const desc = data?.desc;
  const endDate = data?.end_date;
  const startDate = data?.start_date;
  const duration = data?.duration === 1 ? "1 hour" : `${data?.duration} hours`;

  const sdate = new Date(startDate);

  const options: any = { year: "numeric", month: "long", day: "numeric" };
  const formattedSDate = sdate.toLocaleDateString("en-US", options);

  const edate = new Date(endDate);

  const options2: any = { year: "numeric", month: "long", day: "numeric" };
  const formattedEDate = edate.toLocaleDateString("en-US", options2);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  const handleOpenDialog = (id: any) => {
    setDeleteId(id);
    setDialogOpen(true);
  };

  const handleOpenEdit = (id: any) => {
    setEditId(id);
    setEditOpen(true);
  };

  const [toggleCount, setToggleCount] = useState(count);

  const handleToggle = async (state: any, id: any) => {
    console.log("toggle count before if ", toggleCount);
    if (toggleCount >= 3 && !state) {
      toast.error("You can only display up to 3 awards at a time.");
      return;
    }
    const updatedIsDisplayed = !state;
    try {
      const response = await http.post(`/api/community/update/${id}`, {
        toDisplay: updatedIsDisplayed,
      });
      console.log(response);
      // toast.success("Awards Data Saved Successfully");
      setToggleCount((prevCount: any) =>
        updatedIsDisplayed ? prevCount + 1 : prevCount - 1
      );
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
    window.location.reload();
  };

  const handleDialogClose = async (confirmed: any) => {
    setDialogOpen(false);
    if (confirmed && deleteId) {
      try {
        const response = await http.delete(`/api/community/delete/${deleteId}`);
        const result = response.data;
        console.log(result.response.data);
        // toast.success("Service data deleted Successfully!");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } catch (error: any) {
        if (error.response) {
          console.error("Error deleting grade:", error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          console.error("Network error:", error.message);
          toast.error(error.message);
        }
      }
    }
  };

  const handleEditClose = async (confirmed: any, data: any) => {
    setEditOpen(false);
    if (confirmed && editId) {
      try {
        const response = await http.post(
          `/api/community/update/${editId}`,
          data
        );
        const result = response.data;
        console.log(result.response.data);
        // toast.success("Cs data updated Successfully!");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } catch (error: any) {
        if (error.response) {
          console.error("Error deleting grade:", error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          console.error("Network error:", error.message);
          toast.error(error.message);
        }
      }
    } else {
      setEditId(null);
    }
  };

  return (
    <>
      <CustomCard sx={{ maxWidth: "100%", width: "100%", borderRadius: "0px" }}>
        <CardHeader
          avatar={<Avatar alt="" src={cslist} sx={{ width: 40, height: 40 }} />}
          action={
            <>
              {!isSummary && (
                <>
                  <Tooltip title="Click to toggle visibility" arrow>
                    <Switch
                      size="small"
                      checked={data?.toDisplay}
                      onClick={() => handleToggle(data?.toDisplay, data?._id)}
                      inputProps={{ "aria-label": "toggle display" }}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: constants.tertiary + " !important",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: constants.tertiary + " !important",
                          },
                      }}
                    />
                  </Tooltip>
                  <IconButton aria-label="settings" onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              )}
            </>
          }
          title={
            <Typography
              variant="body1"
              sx={{ color: constants.text, textTransform: "capitalize" }}
            >
              {activity}
            </Typography>
          }
          subheader={
            <>
              <Typography variant="body2" sx={{ color: constants.typo }}>
                {duration} / Week
              </Typography>
              <Typography variant="body2" sx={{ color: constants.typo }}>
                {formattedSDate} - {formattedEDate}
              </Typography>
              <Typography variant="body2" sx={{ color: constants.text }}>
                {desc}
              </Typography>
              {!isSummary && (
                <>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      sx={{ color: constants.text }}
                      onClick={() => handleOpenEdit(data?._id)}
                    >
                      <ListItemIcon>
                        <EditIcon
                          sx={{ color: constants.text, fontSize: "20px" }}
                        />
                      </ListItemIcon>
                      Edit
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "red" }}
                      onClick={() => handleOpenDialog(data?._id)}
                    >
                      <ListItemIcon>
                        <DeleteOutlineIcon
                          sx={{ color: "red", fontSize: "20px" }}
                        />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              )}
              <AlertDialogSlide open={dialogOpen} onClose={handleDialogClose} />
              {editId && (
                <AlertCsEditDialogSlide
                  open={editOpen}
                  onClose={handleEditClose}
                  data={data}
                />
              )}
            </>
          }
          sx={{ alignItems: "flex-start" }}
        />
      </CustomCard>
    </>
  );
}
