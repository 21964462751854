import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import constants from "../constants/constant";
import { Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertAwardsAddDialogSlide({ open, onClose, data }: any) {
  const handleClose = (result: any, data: any) => {
    onClose(result, data);
  };


  const [formData, setFormData] = useState<any>({
    class: data,
    award: "",
    desc: "",
    date: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: any) => {
    setFormData({ ...formData, date: date });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(false, formData)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Add {data} Awards and Honors</DialogTitle>
      <DialogContent>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={2}
          sx={{ p: "25px 0px 5px 0px" }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // spacing={2}
            sx={{ p: "0px 0px 0px 15px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={{ fontSize: "12px" }}>
                  Award Name
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <TextField
                // size="small"
                id="outlined-basic"
                name="award"
                value={formData.award}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                sx={{ fontSize: "12px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "10px 0px 0px 0px" }}
              >
                <Typography sx={{ fontSize: "12px" }}>
                  Awarded Date
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    fontSize: "12px",
                    height: "2px",
                    tabSize: "small",
                    width: "100%",
                  }}
                  name="date"
                  value={formData.date}
                  onChange={(date) => handleDateChange(date)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "40px 0px 0px 15px" }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Award Description
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                id="outlined-basic"
                name="desc"
                value={formData.desc}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{ color: constants.tertiary }}
          onClick={() => handleClose(false, formData)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "10px",
            borderColor: constants.tertiary,
            border: "1px solid #FE8D02",
            fontSize: "14px",
            color: "white",
            background:
              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            backgroundSize: "cover",
            "&:hover": {
              background:
                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            },
          }}
          onClick={() => handleClose(true, formData)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertAwardsAddDialogSlide;
