import constants from "./constant";

export const boxMargin = {
  xs: "0px",
  sm: "0px",
  md: "0px",
  lg: "50px",
  xl: "50px",
};
export const buttonWidth = {
  xs: "100px",
  sm: "100px",
  md: "100px",
  lg: "150px",
  xl: "150px",
};
// const boxPaperWidth = {
//         // xs: "calc(100vw - 100px)",
//       // sm: "calc(100vw - 100px)",
//       // md: "calc(100vw - 100px)",
//       lg: "calc(50vw - 100px)",
//       xl: "calc(50vw - 100px)",
// }
// const gridStyleOne = {
//     lg: "30px 30px 30px 0px",
//     xl: "30px 30px 30px 0px",
//     md: "5px 5px 5px 5px",
//     sm: "5px 5px 5px 5px",
//     xs: "5px 5px 5px 5px",
// }
// const gridStyleTwo = {
//   lg: "30px 30px 30px 0px",
//   xl: "30px 30px 30px 0px",
//   md: "15px 15px 15px 5px",
//   sm: "15px 15px 15px 5px",
//   xs: "15px 15px 15px 5px",
// }
// const gridStyleThree = {
// lg: "50px 50px 30px 0px",
// xl: "50px 50px 30px 0px",
// md: "15px 15px 15px 10px",
// sm: "15px 15px 15px 10px",
// xs: "15px 15px 15px 10px",
// }
// const gridStyleFour = {
// lg: "0px 30px 60px 0px",
// xl: "0px 30px 60px 0px",
// md: "15px 15px 15px 10px",
// sm: "15px 15px 15px 10px",
// xs: "15px 15px 15px 10px",
// }
// const fontOne = {
//   xs: "20px",
//   sm: "20px",
//   md: "15px",
//   lg: "36px",
//   xl: "36px",
// }
// const fontTwo = {
// xs: "15px",
// sm: "15px",
// md: "15px",
// lg: "20px",
// xl: "20px",
// }
export const logRegStyle = {
  containerFlexDirection: {
    xs: "column",
    sm: "column",
    md: "column",
    lg: "row",
    xl: "row",
  },

  paperStyle: {
    background: constants.seconday,
    borderRadius: {
      xs: "0",
      sm: "0",
      md: "0",
      lg: "25px",
      xl: "25px",
    },
    margin: {
      xs: "0px",
      sm: "0px",
      md: "0px",
      lg: "50px",
      xl: "50px",
    },
    width: {
      // xs: "calc(100vw - 100px)",
      // sm: "calc(100vw - 100px)",
      // md: "calc(100vw - 100px)",
      lg: "calc(50vw - 100px)",
      xl: "calc(50vw - 100px)",
    },
    height: {
      xs: "calc(30vh - 100px)",
      sm: "calc(30vh - 100px)",
      md: "calc(30vh - 100px)",
      lg: "calc(90vh - 100px)",
      xl: "calc(90vh - 100px)",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: {
      xs: "center",
      sm: "center",
      md: "center",
      lg: "center",
      xl: "center",
    },
    flexDirection: {
      xs: "row",
      sm: "row",
      md: "row",
      lg: "column",
      xl: "column",
    },
    p: {
      xs: "30px",
      sm: "30px",
      md: "30px",
      lg: "0px",
      xl: "0px",
    },
  },
  boxStyle: {
    height: "calc(100vh - 100px)",
    margin: {
      xs: "0px",
      sm: "0px",
      md: "0px",
      lg: "50px",
      xl: "50px",
    },
    width: {
      // xs: "calc(100vw - 100px)",
      // sm: "calc(100vw - 100px)",
      // md: "calc(100vw - 100px)",
      lg: "calc(50vw - 100px)",
      xl: "calc(50vw - 100px)",
    },
  },
  gridSytles: {
    styleOne: {
      lg: "10px 0px 10px 0px",
      xl: "30px 30px 30px 0px",
      md: "5px 5px 5px 5px",
      sm: "5px 5px 5px 5px",
      xs: "5px 5px 5px 5px",
    },
    styleTwo: {
      lg: "30px 30px 30px 0px",
      xl: "30px 30px 30px 0px",
      md: "15px 15px 15px 5px",
      sm: "15px 15px 15px 5px",
      xs: "15px 15px 15px 5px",
    },
    styleThree: {
      lg: "10px 20px 10px 0px",
      xl: "50px 50px 30px 0px",
      md: "15px 15px 15px 10px",
      sm: "15px 15px 15px 10px",
      xs: "15px 15px 15px 10px",
    },
    styleFour: {
      lg: "0px 30px 20px 0px",
      xl: "0px 30px 20px 0px",
      md: "15px 15px 15px 10px",
      sm: "15px 15px 15px 10px",
      xs: "15px 15px 15px 10px",
    },
  },
  fontSize: {
    sizeOne: { xs: "20px", sm: "20px", md: "15px", lg: "20px", xl: "20px" },
    sizeTwo: {
      xs: "15px",
      sm: "15px",
      md: "15px",
      lg: "15px",
      xl: "15px",
    },
  },
  logRegButton: {
    fontWeight: "600px",
    fontSize: {
      xs: "15px",
      sm: "15px",
      md: "15px",
      lg: "20px",
      xl: "20px",
    },
    background: "",
    color: "white",
    borderRadius: "25px",
    textTransform: "capitalize",
    "&:active": {
      background: "#2D438E",
    },
  },
  forgetPasswordTypo: {
    p: "0px 0px 0px 0px",
    color: "#4D4D4D",
    cursor: "pointer",
  },
  logRegHereGrid: {
    color: "#0C21C1",
    fontWeight: 600,
    fontSize: {
      xs: "15px",
      sm: "15px",
      md: "15px",
      lg: "15px",
      xl: "15px",
    },
    p: "0px 10px 0px 10px",
    cursor: "pointer",
  },
  roleButton: {
    fontWeight: "bold",
    borderRadius: "25px",
    textTransform: "capitalize",
    width: {
      xs: "100px",
      sm: "100px",
      md: "100px",
      lg: "150px",
      xl: "150px",
    },
  },
  Typo: {
    fontWeight: "400",
    fontSize: {
      xs: "15px",
      sm: "15px",
      md: "15px",
      lg: "15px",
      xl: "15px",
    },
  },
};
