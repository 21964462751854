import { Box, Drawer, Grid, IconButton, Paper } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StudentFormComponent from "../components/StudentFormComponent";
import OptionsFormComponent from "../components/OptionsFormComponent";
import StudentFormDisplayComponent from "../components/StudentFormDisplayComponent";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import EcaFormComponent from "../components/EcaFormComponent";
import CsFormComponent from "../components/CsFormComponent";
import AwardsFormComponent from "../components/AwardsFormComponent";
import GradeIbFormComponent from "../components/GradesIbFormComponent";
import GradeFormComponent from "../components/GradeFormComponent";
import GradesApList from "../pages/GradesApList";
import GradesIbList from "../pages/GradesIbList";
import CsList from "../pages/CsList";
import AwardsList from "../pages/AwardsList";
import constants from "../constants/constant";
import TestFormComponent from "../components/TestFormComponent";
import TestSummary from "../pages/TestScoresSummary";
import TestList from "../pages/TestList";
import SummaryComponent from "../components/SummaryComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TranscriptList from "../pages/TranscriptList";
import CompetitionList from "../pages/CompetitionList";
import StudentFormViewPage from "../pages/StudentFormViewPage";
import StudentFormEditComponent from "../components/StudentFormEditComponent";
import MenuIcon from "@mui/icons-material/Menu";

const FormLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState(
    location.pathname === "/grades"
      ? "apgrades"
      : location.pathname === "/grades/ap"
      ? "apgrades"
      : location.pathname === "/grades/ap/summary"
      ? "apgradeslist"
      : location.pathname === "/grades/summary"
      ? "apgradeslist"
      : location.pathname === "/grades/ib"
      ? "ibgrades"
      : location.pathname === "/grades/ib/summary"
      ? "ibgradeslist"
      : location.pathname === "/awardspage"
      ? "anh"
      : location.pathname === "/awardspage/summary"
      ? "anhlist"
      : location.pathname === "/communityservices"
      ? "cs"
      : location.pathname === "/communityservices/summary"
      ? "cslist"
      : location.pathname === "/extracurricularactivities"
      ? "eca"
      : location.pathname === "/extracurricularactivities/summary"
      ? "ecalist"
      : location.pathname === "/testscores"
      ? "testscores"
      : location.pathname === "/testscores/summary"
      ? "testscoreslist"
      : location.pathname === "/testscores/summary/edit"
      ? "testscoreslist"
      : location.pathname === "/summary"
      ? "summary"
      : "studentProfile"
  );

  const [gradesOpen, setGradesOpen] = useState(
    activeButton === "grades" ||
      activeButton === "apgrades" ||
      activeButton === "apgradeslist" ||
      activeButton === "ibgrades" ||
      activeButton === "ibgradeslist"
  );
  const [apOpen, setapOpen] = useState(
    activeButton === "apgrades" || activeButton === "apgradeslist"
  );
  const [ibOpen, setibOpen] = useState(
    activeButton === "ibgrades" || activeButton === "ibgradeslist"
  );
  const [anhOpen, setanhOpen] = useState(
    activeButton === "anh" || activeButton === "anhlist"
  );
  const [csOpen, setcsOpen] = useState(
    activeButton === "cs" || activeButton === "cslist"
  );
  const [ecaOpen, setecaOpen] = useState(activeButton === "eca");
  const [testOpen, setTestOpen] = useState(activeButton === "testscores");
  const [summaryOpen, setSummaryOpen] = useState(activeButton === "summary");
   const [drawerOpen, setDrawerOpen] = useState(false);

   const toggleDrawer = () => {
     setDrawerOpen(!drawerOpen);
   };

   const listItems = [
     { label: "Account", value: "account" },
     { label: "Grades", value: "apgradeslist" },
     { label: "Extracurricular Activities", value: "ecalist" },
     { label: "Awards & Honors", value: "anhlist" },
     { label: "Community Services", value: "cslist" },
     { label: "Test Scores", value: "testscoreslist" },
     { label: "Transcript", value: "transcript" },
   ];

  const handleButtonChange = (value: any) => {
    setActiveButton(value);
     setDrawerOpen(false);
    if (value === "studentProfile") {
      navigate("../studentform");
    }
    if (value === "account") {
      navigate("../account");
    }
    if (value === "grades") {
      setGradesOpen(!gradesOpen);
      navigate("../grades");
    }
    if (value === "apgrades") {
      setGradesOpen(true);
      setapOpen(!apOpen);
      navigate("../grades/ap");
    }
    if (value === "apgradesform") {
      setGradesOpen(true);
      setapOpen(true);
      navigate("../grades/ap");
    }
    if (value === "apgradeslist") {
      setGradesOpen(true);
      setapOpen(true);
      navigate("../grades/summary");
    }
    if (value === "transcript") {
      setGradesOpen(true);
      setapOpen(true);
      navigate("../transcript/summary");
    }
    if (value === "ibgrades") {
      setGradesOpen(true);
      setibOpen(!ibOpen);
      navigate("../grades/ib");
    }
    if (value === "ibgradesform") {
      setGradesOpen(true);
      setibOpen(true);
      navigate("../grades/ib");
    }
    if (value === "ibgradeslist") {
      setGradesOpen(true);
      setibOpen(true);
      navigate("../grades/ib/summary");
    }
    if (value === "eca") {
      setecaOpen(!ecaOpen);
      navigate("../extracurricularactivities");
    }
    if (value === "ecaform") {
      setecaOpen(true);
      navigate("../extracurricularactivities");
    }
    if (value === "ecalist") {
      setecaOpen(true);
      navigate("../extracurricularactivities/summary");
    }
    if (value === "anh") {
      setanhOpen(!anhOpen);
      navigate("../awardspage");
    }
    if (value === "anhform") {
      setanhOpen(true);
      navigate("../awardspage");
    }
    if (value === "anhlist") {
      setanhOpen(true);
      navigate("../awardspage/summary");
    }
    if (value === "cs") {
      setcsOpen(!csOpen);
      navigate("../communityservices");
    }
    if (value === "testscores") {
      setTestOpen(!testOpen);
      navigate("../testscores");
    }
    if (value === "testscoreslist") {
      setTestOpen(true);
      navigate("../testscores/summary");
    }
    if (value === "csform") {
      setcsOpen(true);
      navigate("../communityservices");
    }
    if (value === "cslist") {
      setcsOpen(true);
      navigate("../communityservices/summary");
    }
    if (value === "summary") {
      setSummaryOpen(!summaryOpen);
      navigate("../summary");
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          p: {
            xs: "0px",
            sm: "0px",
            md: "20px 20px 20px 20px",
            lg: "20px 20px 20px 20px",
            xl: "20px 20px 20px 20px",
          },
        }}
      >
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "row" },
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              p: {
                xs: "0px 10px 0px 10px",
                sm: "0px 10px 0px 10px  ",
                md: "20px 20px 20px 20px",
                lg: "20px 20px 20px 20px",
                xl: "20px 20px 20px 20px",
              },
            }}
          >
            <>
              <Paper
                sx={{
                  background: constants.seconday,
                  height: { xs: "10%", sm: "10%", md: "100%" ,lg:'380px', xl:"380px"},
                  width: "100%",
                }}
              >
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                  {isMobile || isTablet ? (
                    <>
                      <IconButton
                        onClick={toggleDrawer}
                        sx={{ marginLeft: "auto" }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={toggleDrawer}
                      >
                        <Box sx={{ width: 250 }}>
                          {listItems.map((item) => (
                            <ListItemButton
                              key={item.value}
                              onClick={() => handleButtonChange(item.value)}
                              sx={{
                                backgroundColor:
                                  activeButton === item.value
                                    ? "white"
                                    : "transparent",
                                padding: "10px 16px",
                                "&:hover": {
                                  backgroundColor:
                                    activeButton === item.value
                                      ? "#f5f5f5"
                                      : "#f0f0f0",
                                },
                              }}
                            >
                              <ListItemText primary={item.label} />
                            </ListItemButton>
                          ))}
                        </Box>
                      </Drawer>
                    </>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      {listItems.map((item) => (
                        <ListItemButton
                          key={item.value}
                          onClick={() => handleButtonChange(item.value)}
                          sx={{
                            backgroundColor:
                              activeButton === item.value
                                ? "#f5f5f5"
                                : "transparent",
                            borderRadius:
                              activeButton === item.value ? "8px" : "0",
                            margin:
                              activeButton === item.value ? "5px 5px" : "0",
                            padding: "10px 16px",
                            "&:hover": {
                              backgroundColor:
                                activeButton === item.value
                                  ? "#f5f5f5"
                                  : "#f0f0f0",
                            },
                          }}
                        >
                          <ListItemText
                            primary={item.label}
                            sx={{
                              color:
                                activeButton === item.value ? "#000" : "#555",
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            sx={{ p: "20px 20px 0px 20px" }}
          >
            <Paper sx={{ background: "white" }}>
              <Grid sx={{ p: "0px 20px 0px 20px" }}>
                {location.pathname === "/studentform" && (
                  <>
                    <StudentFormComponent />
                  </>
                )}
                {location.pathname === "/form-check" && (
                  <>
                    <StudentFormDisplayComponent />
                  </>
                )}
                {location.pathname === "/account" && (
                  <>
                    <StudentFormViewPage />
                  </>
                )}
                {location.pathname === "/account/edit" && (
                  <>
                    <StudentFormEditComponent />
                  </>
                )}
                {location.pathname === "/grades/ap" && (
                  <>
                    <GradeFormComponent />
                  </>
                )}
                {location.pathname === "/grades" && (
                  <>
                    <GradeFormComponent />
                  </>
                )}
                {location.pathname === "/grades/ap/summary" && (
                  <>
                    <GradesApList />
                  </>
                )}
                {location.pathname === "/grades/summary" && (
                  <>
                    <GradesApList />
                  </>
                )}
                {location.pathname === "/transcript/summary" && (
                  <>
                    <TranscriptList />
                  </>
                )}
                {location.pathname === "/grades/ib" && (
                  <>
                    <GradeIbFormComponent />
                  </>
                )}
                {location.pathname === "/grades/ib/summary" && (
                  <>
                    <GradesIbList />
                  </>
                )}
                {location.pathname === "/awardspage" && (
                  <>
                    <AwardsFormComponent />
                  </>
                )}
                {location.pathname === "/awardspage/summary" && (
                  <>
                    <AwardsList />
                  </>
                )}
                {location.pathname === "/communityservices" && (
                  <>
                    <CsFormComponent />
                  </>
                )}
                {location.pathname === "/communityservices/summary" && (
                  <>
                    <CsList />
                  </>
                )}
                {location.pathname === "/optionspage" && (
                  <>
                    <OptionsFormComponent />
                  </>
                )}
                {location.pathname === "/grades" && (
                  <>{/* <GradesOptionsComponent /> */}</>
                )}
                {location.pathname === "/extracurricularactivities" && (
                  <>
                    <EcaFormComponent />
                  </>
                )}
                {location.pathname === "/extracurricularactivities/summary" && (
                  <>
                    <CompetitionList />
                  </>
                )}
                {location.pathname === "/testscores" && (
                  <>
                    <TestFormComponent />
                  </>
                )}
                {location.pathname === "/testscores/summary" && (
                  <>
                    <TestSummary />
                  </>
                )}
                {location.pathname === "/testscores/summary/edit" && (
                  <>
                    <TestList />
                  </>
                )}
                {location.pathname === "/summary" && (
                  <>
                    <SummaryComponent />
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormLayout;
