import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BadgeIcon from "@mui/icons-material/Badge";
import constants from "../constants/constant";
import SchoolIcon from "@mui/icons-material/School";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClassIcon from "@mui/icons-material/Class";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import SpeedIcon from "@mui/icons-material/Speed";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { toast } from "react-toastify";
import http from "../config/http-common";
import EditIcon from "@mui/icons-material/Edit";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SummaryComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const studentImage = useSelector((state: any) => state.studentImage);
  const studentData = useSelector((state: any) => state.studentDetails);
  const studentGrades = useSelector((state: any) => state.studentGrades);
  const studentAwards = useSelector((state: any) => state.studentAwards);
  const studentCs = useSelector((state: any) => state.studentCs);
  const studentEca = useSelector((state: any) => state.studentEca);

  const studentTestScores = useSelector(
    (state: any) => state.studentTestScores
  );
  const imageUrl = studentImage.data;

  const examDetails = studentGrades?.data || [];
  const testDetails = studentTestScores?.data || [];
  const awardsDetails = studentAwards?.data || [];
  const CsDetails = studentCs?.data || [];
  const ecaDetails = studentEca?.data || [];

  const awardsData =
    awardsDetails.length > 0 &&
    awardsDetails.filter((item: any) => item.toDisplay === true);
  const csData =
    CsDetails.length > 0 &&
    CsDetails.filter((item: any) => item.toDisplay === true);
  const ecaData =
    ecaDetails.length > 0 &&
    ecaDetails.filter((item: any) => item.toDisplay === true);
  const awardNames =
    awardsData.length > 0
      ? awardsData.map((item: any) => item.award).join(" | ")
      : "";
  const csNames =
    csData.length > 0
      ? csData.map((item: any) => item.activity).join(" | ")
      : "";
  const ecaNames =
    ecaData.length > 0
      ? ecaData
          .map((item: any) =>
            item.ecaName === "Others" ? item.otherName : item.ecaName
          )
          .join(" | ")
      : "";

  const actData =
    testDetails.length > 0 &&
    testDetails.filter((item: any) => item.test === "ACT");
  const satData =
    testDetails.length > 0 &&
    testDetails.filter((item: any) => item.test === "SAT");

  const actScore = actData[0]?.total;
  const satScore = satData[0]?.total;

  const apFreshData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Freshman"
    );

  const apSophmoreData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Sophmore"
    );
  const apJnrData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Junior"
    );
  const apSnrData =
    examDetails.length > 0 &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Senior"
    );

  function calculateAverage(examDetails: any) {
    let sum = 0;

    for (let i = 0; i < examDetails?.length; i++) {
      sum += examDetails[i].subject[0].average_GPA;
    }
    const numberOfRows = examDetails?.length;
    const average = sum / numberOfRows;
    return average.toFixed(2);
  }

  const averageFreshGpa: number = Number(calculateAverage(apFreshData));
  const averageSophmoreGpa: number = Number(calculateAverage(apSophmoreData));
  const averageJnrGpa: number = Number(calculateAverage(apJnrData));
  const averageSnrGpa: number = Number(calculateAverage(apSnrData));

  const unWeightedFreshGpa = isNaN(averageFreshGpa) ? 0 : averageFreshGpa;
  const unWeightedSophmoreGpa = isNaN(averageSophmoreGpa)
    ? 0
    : averageSophmoreGpa;
  const unWeightedJnrGpa = isNaN(averageJnrGpa) ? 0 : averageJnrGpa;
  const unWeightedSnrGpa = isNaN(averageSnrGpa) ? 0 : averageSnrGpa;

  const gpas = [
    unWeightedFreshGpa,
    unWeightedSophmoreGpa,
    unWeightedJnrGpa,
    unWeightedSnrGpa,
  ];
  const nonZeroGpas = gpas.filter((gpa) => gpa !== 0);

  const UnWeGpa =
    (unWeightedFreshGpa +
      unWeightedSophmoreGpa +
      unWeightedJnrGpa +
      unWeightedSnrGpa) /
    nonZeroGpas.length;
  let totalUnWGpa: any = UnWeGpa.toFixed(2);

  if (isNaN(totalUnWGpa)) {
    totalUnWGpa = "no entry";
  }

  const totalUnWeightedGpa = totalUnWGpa;

  const [formData, setFormData] = useState<any>({
    bio: studentData?.data?.bio,
  });

  useEffect(() => {
    if (studentData?.data?.bio) {
      setFormData({ bio: studentData.data.bio });
    }
  }, [studentData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (event: any) => {
    event.preventDefault();
    try {
      const response = await http.post("/api/studentform", formData);
      console.log(response);

      // toast.success("Student Data Saved Successfully");
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ p: "10px 10px 0px 10px" }}
            >
              <Paper
                sx={{
                  display: "flex",
                  p: "5px 5px 5px 5px",
                  border: `2px solid ${constants.tertiary}`,
                }}
              >
                <BadgeIcon />
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.first_name
                    ? studentData.data?.first_name
                    : ""}{" "}
                  {studentData?.data && studentData?.data?.last_name
                    ? studentData.data?.last_name
                    : ""}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
              alignItems="center"
              sx={{ p: "20px 0px 20px 0px" }}
            >
              <Avatar
                alt=""
                src={imageUrl ? imageUrl : ""}
                sx={{ width: 150, height: 150 }}
              />
            </Grid>
            {isMobile || isTablet ? (
              <></>
            ) : (
              <>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      m: "2px",
                      p: "2px",
                      borderRadius: {
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "25px",
                        xl: "25px",
                      },
                    }}
                  >
                    Bio:
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    name="bio"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={handleInputChange}
                    value={formData.bio}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ p: "10px 0px 0px 20px" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleSave}
                    sx={{
                      backgroundColor: constants.tertiary,
                      marginLeft: "auto",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "20px 10px 0px 10px" }}
            >
              <Paper
                sx={{
                  display: "flex",
                  p: "5px 5px 5px 5px",
                  border: `2px solid ${constants.tertiary}`,
                }}
              >
                <SchoolIcon />
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.other_school
                    ? studentData.data?.other_school
                    : studentData.data?.school}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 10px 0px 10px" }}
            >
              <Paper
                sx={{
                  display: "flex",
                  p: "5px 5px 5px 5px",
                  border: `2px solid ${constants.tertiary}`,
                }}
              >
                <LocationOnIcon />
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.city
                    ? studentData.data?.city
                    : ""}{" "}
                  ,{" "}
                  {studentData?.data && studentData?.data?.state
                    ? studentData.data?.state
                    : ""}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 10px 0px 10px" }}
            >
              <Paper
                sx={{
                  display: "flex",
                  p: "5px 5px 5px 5px",
                  border: `2px solid ${constants.tertiary}`,
                }}
              >
                <ClassIcon />
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  {studentData?.data && studentData?.data?.graduating_class
                    ? studentData?.data?.graduating_class
                    : ""}{" "}
                </Typography>
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 10px 0px 10px" }}
            >
              <Paper
                sx={{
                  display: "flex",
                  p: "5px 5px 5px 5px",
                  border: `2px solid ${constants.tertiary}`,
                }}
              >
                <ScoreboardIcon />
                <Typography
                  sx={{
                    p: "0px 10px 0px 10px",
                    textTransform: "capitalize",
                  }}
                >
                  {totalUnWeightedGpa} (Unweighted)
                </Typography>
              </Paper>
            </Grid>
            {(actScore || satScore) && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 10px 0px 10px" }}
                >
                  <Paper
                    sx={{
                      display: "flex",
                      p: "5px 5px 5px 5px",
                      border: `2px solid ${constants.tertiary}`,
                    }}
                  >
                    <SpeedIcon />
                    {actScore && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            p: "0px 10px 0px 10px",
                            textTransform: "capitalize",
                          }}
                        >
                          ACT:
                        </Typography>
                        <Typography
                          sx={{
                            p: "0px 10px 0px 0px",
                            textTransform: "capitalize",
                          }}
                        >
                          {actScore}/ 36{" "}
                        </Typography>
                      </>
                    )}
                    {satScore && (
                      <>
                        <Typography sx={{ fontWeight: "bold" }}>
                          | SAT:
                        </Typography>
                        <Typography
                          sx={{
                            p: "0px 10px 0px 0px",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {satScore} / 1600
                        </Typography>
                      </>
                    )}
                  </Paper>
                </Grid>
              </>
            )}
            {awardNames && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 10px 0px 10px" }}
                >
                  <Paper
                    sx={{
                      display: "flex",
                      p: "5px 5px 5px 5px",
                      border: `2px solid ${constants.tertiary}`,
                    }}
                  >
                    <EmojiEventsIcon />
                    <Typography
                      sx={{
                        p: "0px 10px 0px 10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {awardNames}
                    </Typography>
                  </Paper>
                </Grid>
              </>
            )}
            {csNames && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 10px 0px 10px" }}
                >
                  <Paper
                    sx={{
                      display: "flex",
                      p: "5px 5px 5px 5px",
                      border: `2px solid ${constants.tertiary}`,
                    }}
                  >
                    <Diversity2Icon />
                    <Typography
                      sx={{
                        p: "0px 10px 0px 10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {csNames}
                    </Typography>
                  </Paper>
                </Grid>
              </>
            )}
            {ecaNames && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 10px 0px 10px" }}
                >
                  <Paper
                    sx={{
                      display: "flex",
                      p: "5px 5px 5px 5px",
                      border: `2px solid ${constants.tertiary}`,
                    }}
                  >
                    <WorkspacePremiumOutlinedIcon />
                    <Typography
                      sx={{
                        p: "0px 10px 0px 10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {ecaNames}
                    </Typography>
                  </Paper>
                </Grid>
              </>
            )}
            {isMobile || isTablet ? (
              <>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      m: "2px",
                      p: "2px",
                      borderRadius: {
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "25px",
                        xl: "25px",
                      },
                    }}
                  >
                    Bio:
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    name="bio"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={handleInputChange}
                    value={formData.bio}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ p: "10px 0px 0px 20px" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleSave}
                    sx={{
                      backgroundColor: constants.tertiary,
                      marginLeft: "auto",
                    }}
                  >
                    Save Bio
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryComponent;
