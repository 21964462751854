import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import junior from "../assets/images/formVector/jnr.png";
import senior from "../assets/images/formVector/snr.png";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import http from "../config/http-common";
import { useNavigate } from "react-router-dom";
import usStateCity from "../assets/jsonData/usStateCity.json";
import classList from "../assets/jsonData/class.json";
import schoolData from "../assets/jsonData/schoolData.json";
import francisp from "../assets/images/schoolLogo/francisp.png";
import jonesp from "../assets/images/schoolLogo/jonesp.png";
import lanep from "../assets/images/schoolLogo/lanep.png";
import latin from "../assets/images/schoolLogo/latin.png";
import newtrierp from "../assets/images/schoolLogo/newtrierp.png";
import northsidep from "../assets/images/schoolLogo/northsidep.png";
import walterp from "../assets/images/schoolLogo/walterp.png";
import whitneyp from "../assets/images/schoolLogo/whitneyp.png";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toast } from "react-toastify";
import constants from "../constants/constant";
import LoadingPage from "../utils/Loading";
import InfoIcon from "@mui/icons-material/Info";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StudentFormComponent = () => {
  const navigate = useNavigate();
  const stateCity: any = usStateCity;
  const schoolAdd: any = schoolData;
  const classData: any = classList;

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear + 26; year++) {
    years.push(year);
  }
  const [formData, setFormData] = useState<any>({
    student_id: "",
    first_name: "",
    last_name: "",
    state: "",
    city: "",
    school: "",
    other_school: "",
    school_address: "",
    school_address2: "",
    class_year: "",
    graduating_class: "",
    isVerified: false,
    accountType: "public",
    bio: "",
  });

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    const requiredKeys = [
      "student_id",
      "first_name",
      "state",
      "city",
      "school",
      "class_year",
      "school_address",
      "graduating_class",
    ];

    const checkFormCompleteness = () => {
      return requiredKeys.every(
        (key) => formData[key] !== "" && formData[key] !== null
      );
    };

    setIsFormComplete(checkFormCompleteness());
  }, [formData]);

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "school") {
      setFormData({
        ...formData,
        [name]: value,
        school_address: schoolAdd[value] || "",
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [image, setImage] = useState<any>(null);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    setImage(null);
  };

  const schoolLogo =
    formData.school === "Jones College Prep"
      ? jonesp
      : formData.school === "Whitney M. Young Magnet Highschool"
      ? whitneyp
      : formData.school === "Lane Tech College Prep High School"
      ? lanep
      : formData.school === "Walter Payton College Prepratory Highschool"
      ? walterp
      : formData.school === "Northside College Prep"
      ? northsidep
      : formData.school === "Latin School Of Chicago"
      ? latin
      : formData.school === "Francis W Parker School"
      ? francisp
      : formData.school === "New Trier Township Highschool"
      ? newtrierp
      : "";

  const handleSave = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (isFormComplete) {
        const response = await http.post("/api/studentform", formData);
        if (image) {
          const imageResponse = await http.post("/api/student-image/upload", {
            image,
          });
          console.log(imageResponse);
        }
        console.log(response);

        //  toast.success("Student Details Saved");
        navigate("../form-view");
      } else {
        toast.error("fill out required fields");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
    setIsLoading(false);
  };
  // const handleNext = () => {
  //   navigate("../grades");
  // };

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper
              elevation={3}
              sx={{
                background: "white",
                borderRadius: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "25px",
                  xl: "25px",
                },
                margin: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "25px",
                  xl: "25px",
                },
                width: {
                  xs: "calc(100vw - 100px)",
                  sm: "calc(100vw - 100px)",
                  md: "calc(100vw - 100px)",
                  lg: "calc(55vw - 100px)",
                  xl: "calc(55vw - 100px)",
                },
                height: {
                  xs: "calc(30vh - 100px)",
                  sm: "calc(30vh - 100px)",
                  md: "calc(30vh - 100px)",
                  lg: "calc(87vh - 100px)",
                  xl: "calc(87vh - 100px)",
                },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "center",
                  xl: "center",
                },
                p: {
                  xs: "30px",
                  sm: "30px",
                  md: "30px",
                  lg: "20px 20px 0px 50px",
                  xl: "20px 20px 0px 50px",
                },
              }}
            >
              <Grid container>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "5px 0px 5px 0px" }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="First Name"
                    name="first_name"
                    onChange={handleInputChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    name="last_name"
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!image && (
                    <>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AccountCircleIcon
                          sx={{
                            fontSize: "50px",
                            background: "white",
                            color: "#cccccc",
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {image && (
                    <>
                      <img
                        src={image}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "50px" }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ p: "5px 0px 5px 0px" }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Student ID<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <FormControl sx={{ width: "255px" }}>
                    {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
                    <TextField
                      size="small"
                      id="outlined-basic"
                      // label="Student ID"
                      name="student_id"
                      onChange={handleInputChange}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RecentActorsIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ p: "5px 0px 5px 0px" }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Class<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <FormControl sx={{ width: "255px" }}>
                    {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.graduating_class}
                      label="graduating_class"
                      name="graduating_class"
                      onChange={handleInputChange}
                    >
                      {Object.keys(classData).map((cls) => (
                        <MenuItem key={cls} value={cls}>
                          {cls && cls === "Freshman" && (
                            <img
                              src={freshman}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls && cls === "Sophmore" && (
                            <img
                              src={sophmore}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls && cls === "Junior" && (
                            <img
                              src={junior}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls && cls === "Senior" && (
                            <img
                              src={senior}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    flexDirection: "column",
                    p: "20px 0px 0px 0px",
                  }}
                >
                  <Button
                    size="small"
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    sx={{ fontSize: "8px" }}
                  >
                    Upload Photo
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                  {image && (
                    <>
                      <Grid sx={{ p: "2px 0px 0px 0px" }}>
                        <Button
                          size="small"
                          component="label"
                          variant="outlined"
                          sx={{ fontSize: "8px" }}
                          fullWidth
                          onClick={handleRemove}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container sx={{ p: "5px 0px 0px 0px" }}>
                  <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ p: "0px 0px 5px 0px" }}
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        State<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <FormControl sx={{ width: "255px" }}>
                      {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.state}
                        label="State"
                        name="state"
                        onChange={handleInputChange}
                      >
                        {Object.keys(stateCity)
                          .sort((a, b) => a.localeCompare(b))
                          .map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ p: "0px 0px 5px 0px" }}
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        City<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <FormControl sx={{ width: "255px" }}>
                      {/* <InputLabel id="demo-simple-select-label">City</InputLabel> */}
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.city}
                        label="City"
                        name="city"
                        onChange={handleInputChange}
                      >
                        {stateCity[formData.state] &&
                          stateCity[formData.state]
                            .sort((a: string, b: string) => a.localeCompare(b))
                            .map((city: string) => (
                              <MenuItem key={city} value={city}>
                                {city}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid container>
                    <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          School Name<span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">
                      School Name
                    </InputLabel> */}
                        <Select
                          sx={{ width: "255px" }}
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.school}
                          name="school"
                          label="School Name"
                          onChange={handleInputChange}
                        >
                          {Object.keys(schoolAdd).map((school) => (
                            <MenuItem key={school} value={school}>
                              {school && school === "Jones College Prep" && (
                                <img
                                  src={jonesp}
                                  alt="school logo"
                                  style={{ width: "15px", marginRight: "10px" }}
                                />
                              )}
                              {school &&
                                school ===
                                  "Whitney M. Young Magnet Highschool" && (
                                  <img
                                    src={whitneyp}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school &&
                                school ===
                                  "Lane Tech College Prep High School" && (
                                  <img
                                    src={lanep}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school &&
                                school ===
                                  "Walter Payton College Prepratory Highschool" && (
                                  <img
                                    src={walterp}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school &&
                                school === "Northside College Prep" && (
                                  <img
                                    src={northsidep}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school &&
                                school === "Latin School Of Chicago" && (
                                  <img
                                    src={latin}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school &&
                                school === "Francis W Parker School" && (
                                  <img
                                    src={francisp}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school &&
                                school === "New Trier Township Highschool" && (
                                  <img
                                    src={newtrierp}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              {school}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {formData.school === "Other School" && (
                      <Grid
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        sx={{ p: "28px 2px 0px 0px" }}
                      >
                        <FormControl>
                          {/* <InputLabel id="demo-simple-select-label">
                      School Name
                    </InputLabel> */}
                          <TextField
                            size="small"
                            id="outlined-basic"
                            label="School Name"
                            variant="outlined"
                            name="other_school"
                            onChange={handleInputChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SchoolIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Class of<span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <FormControl sx={{ width: "255px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Year
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.class_year}
                          label="Class year"
                          name="class_year"
                          onChange={handleInputChange}
                        >
                          {years.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          School Address<span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <TextField
                        sx={{ width: "255px" }}
                        size="small"
                        id="outlined-multiline-flexible"
                        label=""
                        value={schoolAdd[formData.school]}
                        name="school_address"
                        multiline
                        rows={1}
                        fullWidth
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Grid
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {formData.school === "Other School"
                            ? "Other School Address"
                            : "Address line 2 (optional)"}
                        </Typography>
                      </Grid>
                      <TextField
                        sx={{ width: "255px" }}
                        size="small"
                        id="outlined-multiline-flexible"
                        label=""
                        name="school_address2"
                        multiline
                        rows={1}
                        fullWidth
                        onChange={handleInputChange}
                      />
                    </Grid>

                    {formData.school && formData.school !== "Other School" && (
                      <>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          sx={{
                            p: "10px 0px 0px 0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={schoolLogo}
                            alt="Uploaded"
                            style={{ maxWidth: "100%", maxHeight: "60px" }}
                          />
                        </Grid>
                      </>
                    )}
                    {formData.school && formData.school === "Other School" && (
                      <>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{
                            p: "25px 0px 0px 0px",
                            display: "flex",
                            // alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Grid sx={{ p: "0px 20px 0px 20px" }}>
                            <Button
                              variant="contained"
                              onClick={handleSave}
                              sx={{ backgroundColor: constants.tertiary }}
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid sx={{ p: "0px 5px 0px 5px" }}>
                            {/* <Button
                          variant="contained"
                          sx={{ alignItems: "flex-end" }}
                          onClick={handleNext}
                        >
                          Next
                        </Button> */}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {formData.school !== "Other School" && (
                    <>
                      <Grid container>
                        <Grid
                          container
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          alignItems="center"
                        >
                          {!isFormComplete && (
                            <Grid
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                p: "0px 5px",
                              }}
                            >
                              <InfoIcon
                                sx={{
                                  mr: 1,
                                  color: constants.tertiary,
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: constants.tertiary,
                                  mr: 1,
                                }}
                              >
                                Please Fill out Required (*) Fields
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        {isFormComplete && (
                          <Grid
                            container
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            alignItems="center"
                          ></Grid>
                        )}
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{
                            p: "25px 0px 0px 0px",
                            display: "flex",
                            // alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Grid sx={{ p: "0px 20px 0px 20px" }}>
                            <Button
                              variant="contained"
                              onClick={handleSave}
                              sx={{
                                backgroundColor: isFormComplete
                                  ? constants.tertiary
                                  : "default",
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                          {/* <Grid sx={{ p: "0px 5px 0px 5px" }}>
                        <Button
                          variant="contained"
                          sx={{ alignItems: "flex-end" }}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Grid> */}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </>
      )}
    </div>
  );
};

export default StudentFormComponent;
