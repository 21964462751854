import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number, baseColor:any }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            bgcolor: "primary.main",
            "& .MuiLinearProgress-bar": { bgcolor: props.baseColor },
          }}
        //   color={props.baseColor}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function ProgressBar() {
  const [progress, setProgress] = useState(20);

  const studentGrades = useSelector((state: any) => state?.studentGrades?.data);
  const studentEca = useSelector((state: any) => state?.studentEca?.data);
  const studentCs = useSelector((state: any) => state?.studentCs?.data);
  const studentAwards = useSelector((state: any) => state?.studentAwards?.data);

  useEffect(() => {
    const nonEmptyArraysCount = [
      studentGrades,
      studentEca,
      studentCs,
      studentAwards,
    ].filter((array) => array && array.length > 0).length;


    setProgress(() => {
      const newProgress = Math.min(
        20 + nonEmptyArraysCount * 20,
        100
      );
      return newProgress === 100 ? 100 : newProgress;
    });
  }, [studentGrades, studentEca, studentCs, studentAwards]);

  const baseColor =
    progress === 20
      ? "#fb4b4b"
      : progress === 40
      ? "#ffee00"
      : progress === 60
      ? "#f57e29"
      : progress === 80
      ? "#0053a6"
      : "#58b947";

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} baseColor={baseColor} />
    </Box>
  );
}
