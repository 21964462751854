import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import http from "../config/http-common";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import test from "../assets/images/formVector/testScores.png";
import constants from "../constants/constant";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingPage from "../utils/Loading";

const RadioBut = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));

const AddAwardSection = ({ handleAdd, class_state }: any) => (
  <Grid
    container
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p: "50px 0px 0px 0px",
    }}
  >
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "0px 0px 0px 0px",
      }}
    >
      <img
        src={test}
        alt="melo logo"
        style={{
          height: "70px",
        }}
      />
    </Grid>

    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        p: "20px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: constants.notAdded,
        }}
      >
        No Test Scores added
      </Typography>
    </Grid>
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        p: "20px 0px 60px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        size="small"
        variant="contained"
        sx={{
          borderRadius: "10px",
          borderColor: constants.tertiary,
          border: "1px solid #FE8D02",
          fontSize: "12px",
          color: "white",
          background:
            "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
          backgroundSize: "cover",
          "&:hover": {
            background:
              "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
          },
        }}
        onClick={() => handleAdd(class_state)}
      >
        ADD NEW
      </Button>
    </Grid>
  </Grid>
);

const TestList = () => {
  const location = useLocation();
  const { class_state } = location.state || {};
  const [awards, setAwards] = useState<any>();
  const [activeClassButton, setActiveClassButton] = useState(class_state);
  const state = localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAwards = async () => {
      setIsLoading(true);
      try {
        const response = await http.get("/api/testscores/all");

        const result: any = response.data;
        setAwards(result.response.data);
      } catch (error: any) {
        if (error.response) {
          console.error("Error fetching awards:", error.response.data.message);
        } else {
          console.error("Network error:", error.message);
        }
      }
      setIsLoading(false);
    };

    if (state) {
      fetchAwards();
    }
  }, [state]);

  const actData = awards && awards.filter((item: any) => item.test === "ACT");
  const satData = awards && awards.filter((item: any) => item.test === "SAT");

  const navigate = useNavigate();
  const handleAdd = (class_state: any) => {
    navigate("../testscores", { state: { class_state } });
  };

  const overall = actData && actData[0]?.total;
  const overallsat = satData && satData[0]?.total;
  const english = actData && actData[0]?.english;
  const maths = actData && actData[0]?.maths;
  const mathssat = satData && satData[0]?.maths;
  const science = actData && actData[0]?.science;
  const reading = actData && actData[0]?.reading;
  const readingsat = satData && satData[0]?.reading;
  const toDisplay = actData && actData[0]?.toDisplay;
  const toDisplaysat = satData && satData[0]?.toDisplay;

  const [formData, setFormData] = useState<any>({
    test: activeClassButton,
    english: 0,
    maths: 0,
    reading: 0,
    science: 0,
  });

  const newmaths = parseFloat(formData.maths ? formData.maths : 0);
  const newenglish = parseFloat(formData.english ? formData.english : 0);
  const newscience = parseFloat(formData.science ? formData.science : 0);
  const newreading = parseFloat(formData.reading ? formData.reading : 0);
  const averageTest =
    activeClassButton === "ACT"
      ? (newmaths + newenglish + newscience + newreading) / 4
      : newmaths + newreading;

  const handleDelete = async (id: any) => {
    try {
      const response = await http.delete(`/api/testscores/delete/${id}`);

      const result: any = response.data;
      console.log(result.response.data);
      // toast.success("Score deleted Successfully!");
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      if (error.response) {
        console.error("Error creating user:", error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        console.error("Network error:", error.message);
        toast.error(error.message);
      }
    }
  };

  const [edit, setEdit] = useState<any>("");

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let newValue: any = parseInt(value, 10);

    if (isNaN(newValue)) {
      newValue = "";
    } else if (newValue < 0) {
      newValue = 0;
    }

    setFormData((prevFormData: any) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      if (
        prevFormData.test === "ACT" &&
        ((updatedFormData.english !== "" && updatedFormData.english > 36) ||
          (updatedFormData.maths !== "" && updatedFormData.maths > 36) ||
          (updatedFormData.science !== "" && updatedFormData.science > 36) ||
          (updatedFormData.reading !== "" && updatedFormData.reading > 36))
      ) {
        toast.error("Scores cannot exceed 36");
        updatedFormData[name] = 0;
      } else if (
        prevFormData.test === "SAT" &&
        ((updatedFormData.maths !== "" && updatedFormData.maths > 800) ||
          (updatedFormData.reading !== "" && updatedFormData.reading > 800))
      ) {
        toast.error("Scores cannot exceed 800");
        updatedFormData[name] = 0;
      }

      return updatedFormData;
    });
  };

  const handleEdit = (fieldName: any) => {
    setEdit(fieldName);
    if (activeClassButton === "ACT") {
      setFormData(actData[0]);
    }
    if (activeClassButton === "SAT") {
      setFormData(satData[0]);
    }
  };
  const handleCancelActEdit = () => {
    setEdit("");
    setFormData(actData[0]);
  };
  const handleCancelSatEdit = () => {
    setEdit("");
    setFormData(satData[0]);
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      if (activeClassButton === "ACT") {
        const response = await http.post(
          `/api/testscores/update/${actData[0]._id}`,
          formData
        );
        console.log(response);
        // toast.success("Awards Data Saved Successfully");
        navigate("../testscores/summary");
      }
      if (activeClassButton === "SAT") {
        const response = await http.post(
          `/api/testscores/update/${satData[0]._id}`,
          formData
        );
        console.log(response);
        // toast.success("Awa Data Saved Successfully");
        navigate("../testscores/summary");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
    window.location.reload();
  };

  let count = 1;

  const [toggleCount, setToggleCount] = useState(count);

  const handleToggle = async (state: any, id: any) => {
    console.log("toggle count before if ", toggleCount);
    // if (toggleCount >= 2 && !state) {
    //   toast.error("You can only display up to 3 awards at a time.");
    //   return;
    // }
    const updatedIsDisplayed = !state;

    try {
      const response = await http.post(`/api/testscores/update/${id}`, {
        toDisplay: updatedIsDisplayed,
      });
      console.log(response);
      // toast.success("Score Data Saved Successfully");

      setToggleCount((prevCount) =>
        updatedIsDisplayed ? prevCount + 1 : prevCount - 1
      );
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
    window.location.reload();
  };

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
            }}
          >
            <Grid>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: constants.text,
                  fontWeight: "500",
                }}
              >
                Test Scores
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioGroup
                aria-label="test"
                name="class-buttons"
                value={activeClassButton}
                onChange={(e) => setActiveClassButton(e.target.value)}
              >
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", p: "5px 10px 20px 10px" }}
                >
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ p: "0px 10px 0px 0px" }}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        test: "SAT",
                      })
                    }
                  >
                    <Grid
                      item
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="ACT"
                        control={<RadioBut />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                m: "2px",
                                p: "2px",
                                borderRadius: {
                                  xs: "0",
                                  sm: "0",
                                  md: "0",
                                  lg: "25px",
                                  xl: "25px",
                                },
                              }}
                            >
                              ACT
                            </Typography>

                            <img
                              src={freshman}
                              alt="melo logo"
                              style={{
                                height: "30px",
                              }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ p: "0px 10px 0px 10px" }}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        test: "SAT",
                      })
                    }
                  >
                    <Grid
                      item
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="SAT"
                        control={<RadioBut />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                m: "2px",
                                p: "2px",
                                borderRadius: {
                                  xs: "0",
                                  sm: "0",
                                  md: "0",
                                  lg: "25px",
                                  xl: "25px",
                                },
                              }}
                            >
                              SAT
                            </Typography>

                            <img
                              src={sophmore}
                              alt="melo logo"
                              style={{
                                height: "30px",
                              }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {activeClassButton === "ACT" && actData && actData.length !== 0 && (
              <>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    ACT Scores
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 0px 0px 0px" }}
                >
                  <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        ACT Scores
                      </Typography>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      {" "}
                      {edit === "" ? (
                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Overall Scores: {overall}/36
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Overall Scores: {averageTest}/36
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      {" "}
                      <Typography sx={{ fontWeight: "bold" }}>
                        To Display:
                        <Switch
                          checked={toDisplay}
                          onClick={() =>
                            handleToggle(toDisplay, actData[0]._id)
                          }
                          inputProps={{ "aria-label": "toggle display" }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    spacing={2}
                  >
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Maths Score:
                        </Typography>
                      </Grid>
                      {edit === "maths" ? (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label=""
                            name="maths"
                            value={formData.maths}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CancelIcon
                                    onClick={() => handleCancelActEdit()}
                                    sx={{ cursor: "pointer" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label="Maths Score"
                            name="maths"
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            value={maths}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EditIcon
                                    onClick={() => handleEdit("maths")}
                                    sx={{ cursor: "pointer", fontSize: "15px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Reading/Writing Score:
                        </Typography>
                      </Grid>
                      {edit === "reading" ? (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label=""
                            name="reading"
                            value={formData.reading}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CancelIcon
                                    onClick={() => handleCancelActEdit()}
                                    sx={{ cursor: "pointer" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label="Reading/Writing Score"
                            name="reading"
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            value={reading}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EditIcon
                                    onClick={() => handleEdit("reading")}
                                    sx={{ cursor: "pointer", fontSize: "15px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    {activeClassButton === "ACT" && (
                      <>
                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ p: "5px 0px 5px 0px" }}
                          >
                            <Typography sx={{ fontSize: "12px" }}>
                              English Score:
                            </Typography>
                          </Grid>
                          {edit === "english" ? (
                            <>
                              <TextField
                                // size="small"
                                id="outlined-basic"
                                label="English Score"
                                name="english"
                                value={formData.english}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                sx={{ fontSize: "12px" }}
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CancelIcon
                                        onClick={() => handleCancelActEdit()}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <TextField
                                // size="small"
                                id="outlined-basic"
                                label="English Score"
                                name="english"
                                variant="outlined"
                                fullWidth
                                sx={{ fontSize: "12px" }}
                                type="number"
                                value={english}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <EditIcon
                                        onClick={() => handleEdit("english")}
                                        sx={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ p: "5px 0px 5px 0px" }}
                          >
                            <Typography sx={{ fontSize: "12px" }}>
                              Science Score:
                            </Typography>
                          </Grid>
                          {edit === "science" ? (
                            <>
                              <TextField
                                // size="small"
                                id="outlined-basic"
                                label=""
                                name="science"
                                value={formData.science}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                sx={{ fontSize: "12px" }}
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CancelIcon
                                        onClick={() => handleCancelActEdit()}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <TextField
                                // size="small"
                                id="outlined-basic"
                                label="Science Score"
                                name="science"
                                variant="outlined"
                                fullWidth
                                sx={{ fontSize: "12px" }}
                                type="number"
                                value={science}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <EditIcon
                                        onClick={() => handleEdit("science")}
                                        sx={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container sx={{ p: "5px 0px   0px 0px" }}>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
                    <Grid
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        // alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    variant="contained"
                    sx={{ background: constants.tertiary, color: "white" }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Grid sx={{ p: "0px 0px 0px 10px" }}>
                    <Button
                      variant="contained"
                      sx={{ background: "#ff3333", color: "white" }}
                      onClick={() => handleDelete(actData[0]?._id)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            {activeClassButton === "ACT" && actData && actData.length === 0 && (
              <>
                <AddAwardSection handleAdd={handleAdd} class_state="ACT" />
              </>
            )}
            {activeClassButton === "SAT" && satData && satData.length !== 0 && (
              <>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    SAT Scores
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 0px 0px 0px" }}
                >
                  <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        SAT Scores
                      </Typography>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      {" "}
                      {edit === "" ? (
                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Overall Scores:{overallsat}/1600
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Overall Scores:{averageTest}/1600
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      {" "}
                      <Typography sx={{ fontWeight: "bold" }}>
                        To Display:
                        <Switch
                          checked={toDisplaysat}
                          onClick={() =>
                            handleToggle(toDisplaysat, satData[0]._id)
                          }
                          inputProps={{ "aria-label": "toggle display" }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    spacing={2}
                  >
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Maths Score:
                        </Typography>
                      </Grid>
                      {edit === "maths" ? (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label=""
                            name="maths"
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CancelIcon
                                    onClick={() => handleCancelSatEdit()}
                                    sx={{ cursor: "pointer" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label="Maths Score"
                            name="maths"
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            value={mathssat}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EditIcon
                                    onClick={() => handleEdit("maths")}
                                    sx={{ cursor: "pointer", fontSize: "15px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ p: "5px 0px 5px 0px" }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Reading/Writing Score:
                        </Typography>
                      </Grid>
                      {edit === "reading" ? (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label=""
                            name="reading"
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CancelIcon
                                    onClick={() => handleCancelSatEdit()}
                                    sx={{ cursor: "pointer" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            // size="small"
                            id="outlined-basic"
                            label="Reading/Writing Score"
                            name="reading"
                            variant="outlined"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            type="number"
                            value={readingsat}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EditIcon
                                    onClick={() => handleEdit("reading")}
                                    sx={{ cursor: "pointer", fontSize: "15px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ p: "5px 0px   0px 0px" }}>
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
                    <Grid
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        // alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    variant="contained"
                    sx={{ background: constants.tertiary, color: "white" }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Grid sx={{ p: "0px 0px 0px 10px" }}>
                    <Button
                      variant="contained"
                      sx={{ background: "#ff3333", color: "white" }}
                      onClick={() => handleDelete(satData[0]?._id)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            {activeClassButton === "SAT" && satData && satData.length === 0 && (
              <>
                <AddAwardSection handleAdd={handleAdd} class_state="SAT" />
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default TestList;
