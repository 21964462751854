import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import http from "../config/http-common";
import { useLocation, useNavigate } from "react-router-dom";
import hoursList from "../assets/jsonData/hours.json";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import constants from "../constants/constant";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const CustomRadioButton = ({ value, selectedValue }: any) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ display: "none" }} />}
    label={
      <Typography
        sx={{
          fontSize: "12px",
          padding: "10px 20px",
          borderRadius: "25px",
          border:
            selectedValue === value ? "2px solid #FFA500" : "1px solid #ccc",
          backgroundColor: selectedValue === value ? "#FFF4E0" : "#F6F6F6",
          color: selectedValue === value ? "#FFA500" : "#000",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
    }
  />
);


const CsFormComponent = () => {
  const hours: any = hoursList;
  const navigate = useNavigate();
  const location = useLocation();
  const { class_state } = location.state || {};
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear + 26; year++) {
    years.push(year);
  }
  const [activeClassButton, setActiveClassButton] = useState(class_state);
        const [selectedValue, setSelectedValue] = useState(class_state);

        const handleChange = (event: any) => {
          setSelectedValue(event.target.value);
          setActiveClassButton(event.target.value);
        };

  const [formData, setFormData] = useState<any>({
    class: class_state,
    activity: "",
    desc: "",
    start_date: "",
    end_date: "",
    hours: 0,
  });

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    const requiredKeys = [
      "class",
      "activity",
      "desc",
      "start_date",
      "end_date",
    ];

    const checkFormCompleteness = () => {
      return requiredKeys.every(
        (key) => formData[key] !== "" && formData[key] !== null
      );
    };

    setIsFormComplete(checkFormCompleteness());
  }, [formData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

   setFormData({ ...formData, [name]: value, class: activeClassButton });
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      if (isFormComplete) {
        const response = await http.post("/api/community/create", formData);
        console.log(response);
        // toast.success("CS Data Saved Successfully");
        navigate("../communityservices/summary");
      } else {
        toast.error("fill out required fields");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error(error.message);
      }
    }
  };

  const handleDateChange = (date: any) => {
    setFormData({ ...formData, start_date: date });
  };
  const handleEndDateChange = (date: any) => {
    setFormData({ ...formData, end_date: date });
  };

  const handleBack = () => {
    navigate("./summary");
  };

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          width: {
            xs: "calc(100vw - 100px)",
            sm: "calc(100vw - 100px)",
            md: "calc(55vw - 100px)",
            lg: "calc(55vw - 100px)",
            xl: "calc(55vw - 100px)",
          },
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            variant="text"
            sx={{ color: constants.tertiary }}
            onClick={handleBack}
          >
            <KeyboardBackspaceIcon /> Back
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <Grid
                container
                // xs={12}
                // sm={12}
                // md={6} // For medium and up, two buttons per row
                // lg={6}
                // xl={6}
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "10px",
                }}
              >
                {["Freshman", "Sophmore", "Junior", "Senior"].map((label) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    key={label}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomRadioButton
                      value={label}
                      selectedValue={selectedValue}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Community Service Name
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <TextField
              size="small"
              id="outlined-basic"
              label="Activity"
              name="activity"
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              sx={{ fontSize: "12px" }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Duration in Hours(Hours Weekly)
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.hours}
              label="Hours"
              name="hours"
              onChange={handleInputChange}
              fullWidth
              // sx={{ width: "260px" }}
            >
              {Object.keys(hours).map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "5px 0px 5px 0px" }}
          >
            <Typography sx={{ fontSize: "12px" }}>
              Community Service Description
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Activity Description"
              name="desc"
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              sx={{ fontSize: "12px", p: "0px 0px 0px 0px" }}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                From Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ fontSize: "12px", height: "2px", tabSize: "small" }}
                name="start_date"
                value={formData.start_date}
                onChange={(date) => handleDateChange(date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                To Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ fontSize: "12px", height: "2px", tabSize: "small" }}
                name="end_date"
                value={formData.end_date}
                onChange={(date) => handleEndDateChange(date)}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container sx={{ p: "50px 0px 0px 0px" }}>
          <Grid
            container
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            alignItems="center"
          >
            {!isFormComplete && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  p: "0px 5px",
                }}
              >
                <InfoIcon
                  sx={{
                    mr: 1,
                    color: constants.tertiary,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: constants.tertiary,
                    mr: 1,
                  }}
                >
                  Please Fill out Required (*) Fields
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
          <Grid
            container
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                p: "0px 5px",
              }}
            >
              <Button
                variant="contained"
                onClick={handleSave}
                style={{
                  backgroundColor: isFormComplete
                    ? constants.tertiary
                    : "default",
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CsFormComponent;
