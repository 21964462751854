import React, { useEffect, useState } from "react";
import http from "../config/http-common";
import {
  Grid,
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ApGradesTable from "../layouts/ApGradesTable";
import constants from "../constants/constant";

import LoadingPage from "../utils/Loading";
import grades from "../assets/images/formVector/Vectorgrades.png";
import { toast } from "react-toastify";
import AlertGradesAddDialogSlide from "../layouts/AlertGradesAddDialogSlide";



const CustomRadioButton = ({ value, selectedValue }: any) => (
  <FormControlLabel
    value={value}
    control={<Radio sx={{ display: "none" }} />}
    label={
      <Typography
        sx={{
          fontSize: "12px",
          padding: "10px 20px",
          borderRadius: "25px",
          border:
            selectedValue === value ? "2px solid #FFA500" : "1px solid #ccc",
          backgroundColor: selectedValue === value ? "#FFF4E0" : "#F6F6F6",
          color: selectedValue === value ? "#FFA500" : "#000",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
    }
  />
);


const GradesApList = () => {
  const [awards, setAwards] = useState<any>();
  const [loading, setLoading] = useState(false);
  const state = localStorage.getItem("accessToken");
  const [activeClassButton, setActiveClassButton] = useState("Freshman");
    const [selectedValue, setSelectedValue] = useState("Freshman");

    const handleChange = (event: any) => {
      setSelectedValue(event.target.value);
      setActiveClassButton(event.target.value);
    };


  useEffect(() => {
    const fetchAwards = async () => {
      setLoading(true);
      try {
        const response = await http.get("/api/grades/all");

        const result: any = response.data;
        console.log(result.response.data);
        setAwards(result.response.data);
      } catch (error: any) {
        if (error.response) {
          console.error("Error fetching awards:", error.response.data.message);
        } else {
          console.error("Network error:", error.message);
        }
      }
      setLoading(false);
    };

    if (state) {
      fetchAwards();
    }
  }, [state]);

  const examDetails = awards ? awards[0]?.examDetails : "";
  const apFreshData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Freshman"
    );
  const apSophmoreData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Sophmore"
    );
  const apJnrData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Junior"
    );
  const apSnrData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "AP" && item.class === "Senior"
    );

  function calculateAverage(examDetails: any) {
    let sum = 0;

    for (let i = 0; i < examDetails.length; i++) {
      sum += examDetails[i].subject[0].average_GPA;
    }
    const numberOfRows = examDetails.length;
    const average = sum / numberOfRows;
    return average.toFixed(2);
  }
  function calculateWeightedAverage(examDetails: any) {
    let sum = 0;

    for (let i = 0; i < examDetails.length; i++) {
      sum += examDetails[i].subject[0].weighted_GPA;
    }
    const numberOfRows = examDetails.length;
    const average = sum / numberOfRows;
    return average.toFixed(2);
  }

   const [addClass, setAddClass] = useState(null);
   const [addOpen, setAddOpen] = useState(false);

   const handleAdd = (class_state: any) => {
     setAddClass(class_state);
     setAddOpen(true);
   };

   const handleAddClose = async (confirmed: any, data: any) => {
     setAddOpen(false);
     if (confirmed && addClass) {
       try {
         const response = await http.post("/api/gradeform", data);
         console.log(response);
         // toast.success("Awards Data Saved Successfully");
         window.location.reload();
       } catch (error: any) {
         if (error.response && error.response.data) {
           console.error("Error LogResponse in user:", error.response.data);
           toast.error(error.response.data.errorMessage);
         } else {
           console.error("Error saving data:", error.message);
           toast.error(error.message);
         }
       }
     } else {
       setAddClass(null);
     }
   };

  const AddGradesSection = ({ handleAdd, class_state }: any) => (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "50px 0px 0px 0px",
      }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: "0px 0px 0px 0px",
        }}
      >
        <img
          src={grades}
          alt="melo logo"
          style={{
            height: "70px",
          }}
        />
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          p: "20px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: constants.notAdded,
          }}
        >
          No Grades added
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          p: "20px 0px 60px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{
            borderRadius: "10px",
            borderColor: constants.tertiary,
            border: "1px solid #FE8D02",
            fontSize: "12px",
            color: "white",
            background:
              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            backgroundSize: "cover",
            "&:hover": {
              background:
                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            },
          }}
          onClick={() => handleAdd(class_state)}
        >
          ADD NEW
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      {loading ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <LoadingPage />
        </Grid>
      ) : (
        <>
          <Grid sx={{ p: "20px 0px  0px 0px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                color: constants.text,
                fontWeight: "500",
              }}
            >
              Grades
            </Typography>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <Grid
                container
                // xs={12}
                // sm={12}
                // md={6} // For medium and up, two buttons per row
                // lg={6}
                // xl={6}
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "10px",
                }}
              >
                {["Freshman", "Sophmore", "Junior", "Senior"].map((label) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    key={label}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomRadioButton
                      value={label}
                      selectedValue={selectedValue}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "5px 0px 5px 0px",
            }}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {activeClassButton === "Freshman" &&
                apFreshData &&
                apFreshData.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "500",
                        color: constants.text,
                      }}
                    >
                      Freshman List
                    </Typography>
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                apSophmoreData &&
                apSophmoreData.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "500",
                        color: constants.text,
                      }}
                    >
                      Sophmore List
                    </Typography>
                  </>
                )}
              {activeClassButton === "Junior" &&
                apJnrData &&
                apJnrData.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "500",
                        color: constants.text,
                      }}
                    >
                      Junior List
                    </Typography>
                  </>
                )}
              {activeClassButton === "Senior" &&
                apSnrData &&
                apSnrData.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "500",
                        color: constants.text,
                      }}
                    >
                      Senior List
                    </Typography>
                  </>
                )}
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              {activeClassButton === "Freshman" &&
                apFreshData &&
                apFreshData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "10px",
                            borderColor: constants.tertiary,
                            border: "1px solid #FE8D02",
                            fontSize: "12px",
                            color: "white",
                            background:
                              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            backgroundSize: "cover",

                            "&:hover": {
                              background:
                                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            },
                          }}
                          onClick={() => handleAdd("Freshman")}
                        >
                          + Add
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                apSophmoreData &&
                apSophmoreData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "10px",
                            borderColor: constants.tertiary,
                            border: "1px solid #FE8D02",
                            fontSize: "12px",
                            color: "white",
                            background:
                              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            backgroundSize: "cover",

                            "&:hover": {
                              background:
                                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            },
                          }}
                          onClick={() => handleAdd("Sophmore")}
                        >
                          + add
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              {activeClassButton === "Junior" &&
                apJnrData &&
                apJnrData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "10px",
                            borderColor: constants.tertiary,
                            border: "1px solid #FE8D02",
                            fontSize: "12px",
                            color: "white",
                            background:
                              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            backgroundSize: "cover",

                            "&:hover": {
                              background:
                                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            },
                          }}
                          onClick={() => handleAdd("Junior")}
                        >
                          + add
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              {activeClassButton === "Senior" &&
                apSnrData &&
                apSnrData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "10px",
                            borderColor: constants.tertiary,
                            border: "1px solid #FE8D02",
                            fontSize: "12px",
                            color: "white",
                            background:
                              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            backgroundSize: "cover",

                            "&:hover": {
                              background:
                                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                            },
                          }}
                          onClick={() => handleAdd("Senior")}
                        >
                          + add
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "5px 0px 5px 0px",
            }}
          >
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              {activeClassButton === "Freshman" &&
                apFreshData &&
                apFreshData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        // sx={{
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   p: "5px 0px 0px 0px",
                        // }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                              xl: "12px",
                            },
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Unweighted:{calculateAverage(apFreshData)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                              xl: "12px",
                            },
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Weighted:
                          {calculateWeightedAverage(apFreshData)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                apSophmoreData &&
                apSophmoreData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Unweighted:{calculateAverage(apSophmoreData)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Weighted:{calculateWeightedAverage(apSophmoreData)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              {activeClassButton === "Junior" &&
                apJnrData &&
                apJnrData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Unweighted:{calculateAverage(apJnrData)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Weighted:
                          {calculateWeightedAverage(apJnrData)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              {activeClassButton === "Senior" &&
                apSnrData &&
                apSnrData.length !== 0 && (
                  <>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Unweighted:{calculateAverage(apSnrData)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: constants.tableTxt,
                          }}
                        >
                          Weighted:
                          {calculateWeightedAverage(apSnrData)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              width: {
                xs: "calc(100vw - 100px)",
                sm: "calc(100vw - 100px)",
                md: "calc(55vw - 100px)",
                lg: "calc(55vw - 100px)",
                xl: "calc(55vw - 100px)",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Box sx={{ maxHeight: "auto", overflow: "auto" }}>
              {activeClassButton === "Freshman" &&
                apFreshData &&
                apFreshData.length !== 0 && (
                  <>
                    <ApGradesTable data={apFreshData} />
                  </>
                )}
              {activeClassButton === "Freshman" &&
                (!apFreshData || apFreshData.length === 0) && (
                  <>
                    <AddGradesSection
                      handleAdd={handleAdd}
                      class_state="Freshman"
                    />
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                apSophmoreData &&
                apSophmoreData.length !== 0 && (
                  <>
                    <ApGradesTable data={apSophmoreData} />
                  </>
                )}
              {activeClassButton === "Sophmore" &&
                (!apSophmoreData || apSophmoreData.length === 0) && (
                  <>
                    <AddGradesSection
                      handleAdd={handleAdd}
                      class_state="Sophmore"
                    />
                  </>
                )}
              {activeClassButton === "Junior" &&
                apJnrData &&
                apJnrData.length !== 0 && (
                  <>
                    <ApGradesTable data={apJnrData} />
                  </>
                )}
              {activeClassButton === "Junior" &&
                (!apJnrData || apJnrData.length === 0) && (
                  <>
                    <AddGradesSection
                      handleAdd={handleAdd}
                      class_state="Junior"
                    />
                  </>
                )}
              {activeClassButton === "Senior" &&
                apSnrData &&
                apSnrData.length !== 0 && (
                  <>
                    <ApGradesTable data={apSnrData} />
                  </>
                )}
              {activeClassButton === "Senior" &&
                (!apSnrData || apSnrData.length === 0) && (
                  <>
                    <AddGradesSection
                      handleAdd={handleAdd}
                      class_state="Senior"
                    />
                  </>
                )}
            </Box>
          </Grid>
          {addClass && (
            <AlertGradesAddDialogSlide
              open={addOpen}
              onClose={handleAddClose}
              data={addClass}
            />
          )}
        </>
      )}
    </>
  );
};

export default GradesApList;
