import React from "react";
import PostCards from "../layouts/PostCards";
import { useSelector } from "react-redux";
import { Grid,} from "@mui/material";
import constants from "../constants/constant";

const PostsComponent = () => {
  const posts = useSelector((state: any) => state.posts);

  const data: any = posts?.data || [];

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Grid
        sx={{
          background: constants.seconday,
          borderRadius: "10px",
          margin: "10px",
          width: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          padding: "10px",
        }}
      >
        {Array.isArray(data) &&
          data
            .slice()
            .reverse()
            .map((admin: any, index: number) => (
              <Grid
                container
                key={index}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                {admin.posts
                  .slice()
                  .reverse()
                  .map((post: any) => (
                    <PostCards
                      key={post._id}
                      post={post}
                      userName={admin.name}
                    />
                  ))}
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
};

export default PostsComponent;
