import React, { useEffect, useState } from "react";
import http from "../config/http-common";
import { Grid, Box, Typography, Button } from "@mui/material";
import IbGradesTable from "../layouts/IbGradesTable";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import junior from "../assets/images/formVector/jnr.png";
import senior from "../assets/images/formVector/snr.png";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingPage from "../utils/Loading";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const GradesIbList = () => {
  const [awards, setAwards] = useState<any>();
  const [loading, setLoading] = useState(false);
  const state = localStorage.getItem("accessToken");
  const [activeClassButton, setActiveClassButton] = useState("Freshman");

  useEffect(() => {
    const fetchAwards = async () => {
      setLoading(true);
      try {
        const response = await http.get("/api/grades/all");

        const result: any = response.data;
        console.log(result.response.data);
        setAwards(result.response.data);
      } catch (error: any) {
        if (error.response) {
          console.error("Error fetching awards:", error.response.data.message);
        } else {
          console.error("Network error:", error.message);
        }
      }
      setLoading(false);
    };

    if (state) {
      fetchAwards();
    }
  }, [state]);

  const examDetails = awards ? awards[0].examDetails : "";
  const apFreshData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "IB" && item.class === "Freshman"
    );
  const apSophmoreData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "IB" && item.class === "Sophmore"
    );
  const apJnrData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "IB" && item.class === "Junior"
    );
  const apSnrData =
    examDetails &&
    examDetails.filter(
      (item: any) => item.examType === "IB" && item.class === "Senior"
    );

  return (
    <div>
      {loading ? (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <LoadingPage />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setActiveClassButton("Freshman");
                }}
              >
                <img
                  src={freshman}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background:
                      activeClassButton === "Freshman" ? "#2D438E" : "",
                    color: activeClassButton === "Freshman" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Freshman
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",

                  flexDirection: "column",
                }}
                onClick={() => {
                  setActiveClassButton("Sophmore");
                }}
              >
                <img
                  src={sophmore}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background:
                      activeClassButton === "Sophmore" ? "#2D438E" : "",
                    color: activeClassButton === "Sophmore" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Sophmore
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",

                  flexDirection: "column",
                }}
                onClick={() => {
                  setActiveClassButton("Junior");
                }}
              >
                <img
                  src={junior}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background: activeClassButton === "Junior" ? "#2D438E" : "",
                    color: activeClassButton === "Junior" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Junior
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setActiveClassButton("Senior");
                }}
              >
                <img
                  src={senior}
                  alt="melo logo"
                  style={{
                    height: "30px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    background: activeClassButton === "Senior" ? "#2D438E" : "",
                    color: activeClassButton === "Senior" ? "white" : "",
                    m: "2px 2px 2px 2px",
                    p: "2px 2px 2px 2px",
                    borderRadius: {
                      xs: "0",
                      sm: "0",
                      md: "0",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  Senior
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ maxHeight: 300, overflow: "auto" }}>
            {activeClassButton === "Freshman" &&
              apFreshData &&
              apFreshData.length !== 0 && (
                <>
                  <Typography>Freshman List</Typography>
                  <IbGradesTable data={apFreshData} />
                </>
              )}
            {activeClassButton === "Sophmore" &&
              apSophmoreData &&
              apSophmoreData.length !== 0 && (
                <>
                  <Typography>Sophmore List</Typography>
                  <IbGradesTable data={apSophmoreData} />
                </>
              )}
            {activeClassButton === "Junior" &&
              apJnrData &&
              apJnrData.length !== 0 && (
                <>
                  <Typography>Junior List</Typography>
                  <IbGradesTable data={apJnrData} />
                </>
              )}
            {activeClassButton === "Senior" &&
              apSnrData &&
              apSnrData.length !== 0 && (
                <>
                  <Typography>Senior List</Typography>
                  <IbGradesTable data={apSnrData} />
                </>
              )}
          </Box>

          <Grid xs={8} sm={8} md={8} lg={8} xl={8}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "0px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Upload Transcript*
              </Typography>
            </Grid>
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Upload Transcript*
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
          {/* <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          p: "2px 0px 0px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Total GPA:</Typography>
        <TextField
          size="small"
          id="outlined-basic"
          label="GPA"
          name="GPA"
          variant="outlined"
          sx={{ width: "100px" }}
        />
      </Grid> */}
        </>
      )}
    </div>
  );
};

export default GradesIbList;
