import { configureStore } from "@reduxjs/toolkit";
import { sessionSlice } from "./slices/loginSessionSlice";
import { studentSlice } from "./slices/studentDataSlice";
import { studentImageSlice } from "./slices/studentImageSlice";
import { studentExamSlice } from "./slices/studentExamSlice";
import { studentEcaSlice } from "./slices/studentEcaSlice";
import { studentCsSlice } from "./slices/studentCsSlice";
import { studentAwardsSlice } from "./slices/studentAwardsSlice";
import { studentTestScoresSlice } from "./slices/studentTestScoresSlice";
import { postDataSlice } from "./slices/postDataSlice";
import { allStudentsDataSlice } from "./slices/allStudentSlice";
import { searchStudentDataSlice } from "./slices/searchStudentSlice";
import {studentFilesSlice} from "./slices/studentFilesSlice";

export const store = configureStore({
  reducer: {
    loginDetails: sessionSlice.reducer,
    studentDetails: studentSlice.reducer,
    studentImage: studentImageSlice.reducer,
    studentGrades: studentExamSlice.reducer,
    studentEca: studentEcaSlice.reducer,
    studentCs: studentCsSlice.reducer,
    studentAwards: studentAwardsSlice.reducer,
    studentTestScores: studentTestScoresSlice.reducer,
    posts: postDataSlice.reducer,
    allStudents: allStudentsDataSlice.reducer,
    searchStudentData: searchStudentDataSlice.reducer,
    studentFiles: studentFilesSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
