import { Button, Grid, Paper, Typography } from "@mui/material";
import axiosInstance from "../config/http-common";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import emailSent from "../assets/jsonData/emailSent.json";
import { toast } from "react-toastify";
import constants from "../constants/constant";
import { useEffect } from "react";

const EmailVerificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const expTime = parts[parts.length - 1];
  const token = parts[parts.length - 2];
  const id = parts[parts.length - 3];

  const verifyEmail = async () => {
    try {
      await axiosInstance.post(
        `/api/userverification/${id}/${token}/${expTime}`
      );
      toast.success("Email verified successfully");
      navigate(`../setpassword/${id}`);
    } catch (error: any) {
      console.log(error.response.data.errorMessage);
      if (error.response && error.response.data) {
        console.error(
          "Error creating User:",
          error.response.data.errorMessage
        );
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error creating User:", error.message);
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axiosInstance.post(
          `/api/userverification/${id}/${token}/${expTime}`
        );
        toast.success("Email verified successfully");
        navigate(`../setpassword/${id}`);
      } catch (error: any) {
        console.log(error.response.data.errorMessage);
        if (error.response && error.response.data) {
          console.error(
            "Error creating User:",
            error.response.data.errorMessage
          );
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error creating User:", error.message);
          toast.error(error.message);
        }
      }
    };
    verifyEmail();
  }, [expTime, token, id, navigate]);

  return (
    <>
      <Grid
        sx={{
          p: "50px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            background: constants.seconday,
            borderRadius: "10px",
            border: `2px solid ${constants.tertiary}`,
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(100vw - 100px)",
              lg: "calc(30vw - 100px)",
              xl: "calc(30vw - 100px)",
            },
          }}
        >
          <Grid
            sx={{
              p: "10px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "15px",
                color: "black",
              }}
            >
              Verifying Email Address...
            </Typography>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie animationData={emailSent} />
            </Grid>
          </Grid>

          <Grid
            sx={{
              p: "20px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                color: "black",
              }}
            >
              Please wait while we verify your email address. If you are not
              redirected automatically, please click the 'Verify' button below.
            </Typography>
          </Grid>
          <Grid
            sx={{
              p: "20px 0px 60px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: 600,
                fontSize: "10px",
                p: "0px 10px 0px 10px",
                cursor: "pointer",
                background: "white",
                color: constants.tertiary,
              }}
              onClick={verifyEmail}
            >
              {" "}
              Verify
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default EmailVerificationPage;
