import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import junior from "../assets/images/formVector/jnr.png";
import senior from "../assets/images/formVector/snr.png";
import http from "../config/http-common";
import { logRegStyle } from "../constants/loginRegisterStyles";
import gradesData from "../assets/jsonData/grades.json";
import subjectsData from "../assets/jsonData/rawSubject.json";
import gpaData from "../assets/jsonData/gpaList.json";
import constants from "../constants/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { getStudentExam } from "../redux/slices/studentExamSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const RadioBut = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));
const RadioBut1 = styled(Radio)(({ theme: any }) => ({
  color: "",
  "&.Mui-checked": {
    color: constants.tertiary,
  },
}));

const GradeFormComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { class_state } = location.state || {};
  const [activeExamButton, setActiveExamButton] = useState("quarter");
  const studentExam = useSelector((state: any) => state.studentGrades);

  const handleExamButtonChange = (e: any) => {
    const { name, value } = e.target;
    setActiveExamButton(value);
    setFormData({ ...formData, [name]: value });
    if (value === "quarter") {
      setFormData({
        ...formData,
        examDivision: "quarter",
        s1: "",
        s2: "",
      });
    }
    if (value === "semester") {
      setFormData({
        ...formData,
        examDivision: "semester",
        q1: "",
        q2: "",
        q3: "",
        q4: "",
      });
    }
  };

  const gradesField: any = gradesData;
  const subjectField: any = subjectsData;
  const gpaList: any = gpaData;

  const [activeClassButton, setActiveClassButton] = useState(class_state);
  const [activeTypeButton, setActiveTypeButton] = useState<any>();

  const [formData, setFormData] = useState<any>({
    class: class_state,
    subject: "",
    otherSubject: "",
    subjectType: "",
    examType: "AP",
    examDivision: activeExamButton,
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    s1: "",
    s2: "",
    finalScore: "",
  });

  const [isFormComplete, setIsFormComplete] = useState(false);
  useEffect(() => {
    try {
      dispatch(getStudentExam());
    } catch (error: any) {
      // 50x code will come here
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const requiredKeys = ["class", "subject", "examType", "examDivision"];

    const checkFormCompleteness = () => {
      const basicCheck = requiredKeys.every(
        (key) => formData[key] !== "" && formData[key] !== null
      );

      if (!basicCheck) return false;

      if (formData.examDivision === "quarter") {
        return (
          formData.q1 !== "" ||
          formData.q2 !== "" ||
          formData.q3 !== "" ||
          formData.q4 !== ""
        );
      }

      if (formData.examDivision === "semester") {
        return formData.s1 !== "" || formData.s2 !== "";
      }

      return true;
    };

    setIsFormComplete(checkFormCompleteness());
  }, [formData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      if (isFormComplete) {
        const response = await http.post("/api/gradeform", formData);
        console.log(response);
        // toast.success("Student Grades Data Saved Successfully");
        setFormData({});
        navigate("../grades/summary");
      } else {
        toast.error("fill out required fields");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  };

  const getGPA = (grade: any) => {
    return gpaList[grade]?.gpa || 0;
  };

  const gpaQ1 = getGPA(formData.q1);
  const gpaQ2 = getGPA(formData.q2);
  const gpaQ3 = getGPA(formData.q3);
  const gpaQ4 = getGPA(formData.q4);
  const gpaS1 = getGPA(formData.s1);
  const gpaS2 = getGPA(formData.s2);

  const quarterGPAs: number[] = [gpaQ1, gpaQ2, gpaQ3, gpaQ4];
  const semesterGPAs: number[] = [gpaS1, gpaS2];

  const countNonZero = (gpas: number[]): number =>
    gpas.filter((gpa: number) => gpa > 0).length;

  const nonZeroQuarterCount = countNonZero(quarterGPAs);
  const nonZeroSemesterCount = countNonZero(semesterGPAs);

  const averageGpa =
    activeExamButton === "quarter"
      ? quarterGPAs.reduce((sum: number, gpa: number) => sum + gpa, 0) /
        (nonZeroQuarterCount || 1)
      : semesterGPAs.reduce((sum: number, gpa: number) => sum + gpa, 0) /
        (nonZeroSemesterCount || 1);

  const weightedGpa =
    formData.subjectType === "Honors"
      ? averageGpa + 0.5
      : formData.subjectType === "AP"
      ? averageGpa + 1
      : averageGpa;



  const studentNames = Array.isArray(studentExam?.data)
    ? studentExam.data.flatMap((entry: any) =>
        entry?.subject.map((subject: any) => ({
          subjectName: subject?.subjectName,
          class: entry?.class,
        }))
      )
    : [];


  const handleBack = () => {
    navigate("./summary")
  }

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ p: "10px 0px 10px 0px" }}
      >
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(70vw - 100px)",
              lg: "calc(70vw - 100px)",
              xl: "calc(70vw - 100px)",
            },
          }}
        >
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                variant="text"
                sx={{ color: constants.tertiary }}
                onClick={handleBack}
              >
                <KeyboardBackspaceIcon /> Back
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioGroup
                aria-label="class"
                name="class-buttons"
                value={activeClassButton}
                onChange={(e) => setActiveClassButton(e.target.value)}
              >
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    p: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "5px 10px 20px 10px",
                      lg: "5px 10px 20px 10px",
                      xl: "5px 10px 20px 10px",
                    },
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: {
                        xs: "0px 5px 5px 5px",
                        sm: "0px 5px 5px 5px",
                        md: "0px 10px 0px 0px",
                        lg: "0px 10px 0px 0px",
                        xl: "0px 10px 0px 0px",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: {
                          xs: "0px 0px 0px 10px",
                          sm: "0px 0px 0px 10px",
                          md: "0px 10px 0px 0px",
                          lg: "0px 10px 0px 0px",
                          xl: "0px 10px 0px 0px",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="Freshman"
                        control={<RadioBut />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                m: "2px",
                                p: "2px",
                                borderRadius: {
                                  xs: "0",
                                  sm: "0",
                                  md: "0",
                                  lg: "25px",
                                  xl: "25px",
                                },
                              }}
                            >
                              Freshman
                            </Typography>

                            <img
                              src={freshman}
                              alt="melo logo"
                              style={{
                                height: "30px",
                              }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: {
                        xs: "0px 0px 0px 0px",
                        sm: "0px 0px 0px 0px",
                        md: "0px 10px 0px 10px",
                        lg: "0px 10px 0px 10px",
                        xl: "0px 10px 0px 10px",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="Sophmore"
                        control={<RadioBut />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                m: "2px",
                                p: "2px",
                                borderRadius: {
                                  xs: "0",
                                  sm: "0",
                                  md: "0",
                                  lg: "25px",
                                  xl: "25px",
                                },
                              }}
                            >
                              Sophmore
                            </Typography>

                            <img
                              src={sophmore}
                              alt="melo logo"
                              style={{
                                height: "30px",
                              }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: {
                        xs: "0px 5px 5px 5px",
                        sm: "0px 5px 5px 5px",
                        md: "0px 10px 0px 10px",
                        lg: "0px 10px 0px 10px",
                        xl: "0px 10px 0px 10px",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="Junior"
                        control={<RadioBut />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                m: "2px",
                                p: "2px",
                                borderRadius: {
                                  xs: "0",
                                  sm: "0",
                                  md: "0",
                                  lg: "25px",
                                  xl: "25px",
                                },
                              }}
                            >
                              Junior
                            </Typography>

                            <img
                              src={junior}
                              alt="melo logo"
                              style={{
                                height: "30px",
                              }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      p: {
                        xs: "0px 0px 0px 0px",
                        sm: "0px 0px 0px 0px",
                        md: "0px 0px 0px 10px",
                        lg: "0px 0px 0px 10px",
                        xl: "0px 0px 0px 10px",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="Senior"
                        control={<RadioBut />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                m: "2px",
                                p: "2px",
                                borderRadius: {
                                  xs: "0",
                                  sm: "0",
                                  md: "0",
                                  lg: "25px",
                                  xl: "25px",
                                },
                              }}
                            >
                              Senior
                            </Typography>

                            <img
                              src={senior}
                              alt="melo logo"
                              style={{
                                height: "30px",
                              }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "0px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Subject Name
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:'small'}}>
                    Subject Name
                  </InputLabel> */}
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.subject}
                name="subject"
                label="Subject"
                onChange={handleInputChange}
              >
                {Object.keys(subjectField).map(
                  (subjectKey: any) =>
                    Array.isArray(subjectField[subjectKey]) &&
                    subjectField[subjectKey].map((subject: any) => {
                      const isDisabled = studentNames.some(
                        (student: any) =>
                          student.subjectName === subject &&
                          student.class === activeClassButton
                      );
                      return (
                        <MenuItem
                          key={subject}
                          value={subject}
                          disabled={isDisabled}
                        >
                          {subject}
                        </MenuItem>
                      );
                    })
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {formData.subject === "Others" && (
          <>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "5px 0px 5px 0px" }}
              >
                <Typography sx={{ fontSize: "12px" }}>
                  Subject Name <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <TextField
                  size="small"
                  id="outlined-multiline-flexible"
                  label=""
                  name="otherSubject"
                  multiline
                  rows={1}
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ p: "5px 0px 5px 0px" }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Is this an Honor's or AP class?*{" "}
          </Typography>
          <RadioGroup
            aria-label="subjectType"
            name="subject-buttons"
            value={activeTypeButton}
            onChange={(e) => setActiveTypeButton(e.target.value)}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", p: "5px 10px   0px 0px" }}
            >
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                lg={2}
                xl={2}
                sx={{
                  p: {
                    xs: "0px 10px 0px 0px",
                    sm: "0px 10px 0px 0px",
                    md: "0px 10px 0px 10px",
                    lg: "0px 10px 0px 10px",
                    xl: "0px 10px 0px 10px",
                  },
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    subjectType: "No",
                  })
                }
              >
                <Grid
                  item
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value="No"
                    control={<RadioBut1 />}
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            m: "2px",
                            p: "2px",
                            borderRadius: {
                              xs: "0",
                              sm: "0",
                              md: "0",
                              lg: "25px",
                              xl: "25px",
                            },
                          }}
                        >
                          No
                        </Typography>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                lg={2}
                xl={2}
                sx={{
                  p: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 10px 0px 10px",
                    lg: "0px 10px 0px 10px",
                    xl: "0px 10px 0px 10px",
                  },
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    subjectType: "Honors",
                  })
                }
              >
                <Grid
                  item
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value="Honors"
                    control={<RadioBut1 />}
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            m: "2px",
                            p: "2px",
                            borderRadius: {
                              xs: "0",
                              sm: "0",
                              md: "0",
                              lg: "25px",
                              xl: "25px",
                            },
                          }}
                        >
                          Honors
                        </Typography>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                lg={2}
                xl={2}
                sx={{
                  p: {
                    xs: "0px 0px 0px 10px",
                    sm: "0px 0px 0px 10px",
                    md: "0px 0px 0px 10px",
                    lg: "0px 0px 0px 10px",
                    xl: "0px 0px 0px 10px",
                  },
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    subjectType: "AP",
                  })
                }
              >
                <Grid
                  item
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value="AP"
                    control={<RadioBut1 />}
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            m: "2px",
                            p: "2px",
                            borderRadius: {
                              xs: "0",
                              sm: "0",
                              md: "0",
                              lg: "25px",
                              xl: "25px",
                            },
                          }}
                        >
                          AP
                        </Typography>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            p: "5px 0px 0px 0px",
          }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                p: "0px 20px 0px 0px",
              }}
            >
              Exam Type:*
            </Typography>
            <FormControlLabel
              control={<RadioBut color="primary" />}
              label="Quarter"
              labelPlacement="end"
              value="quarter"
              checked={activeExamButton === "quarter"}
              onChange={handleExamButtonChange}
              sx={{ ...logRegStyle.roleButton }}
            />
            <FormControlLabel
              control={<RadioBut color="primary" />}
              label="Semester"
              labelPlacement="end"
              value="semester"
              checked={activeExamButton === "semester"}
              onChange={handleExamButtonChange}
              sx={{ ...logRegStyle.roleButton }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ p: "0px 0px 5px 0px" }}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "0px 0px 5px 0px" }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              Grades
              <span style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>
          {activeExamButton === "semester" ? (
            <>
              <Grid
                container
                sx={{
                  display: "flex",
                  // alignItems: "center",
                  // justifyContent: "space-evenly",
                }}
              >
                <Grid sx={{ p: "0px 5px 0px 5px" }}>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Semester 1
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.s1}
                      label="s1"
                      name="s1"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl sx={{ width: "100px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: "12px" }}
                    >
                      Semester 2
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.s2}
                      label="S2"
                      name="s2"
                      onChange={handleInputChange}
                    >
                      {Object.keys(gradesField).map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid sx={{ p: "0px 5px 5px 5px" }}>
                <FormControl sx={{ width: "100px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: "12px" }}
                  >
                    Quarter 1
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.q1}
                    label="Q1"
                    name="q1"
                    onChange={handleInputChange}
                  >
                    {Object.keys(gradesField).map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ p: "0px 5px 0px 5px" }}>
                <FormControl sx={{ width: "100px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: "12px" }}
                  >
                    Quarter 2
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.q2}
                    label="Q2"
                    name="q2"
                    onChange={handleInputChange}
                  >
                    {Object.keys(gradesField).map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ p: "0px 5px 0px 5px" }}>
                <FormControl sx={{ width: "100px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: "12px" }}
                  >
                    Quarter 3
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.q3}
                    label="Q3"
                    name="q3"
                    onChange={handleInputChange}
                  >
                    {Object.keys(gradesField).map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ p: "0px 5px 0px 5px" }}>
                <FormControl sx={{ width: "100px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: "12px" }}
                  >
                    Quarter 4
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.q4}
                    label="Q4"
                    name="q4"
                    onChange={handleInputChange}
                  >
                    {Object.keys(gradesField).map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid container>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              sx={{
                p: "10px 0px 0px 0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  p: "0px 5px 0px 5px",
                }}
              >
                AP Score:
              </Typography>
              <TextField
                size="small"
                id="outlined-basic"
                label="AP"
                name="ap"
                onChange={handleInputChange}
                variant="outlined"
                sx={{ width: "100px", p: "0px 5px 0px 5px" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              sx={{
                p: "10px 0px 0px 0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  p: "0px 5px 0px 5px",
                }}
              >
                Final Grade:
              </Typography>
              <Grid sx={{ p: "0px 5px 0px 5px" }}>
                <FormControl sx={{ width: "100px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: "12px" }}
                  >
                    Final Grade
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.finalScore}
                    label="finalScore"
                    name="finalScore"
                    onChange={handleInputChange}
                  >
                    {Object.keys(gradesField).map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              sx={{
                p: "10px 0px 10px 0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  p: "0px 5px 0px 5px",
                }}
              >
                Unweighted GPA:
              </Typography>
              <TextField
                size="small"
                id="outlined-basic"
                name="total_GPA"
                value={averageGpa}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ width: "100px", color: "black", p: "0px 5px 0px 5px" }}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              sx={{
                p: "10px 0px 10px 0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  p: "0px 5px 0px 5px",
                }}
              >
                Weighted GPA:
              </Typography>
              <TextField
                size="small"
                id="outlined-basic"
                name="weighted_GPA"
                value={weightedGpa}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ width: "100px", color: "black", p: "0px 5px 0px 5px" }}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container sx={{ p: "5px 0px 0px 0px" }}>
            <Grid
              container
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              alignItems="center"
            >
              {!isFormComplete && (
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    p: "0px 5px",
                  }}
                >
                  <InfoIcon
                    sx={{
                      mr: 1,
                      color: constants.tertiary,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: constants.tertiary,
                      mr: 1,
                    }}
                  >
                    Please Fill out Required (*) Fields
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
            <Grid
              container
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: "0px 5px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSave}
                  style={{
                    backgroundColor: isFormComplete
                      ? constants.tertiary
                      : "default",
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GradeFormComponent;
