import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import constants from "../constants/constant";
import { Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertTestAddDialogSlide({ open, onClose, data }: any) {
  const handleClose = (result: any, data: any) => {
    onClose(result, data);
  };

  const [formData, setFormData] = useState<any>({
    test: data,
    english: 0,
    maths: 0,
    reading: 0,
    science: 0,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let newValue: any = parseInt(value, 10);

    if (isNaN(newValue)) {
      newValue = "";
    } else if (newValue < 0) {
      newValue = 0;
    }

    setFormData((prevFormData: any) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      if (
        prevFormData.test === "ACT" &&
        ((updatedFormData.english !== "" && updatedFormData.english > 36) ||
          (updatedFormData.maths !== "" && updatedFormData.maths > 36) ||
          (updatedFormData.science !== "" && updatedFormData.science > 36) ||
          (updatedFormData.reading !== "" && updatedFormData.reading > 36))
      ) {
        toast.error("Scores cannot exceed 36");
        updatedFormData[name] = 0;
      } else if (
        prevFormData.test === "SAT" &&
        ((updatedFormData.maths !== "" && updatedFormData.maths > 800) ||
          (updatedFormData.reading !== "" && updatedFormData.reading > 800))
      ) {
        toast.error("Scores cannot exceed 800");
        updatedFormData[name] = 0;
      }

      return updatedFormData;
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(false, formData)}
      aria-describedby="alert-dialog-slide-description"
      sx={{ "& .MuiDialog-paper": { width: "300px" } }}
    >
      <DialogTitle>Add {data} Test Scores</DialogTitle>
      <DialogContent>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={2}
          sx={{ p: "25px 0px 5px 0px" }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // spacing={2}
            sx={{ p: "0px 0px 0px 15px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "10px 0px 0px 0px" }}
              >
                <Typography sx={{ fontSize: "12px" }}>
                  Maths Score
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <TextField
                size="small"
                id="outlined-basic"
                name="maths"
                variant="outlined"
                fullWidth
                sx={{ fontSize: "12px" }}
                type="number"
                value={formData.maths}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 0px 0px 0px" }}
            >
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={{ fontSize: "12px" }}>
                  Reading / Writing Score
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <TextField
                size="small"
                id="outlined-basic"
                name="reading"
                variant="outlined"
                fullWidth
                sx={{ fontSize: "12px" }}
                type="number"
                value={formData.reading}
                onChange={handleInputChange}
              />
            </Grid>
            {data !== "SAT" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 0px 0px 0px" }}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontSize: "12px" }}>
                      English Score
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    name="english"
                    variant="outlined"
                    fullWidth
                    sx={{ fontSize: "12px" }}
                    type="number"
                    value={formData.english}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "10px 0px 0px 0px" }}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontSize: "12px" }}>
                      Science Score
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    name="science"
                    variant="outlined"
                    fullWidth
                    sx={{ fontSize: "12px" }}
                    type="number"
                    value={formData.science}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => handleClose(false, formData)}
          sx={{ color: constants.tertiary }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "10px",
            borderColor: constants.tertiary,
            border: "1px solid #FE8D02",
            fontSize: "14px",
            color: "white",
            background:
              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            backgroundSize: "cover",
            "&:hover": {
              background:
                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            },
          }}
          onClick={() => handleClose(true, formData)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertTestAddDialogSlide;
