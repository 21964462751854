import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import ProfileMenu from "../layouts/ProfileMenu";
import AdMenu from "../layouts/AdMenu";
import { getStudentData } from "../redux/slices/studentDataSlice";
import { getStudentImage } from "../redux/slices/studentImageSlice";
import { getStudentExam } from "../redux/slices/studentExamSlice";
import { getStudentEca } from "../redux/slices/studentEcaSlice";
import { getStudentCs } from "../redux/slices/studentCsSlice";
import { getStudentAwards } from "../redux/slices/studentAwardsSlice";
import { toast } from "react-toastify";
// import ToggleButtons from "../layouts/ToggleButtons";
import AgreementMenu from "../layouts/AgreementMenu";
import { getPosts } from "../redux/slices/postDataSlice";
import PostsComponent from "../components/PostsComponent";
import { getAllStudentsData } from "../redux/slices/allStudentSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import GoProfile from "../layouts/GoProfile";
import { getStudentFiles } from "../redux/slices/studentFilesSlice";

const SummaryPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  // const studentData = useSelector((state: any) => state.studentDetails);
  const dispatch = useDispatch();
  const state = localStorage.getItem("accessToken");
  useEffect(() => {
    if (state) {
      try {
        dispatch(getStudentData());
        dispatch(getStudentImage());
        dispatch(getStudentExam());
        dispatch(getStudentEca());
        dispatch(getStudentCs());
        dispatch(getStudentAwards());
        dispatch(getPosts());
        dispatch(getAllStudentsData());
         dispatch(getStudentFiles());
      } catch (error: any) {
        // 50x code will come here
        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error Logging in user:", error.message);
          toast.error(error.message);
        }
      }
    }
  }, [dispatch, state]);

  return (
    <>
      {/* <Hero studentData={studentData} /> */}
      {/* <ToggleButtons /> */}
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          sx={{
            margin: {
              xs: "",
              sm: "",
              md: "20px 0px 10px 100px",
              lg: "20px 0px 10px 100px",
              xl: "20px 0px 10px 100px",
            },
          }}
        >
          <ProfileMenu />
          <GoProfile />
          {isMobile || isTablet ? (
            <></>
          ) : (
            <>
              <AgreementMenu />
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            margin: {
              xs: "",
              sm: "",
              md: "20px 0px 10px 0px",
              lg: "20px 0px 10px 0px",
              xl: "20px 0px 10px 0px",
            },
          }}
        >
          <PostsComponent />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          sx={{
            margin: {
              xs: "",
              sm: "",
              md: "20px 100px 10px 0px",
              lg: "20px 100px 10px 0px",
              xl: "20px 100px 10px 0px",
            },
          }}
        >
          <AdMenu />
          <AdMenu />
        </Grid>
        {(isMobile || isTablet) && (
          <>
            <AgreementMenu />
          </>
        )}
      </Grid>
    </>
  );
};

export default SummaryPage;
