import { Button, Grid, Paper, Typography } from "@mui/material";
import rcmd from "../assets/images/formVector/rcmd.png";
import grades from "../assets/images/formVector/grades.png";
import eca from "../assets/images/formVector/eca.png";
import cs from "../assets/images/formVector/cs.png";
import { useNavigate } from "react-router-dom";
const OptionsFormComponent = () => {
  const navigate = useNavigate();

  const handleGrades = () => {
    navigate("../grades");
  };

  const handleEca = () => {
    navigate("../extracurricularactivities");
  };
  const handleAwardsHonors = () => {
    navigate("../awardspage");
  };
  const handleCommunity = () => {
    navigate("../communityservices");
  };
  const handleSummary = () => {
    navigate("../home");
  };
  return (
    <>
      <Grid container xs={8} sm={8} md={8} lg={8} xl={8}>
        <Paper
          elevation={3}
          sx={{
            background: "white",
            borderRadius: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "25px",
              xl: "25px",
            },
            margin: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "25px",
              xl: "25px",
            },
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(100vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
            height: {
              xs: "calc(30vh - 100px)",
              sm: "calc(30vh - 100px)",
              md: "calc(30vh - 100px)",
              lg: "calc(87vh - 100px)",
              xl: "calc(87vh - 100px)",
            },
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "center",
            },
            p: {
              xs: "30px",
              sm: "30px",
              md: "30px",
              lg: "20px 20px 0px 50px",
              xl: "20px 20px 0px 50px",
            },
          }}
        >
          <Grid
            container
            sx={{
              p: "30px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Paper
                sx={{
                  cursor: "pointer",
                  background: "#2D438E",
                  borderRadius: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "25px",
                    xl: "25px",
                  },
                  width: {
                    xs: "calc(100vw - 100px)",
                    sm: "calc(100vw - 100px)",
                    md: "calc(100vw - 100px)",
                    lg: "calc(30vw - 100px)",
                    xl: "calc(30vw - 100px)",
                  },
                  height: {
                    xs: "calc(30vh - 100px)",
                    sm: "calc(30vh - 100px)",
                    md: "calc(30vh - 100px)",
                    lg: "calc(40vh - 100px)",
                    xl: "calc(40vh - 100px)",
                  },
                }}
              >
                <Grid
                  sx={{
                    p: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={handleGrades}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    GRADES
                  </Typography>
                  <img
                    src={grades}
                    alt="melo logo"
                    style={{
                      height: "100px",
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Paper
                sx={{
                  cursor: "pointer",
                  background: "#2D438E",
                  borderRadius: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "25px",
                    xl: "25px",
                  },
                  width: {
                    xs: "calc(100vw - 100px)",
                    sm: "calc(100vw - 100px)",
                    md: "calc(100vw - 100px)",
                    lg: "calc(30vw - 100px)",
                    xl: "calc(30vw - 100px)",
                  },
                  height: {
                    xs: "calc(30vh - 100px)",
                    sm: "calc(30vh - 100px)",
                    md: "calc(30vh - 100px)",
                    lg: "calc(40vh - 100px)",
                    xl: "calc(40vh - 100px)",
                  },
                }}
              >
                <Grid
                  sx={{
                    p: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={handleEca}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    EXTRACURRICULAR ACTIVITIES
                  </Typography>
                  <img
                    src={rcmd}
                    alt="melo logo"
                    style={{
                      height: "100px",
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container sx={{ p: "30px 0px 0px 0px" }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Paper
                sx={{
                  cursor: "pointer",
                  background: "#2D438E",
                  borderRadius: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "25px",
                    xl: "25px",
                  },
                  width: {
                    xs: "calc(100vw - 100px)",
                    sm: "calc(100vw - 100px)",
                    md: "calc(100vw - 100px)",
                    lg: "calc(30vw - 100px)",
                    xl: "calc(30vw - 100px)",
                  },
                  height: {
                    xs: "calc(30vh - 100px)",
                    sm: "calc(30vh - 100px)",
                    md: "calc(30vh - 100px)",
                    lg: "calc(40vh - 100px)",
                    xl: "calc(40vh - 100px)",
                  },
                }}
              >
                <Grid
                  sx={{
                    p: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={handleAwardsHonors}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    AWARDS & HONORS
                  </Typography>
                  <img
                    src={eca}
                    alt="melo logo"
                    style={{
                      height: "100px",
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Paper
                sx={{
                  cursor: "pointer",
                  background: "#2D438E",
                  borderRadius: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "25px",
                    xl: "25px",
                  },
                  width: {
                    xs: "calc(100vw - 100px)",
                    sm: "calc(100vw - 100px)",
                    md: "calc(100vw - 100px)",
                    lg: "calc(30vw - 100px)",
                    xl: "calc(30vw - 100px)",
                  },
                  height: {
                    xs: "calc(30vh - 100px)",
                    sm: "calc(30vh - 100px)",
                    md: "calc(30vh - 100px)",
                    lg: "calc(40vh - 100px)",
                    xl: "calc(40vh - 100px)",
                  },
                }}
              >
                <Grid
                  sx={{
                    p: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={handleCommunity}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    COMMUNITY SERVICES
                  </Typography>
                  <img
                    src={cs}
                    alt="melo logo"
                    style={{
                      height: "100px",
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                p: "30px 0px 0px 0px",
                display: "flex",
                // alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Grid sx={{ p: "0px 20px 0px 20px" }}></Grid>
              <Grid sx={{ p: "0px 40px 0px 20px" }}>
                <Button
                  variant="contained"
                  sx={{ alignItems: "flex-end" }}
                  onClick={handleSummary}
                >
                  Skip for Now
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default OptionsFormComponent;
