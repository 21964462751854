import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import constants from "../constants/constant";
import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import hoursList from "../assets/jsonData/hours.json";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertCsAddDialogSlide({ open, onClose, data }: any) {
  const hours: any = hoursList;


  const [formData, setFormData] = useState<any>({
    class: data,
    activity: "",
    desc: "",
    start_date:"",
    end_date: "",
    hours: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: any) => {
    setFormData({ ...formData, start_date: date });
  };
  const handleEndDateChange = (date: any) => {
    setFormData({ ...formData, end_date: date });
  };

  const handleClose = (result: any, data: any) => {
    onClose(result, data);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(false, formData)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Add {data} Community services</DialogTitle>
      <DialogContent>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Community Service Name
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <TextField
              size="small"
              id="outlined-basic"
              // label="Activity"
              name="activity"
              value={formData.activity}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              sx={{ fontSize: "12px" }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Duration in Hours(Hours Weekly)
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.hours}
              label="Hours"
              name="hours"
              onChange={handleInputChange}
              fullWidth
              // sx={{ width: "260px" }}
            >
              {Object.keys(hours).map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={2}
          sx={{ p: "10px 0px 40px 0px" }}
        >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                From Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ fontSize: "12px", height: "2px", tabSize: "small" }}
                name="start_date"
                value={formData.start_date}
                onChange={(date) => handleDateChange(date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "5px 0px 5px 0px" }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                To Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ fontSize: "12px", height: "2px", tabSize: "small" }}
                name="end_date"
                value={formData.end_date}
                onChange={(date) => handleEndDateChange(date)}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "5px 0px 5px 0px" }}
          >
            <Typography sx={{ fontSize: "12px" }}>
              Community Service Description
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "0px 15px 0px 0px" }}
          >
            <TextField
              size="small"
              id="outlined-basic"
              // label="Activity Description"
              name="desc"
              onChange={handleInputChange}
              variant="outlined"
              value={formData.desc}
              fullWidth
              multiline
              rows={4}
              sx={{ fontSize: "12px", p: "0px 0px 5px 0px" }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => handleClose(false, formData)}
          sx={{ color: constants.tertiary }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "10px",
            borderColor: constants.tertiary,
            border: "1px solid #FE8D02",
            fontSize: "14px",
            color: "white",
            background:
              "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            backgroundSize: "cover",
            "&:hover": {
              background:
                "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
            },
          }}
          onClick={() => handleClose(true, formData)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertCsAddDialogSlide;
