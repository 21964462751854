// APGradesTable.js
import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import http from "../config/http-common";
import { toast } from "react-toastify";

const IbGradesTable = ({ data }: any) => {


  const handleDelete = async (id: any) => {
    //   const updatedAwards = awards.filter((award: any) => award.id !== id);
    //   setAwards(updatedAwards);
    try {
      const response = await http.delete(`/api/grade/delete/${id}`);

      const result: any = response.data;
      console.log(result.response.data);
      // toast.success("Grades deleted Successfully!");
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      if (error.response) {
        console.error("Error creating user:", error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        console.error("Network error:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "150px",
                  borderRight: "2px solid #ccc",
                  borderLeft: "2px solid #ccc",
                  borderTop: "2px solid #ccc",
                  borderBottom: "2px solid #ccc",
                  backgroundColor: "#2D438E",
                  color: "white",
                }}
                align="center"
              >
                Subject
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "150px",
                  borderRight: "2px solid #ccc",
                  borderLeft: "2px solid #ccc",
                  borderTop: "2px solid #ccc",
                  borderBottom: "2px solid #ccc",
                  backgroundColor: "#2D438E",
                  color: "white",
                }}
                align="center"
              >
                S1
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "150px",
                  borderRight: "2px solid #ccc",
                  borderLeft: "2px solid #ccc",
                  borderTop: "2px solid #ccc",
                  borderBottom: "2px solid #ccc",
                  backgroundColor: "#2D438E",
                  color: "white",
                }}
                align="center"
              >
                S2
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "150px",
                  borderRight: "2px solid #ccc",
                  borderLeft: "2px solid #ccc",
                  borderTop: "2px solid #ccc",
                  borderBottom: "2px solid #ccc",
                  backgroundColor: "#2D438E",
                  color: "white",
                }}
                align="center"
              >
                Annual
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "150px",
                  borderRight: "2px solid #ccc",
                  borderLeft: "2px solid #ccc",
                  borderTop: "2px solid #ccc",
                  borderBottom: "2px solid #ccc",
                  backgroundColor: "#2D438E",
                  color: "white",
                }}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item: any, index: number) =>
                item.subject.map((subjectItem: any) => (
                  <TableRow key={subjectItem._id}>
                    {subjectItem.subjectName === "Others" ? (
                      <>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            maxWidth: "100px",
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderRight: "2px solid #ccc",
                            borderLeft: "2px solid #ccc",
                            borderTop: "2px solid #ccc",
                            borderBottom: "2px solid #ccc",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f8ff" : "#FFFFFF",
                          }}
                          align="center"
                        >
                          {subjectItem.otherSubjectName}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            maxWidth: "100px",
                            whiteSpace: "normal",
                            textAlign: "center",
                            borderRight: "2px solid #ccc",
                            borderLeft: "2px solid #ccc",
                            borderTop: "2px solid #ccc",
                            borderBottom: "2px solid #ccc",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f8ff" : "#FFFFFF",
                          }}
                          align="center"
                        >
                          {subjectItem.subjectName}
                        </TableCell>
                      </>
                    )}
                    {/* {data && data.classType === "AP" && (
                        <></>
                    )} */}
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        maxWidth: "100px",
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderRight: "2px solid #ccc",
                        borderLeft: "2px solid #ccc",
                        borderTop: "2px solid #ccc",
                        borderBottom: "2px solid #ccc",
                        backgroundColor:
                          index % 2 === 0 ? "#f0f8ff" : "#FFFFFF",
                      }}
                      align="center"
                    >
                      {item.examDivision === "semester"
                        ? subjectItem.semester.s1
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        maxWidth: "100px",
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderRight: "2px solid #ccc",
                        borderLeft: "2px solid #ccc",
                        borderTop: "2px solid #ccc",
                        borderBottom: "2px solid #ccc",
                        backgroundColor:
                          index % 2 === 0 ? "#f0f8ff" : "#FFFFFF",
                      }}
                      align="center"
                    >
                      {item.examDivision === "semester"
                        ? subjectItem.semester.s2
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        maxWidth: "100px",
                        whiteSpace: "normal",
                        textAlign: "center",
                        borderRight: "2px solid #ccc",
                        borderLeft: "2px solid #ccc",
                        borderTop: "2px solid #ccc",
                        borderBottom: "2px solid #ccc",
                        backgroundColor:
                          index % 2 === 0 ? "#f0f8ff" : "#FFFFFF",
                      }}
                      align="center"
                    >
                      {(parseFloat(subjectItem.semester.s1) +
                        parseFloat(subjectItem.semester.s2)) /
                        2}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderRight: "2px solid #ccc",
                        borderLeft: "2px solid #ccc",
                        borderTop: "2px solid #ccc",
                        borderBottom: "2px solid #ccc",
                        backgroundColor:
                          index % 2 === 0 ? "#f0f8ff" : "#FFFFFF",
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() => handleDelete(item._id)}
                        variant="contained"
                        color="error"
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default IbGradesTable;
