import {
  Avatar,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import http from "../config/http-common";
import { useNavigate } from "react-router-dom";
import constants from "../constants/constant";
import usStateCity from "../assets/jsonData/usStateCity.json";
import classList from "../assets/jsonData/class.json";
import schoolData from "../assets/jsonData/schoolData.json";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import junior from "../assets/images/formVector/jnr.png";
import senior from "../assets/images/formVector/snr.png";
import francisp from "../assets/images/schoolLogo/francisp.png";
import jonesp from "../assets/images/schoolLogo/jonesp.png";
import lanep from "../assets/images/schoolLogo/lanep.png";
import latin from "../assets/images/schoolLogo/latin.png";
import newtrierp from "../assets/images/schoolLogo/newtrierp.png";
import northsidep from "../assets/images/schoolLogo/northsidep.png";
import walterp from "../assets/images/schoolLogo/walterp.png";
import whitneyp from "../assets/images/schoolLogo/whitneyp.png";
import SchoolIcon from "@mui/icons-material/School";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StudentFormEditComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const stateCity: any = usStateCity;
  const schoolAdd: any = schoolData;
  const classData: any = classList;
  const [data, setData] = useState<any>();
  const [imgData, setImgData] = useState<any>();
  const [image, setImage] = useState<any>(null);
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear + 26; year++) {
    years.push(year);
  }

  const studentDetails = useSelector((state: any) => state.studentDetails);
  const studentImg = useSelector((state: any) => state.studentImage);

  const [formData, setFormData] = useState<any>({
    student_id: "",
    first_name: "",
    last_name: "",
    state: "",
    city: "",
    school: "",
    other_school: "",
    school_address: "",
    school_address2: "",
    class_year: "",
    graduating_class: "",
    isVerified: false,
    accountType: "public",
    bio: "",
    zipCode: "",
  });

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (studentDetails?.data) {
      setData(studentDetails?.data);
    }
    if (studentImg?.data) {
      setImgData(studentImg?.data);
    }
  }, [studentDetails, studentImg]);

  const handleCancel = async (event: any) => {
    event.preventDefault();
    navigate("../account");
  };

  useEffect(() => {
    if (data) {
      const date = new Date(data?.class_year);
      const year = date.getUTCFullYear();
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        first_name: data?.first_name || "",
        last_name: data?.last_name || "",
        student_id: data?.student_id || "",
        state: data?.state || "",
        city: data?.city || "",
        school: data?.school || "",
        other_school: data?.other_school || "",
        school_address: data?.school_address || "",
        school_address2: data?.school_address2 || "",
        class_year: year || "",
        graduating_class: data?.graduating_class || "",
        bio: data?.bio || "",
        zipCode: data?.zipCode || "",
      }));
    }
  }, [data]);

  //   const date = new Date(data?.class_year);
  //   const year = date.getUTCFullYear();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "school") {
      setFormData({
        ...formData,
        [name]: value,
        school_address: schoolAdd[value] || "",
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleRemove = () => {
    setImage(null);
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      const response = await http.post("/api/studentform", formData);
      if (image) {
        const imageResponse = await http.post("/api/student-image/upload", {
          image,
        });
        console.log(imageResponse);
      }
      console.log(response);

      // toast.success("Student Image Data Saved Successfully");
      navigate("../home");
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            width: {
              xs: "calc(100vw - 100px)",
              sm: "calc(100vw - 100px)",
              md: "calc(55vw - 100px)",
              lg: "calc(55vw - 100px)",
              xl: "calc(55vw - 100px)",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ p: "20px 0px 0px 0px" }}
          >
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                xs={6}
                sm={6}
                md={8}
                lg={8}
                xl={8}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography sx={{ fontWeight: "bold", color: constants.text }}>
                  Account
                </Typography>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={2}
                lg={2}
                xl={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  sx={{ color: "black" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                xs={3}
                sm={3}
                md={2}
                lg={2}
                xl={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    borderColor: constants.tertiary,
                    border: "1px solid #FE8D02",
                    fontSize: isMobile ? "0.875rem" : "1rem",
                    color: "white",
                    background:
                      "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                    backgroundSize: "cover",
                    padding: isMobile ? "6px 16px" : "8px 24px",
                    "&:hover": {
                      background:
                        "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                    },
                  }}
                  onClick={handleSave}
                >
                  save
                </Button>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography sx={{ color: constants.typo }}>Photo</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {image ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={12}
                        lg={12}
                        xl={12}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        sx={{ p: "20px 0px 20px 0px" }}
                      >
                        <Avatar
                          alt=""
                          src={image}
                          sx={{ width: 100, height: 100 }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={12}
                        lg={12}
                        xl={12}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        sx={{ p: "20px 0px 20px 0px" }}
                      >
                        <Avatar
                          alt=""
                          src={imgData}
                          sx={{ width: 100, height: 100 }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  xs={6}
                  sm={6}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{ p: "30px 0px 0px 0px" }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      p: "20px 0px 0px 0px",
                    }}
                  >
                    <Button
                      size="small"
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      sx={{ fontSize: "8px" }}
                    >
                      Upload New
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </Button>
                    {image && (
                      <>
                        <Grid sx={{ p: "2px 0px 0px 0px" }}>
                          <Button
                            size="small"
                            component="label"
                            variant="outlined"
                            sx={{ fontSize: "8px", color: "red" }}
                            fullWidth
                            onClick={handleRemove}
                          >
                            Remove
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex" }}
            >
              <Grid
                xs={6}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  p: "5px 0px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  First Name{" "}
                </Typography>
                <>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      p: {
                        xs: "0px 10px 0px 0px",
                        sm: "0px 10px 0px 0px",
                        md: "0px 50px 0px 0px",
                        lg: "0px 50px 0px 0px",
                        xl: "0px 50px 0px 0px",
                      },
                    }}
                  >
                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="first_name"
                      value={formData?.first_name}
                      onChange={handleInputChange}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              </Grid>
              <Grid
                xs={6}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  p: "5px 0px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  Last Name :{" "}
                </Typography>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    p: {
                      xs: "0px 10px 0px 0px",
                      sm: "0px 10px 0px 0px",
                      md: "0px 50px 0px 0px",
                      lg: "0px 50px 0px 0px",
                      xl: "0px 50px 0px 0px",
                    },
                  }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    name="last_name"
                    value={formData?.last_name}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex" }}
            >
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ p: "5px 0px 5px 0px" }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  Student ID :{" "}
                </Typography>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    p: {
                      xs: "0px 10px 0px 0px",
                      sm: "0px 10px 0px 0px",
                      md: "0px 50px 0px 0px",
                      lg: "0px 50px 0px 0px",
                      xl: "0px 50px 0px 0px",
                    },
                  }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    // label="Student ID"
                    name="student_id"
                    value={formData?.student_id}
                    onChange={handleInputChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RecentActorsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{ p: "5px 0px 5px 0px" }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  Class :{" "}
                </Typography>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ p: "0px 50px 0px 0px" }}
                >
                  <FormControl
                    sx={{
                      width: {
                        xs: "120px",
                        sm: "120px",
                        md: "150px",
                        lg: "150px",
                        xl: "150px",
                      },
                    }}
                  >
                    {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData?.graduating_class}
                      label="graduating_class"
                      name="graduating_class"
                      onChange={handleInputChange}
                    >
                      {Object.keys(classData).map((cls) => (
                        <MenuItem key={cls} value={cls}>
                          {cls && cls === "Freshman" && (
                            <img
                              src={freshman}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls && cls === "Sophmore" && (
                            <img
                              src={sophmore}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls && cls === "Junior" && (
                            <img
                              src={junior}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls && cls === "Senior" && (
                            <img
                              src={senior}
                              alt={cls}
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                          )}
                          {cls}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: {
                    xs: "5px 0px 5px 0px",
                    sm: "5px 0px 5px 0px",
                    md: "5px 0px 5px 0px",
                    lg: "5px 0px 5px 0px",
                    xl: "5px 0px 5px 0px",
                  },
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  Class of :{" "}
                </Typography>
                <FormControl
                  sx={{
                    width: {
                      xs: "135px",
                      sm: "135px",
                      md: "100px",
                      lg: "100px",
                      xl: "100px",
                    },
                  }}
                >
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.class_year}
                    label="Class year"
                    name="class_year"
                    onChange={handleInputChange}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={9}
              lg={9}
              xl={9}
              sx={{ display: "flex" }}
            >
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "5px 0px 5px 0px" }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  Bio :{" "}
                </Typography>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    p: {
                      xs: "0px 10px 0px 0px",
                      sm: "0px 10px 0px 0px",
                      md: "0px 100px 0px 0px",
                      lg: "0px 100px 0px 0px",
                      xl: "0px 100px 0px 0px",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    name="bio"
                    value={formData?.bio}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* school data */}
            <Divider />

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex" }}
            >
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  p: "5px 0px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  School Name{" "}
                </Typography>
                {formData?.school !== "Other School" && (
                  <>
                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label">
                      School Name
                    </InputLabel> */}
                      <Select
                        sx={{
                          width: {
                            xs: "150px",
                            sm: "150px",
                            md: "255px",
                            lg: "255px",
                            xl: "255px",
                          },
                        }}
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.school}
                        name="school"
                        label="School Name"
                        onChange={handleInputChange}
                      >
                        {Object.keys(schoolAdd).map((school) => (
                          <MenuItem key={school} value={school}>
                            {school && school === "Jones College Prep" && (
                              <img
                                src={jonesp}
                                alt="school logo"
                                style={{ width: "15px", marginRight: "10px" }}
                              />
                            )}
                            {school &&
                              school ===
                                "Whitney M. Young Magnet Highschool" && (
                                <img
                                  src={whitneyp}
                                  alt="school logo"
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                            {school &&
                              school ===
                                "Lane Tech College Prep High School" && (
                                <img
                                  src={lanep}
                                  alt="school logo"
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                            {school &&
                              school ===
                                "Walter Payton College Prepratory Highschool" && (
                                <img
                                  src={walterp}
                                  alt="school logo"
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                            {school && school === "Northside College Prep" && (
                              <img
                                src={northsidep}
                                alt="school logo"
                                style={{
                                  width: "15px",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            {school && school === "Latin School Of Chicago" && (
                              <img
                                src={latin}
                                alt="school logo"
                                style={{
                                  width: "15px",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            {school && school === "Francis W Parker School" && (
                              <img
                                src={francisp}
                                alt="school logo"
                                style={{
                                  width: "15px",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            {school &&
                              school === "New Trier Township Highschool" && (
                                <img
                                  src={newtrierp}
                                  alt="school logo"
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                            {school}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {formData?.school === "Other School" && (
                  <>
                    <TextField
                      sx={{
                        width: {
                          xs: "280px",
                          sm: "280px",
                          md: "255px",
                          lg: "255px",
                          xl: "255px",
                        },
                      }}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      name="other_school"
                      value={formData?.other_school}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SchoolIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex" }}
            >
              <Grid
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: "5px 0px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  State{" "}
                </Typography>
                <FormControl
                  sx={{
                    width: {
                      xs: "120px",
                      sm: "120px",
                      md: "150px",
                      lg: "150px",
                      xl: "150px",
                    },
                  }}
                >
                  {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.state}
                    label="State"
                    name="state"
                    onChange={handleInputChange}
                  >
                    {Object.keys(stateCity).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  p: "5px 0px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  City{" "}
                </Typography>
                <FormControl
                  sx={{
                    width: {
                      xs: "135px",
                      sm: "135px",
                      md: "150px",
                      lg: "150px",
                      xl: "150px",
                    },
                  }}
                >
                  {/* <InputLabel id="demo-simple-select-label">City</InputLabel> */}
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.city}
                    label="City"
                    name="city"
                    onChange={handleInputChange}
                  >
                    {stateCity[formData.state] &&
                      stateCity[formData.state].map((city: any) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  p: "5px 10px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  School Address{" "}
                </Typography>
                <TextField
                  sx={{
                    width: {
                      xs: "280px",
                      sm: "280px",
                      md: "240px",
                      lg: "240px",
                      xl: "240px",
                    },
                  }}
                  size="small"
                  id="outlined-multiline-flexible"
                  label=""
                  value={formData?.school_address}
                  name={"school_address"}
                  multiline
                  rows={1}
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  p: "5px 0px 5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  School Address Line 2(Optional){" "}
                </Typography>
                <TextField
                  sx={{
                    width: {
                      xs: "280px",
                      sm: "280px",
                      md: "240px",
                      lg: "240px",
                      xl: "240px",
                    },
                  }}
                  size="small"
                  id="outlined-multiline-flexible"
                  label=""
                  value={formData?.school_address2}
                  name={"school_address2"}
                  multiline
                  rows={1}
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  p: {
                    xs: "0px 10px 20px 0px",
                    sm: "0px 10px 20px 0px",
                    md: "0px 50px 20px 0px",
                    lg: "0px 50px 20px 0px",
                    xl: "0px 50px 20px 0px",
                  },
                }}
              >
                <Typography sx={{ fontSize: "12px", color: constants.typo }}>
                  ZIP Code{" "}
                </Typography>
                <TextField
                  size="small"
                  id="outlined-multiline-flexible"
                  label=""
                  value={formData?.zipCode}
                  name="zipCode"
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentFormEditComponent;
