import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../config/http-common";
// import { toast } from "react-toastify";

export interface studentDataSlice {
  data: {} | null;
  isDataFetched: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: studentDataSlice = {
  data: {},
  isDataFetched: false,
  loading: false,
  error: "",
};

export const getStudentData: any = createAsyncThunk("student", async () => {
  try {
    const res = await axiosInstance.get("/api/student/data/");
    return res.data.response.data[0];
  } catch (err: any) {
     localStorage.removeItem("accessToken");
     localStorage.removeItem("refreshToken");
     window.location.href = "/login";
    if (err.response.data.errorMessage) {
      console.log(err.response.data.errorMessage);
      // toast.error(err.response.data.errorMessage);
    }
    if (err.response.data.errMsg) {
      console.log(err.response.data.errMsg);
      // toast.error(err.response.data.errMsg);
    } else {
      console.log(err);
    }
  }
});

export const studentSlice = createSlice({
  name: "studentDetails",
  initialState,
  reducers: {
    remove: (state, action) => {
      state.data = {};
      state.isDataFetched = false;
      state.loading = false;
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStudentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getStudentData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.isDataFetched = true;
          state.data = action.payload;
        }
      )
      .addCase(getStudentData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isDataFetched = false;
        state.error = action.payload;
        state.data = {};
      });
  },
});

export const { remove } = studentSlice.actions;
export default studentSlice.reducer;
