import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import meloLogo from "../assets/images/logo/melo_logo.png";
import constants from "../constants/constant";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import http from "../config/http-common";
import { useNavigate } from "react-router-dom";
import usStateCity from "../assets/jsonData/usStateCity.json";
import classList from "../assets/jsonData/class.json";
import schoolData from "../assets/jsonData/schoolData.json";
import francisp from "../assets/images/schoolLogo/francisp.png";
import jonesp from "../assets/images/schoolLogo/jonesp.png";
import lanep from "../assets/images/schoolLogo/lanep.png";
import latin from "../assets/images/schoolLogo/latin.png";
import newtrierp from "../assets/images/schoolLogo/newtrierp.png";
import northsidep from "../assets/images/schoolLogo/northsidep.png";
import walterp from "../assets/images/schoolLogo/walterp.png";
import whitneyp from "../assets/images/schoolLogo/whitneyp.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoadingPage from "../utils/Loading";
import PersonIcon from "@mui/icons-material/Person";
import freshman from "../assets/images/formVector/freshman.png";
import sophmore from "../assets/images/formVector/sophmore.png";
import junior from "../assets/images/formVector/jnr.png";
import senior from "../assets/images/formVector/snr.png";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SchoolIcon from "@mui/icons-material/School";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StudentFormComponentV2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const navigate = useNavigate();
  const stateCity: any = usStateCity;
  const schoolAdd: any = schoolData;
  const classData: any = classList;

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear + 26; year++) {
    years.push(year);
  }
  const [formData, setFormData] = useState<any>({
    student_id: "",
    first_name: "",
    last_name: "",
    state: "",
    city: "",
    school: "",
    other_school: "",
    school_address: "",
    school_address2: "",
    class_year: "",
    graduating_class: "",
    isVerified: false,
    accountType: "public",
    bio: "",
    zipCode:""
  });

  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isStepComplete, setIsStepComplete] = useState(false);

  const steps = ["Student Info", "School Info"];

  useEffect(() => {
    const requiredKeys = [
      "student_id",
      "first_name",
      "state",
      "city",
      "school",
      "class_year",
      "school_address",
      "graduating_class",
    ];
    const stepKeys = [
      "student_id",
      "first_name",
      "class_year",
      "graduating_class",
    ];

    const checkFormCompleteness = () => {
      return requiredKeys.every(
        (key) => formData[key] !== "" && formData[key] !== null
      );
    };
    const checkStepCompleteness = () => {
      return stepKeys.every(
        (key) => formData[key] !== "" && formData[key] !== null
      );
    };

    setIsFormComplete(checkFormCompleteness());
    setIsStepComplete(checkStepCompleteness());
  }, [formData]);

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "school") {
      setFormData({
        ...formData,
        [name]: value,
        school_address: schoolAdd[value] || "",
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [image, setImage] = useState<any>(null);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    setImage(null);
  };

  const handleNextStep = () => {
    if (isStepComplete) {
      setActiveStep(1);
    } else {
      toast.error("fill out required fields");
    }
  };
  const handleBack = () => {
    setActiveStep(0);
  };

  const handleSave = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (isFormComplete) {
        const response = await http.post("/api/studentform", formData);
        if (image) {
          const imageResponse = await http.post("/api/student-image/upload", {
            image,
          });
          console.log(imageResponse);
        }
        console.log(response);

        //  toast.success("Student Details Saved");
        navigate("../setup-complete");
      } else {
        toast.error("fill out required fields");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  p: "0px 0px 0px 0px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={meloLogo}
                  alt="melo logo"
                  style={{
                    height: isMobile ? "40px" : isTablet ? "40px" : "60px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "10px 0px 0px 0px" }}
              >
                <Typography
                  sx={{
                    color: constants.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  Let's setup your account
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ p: "5px 0px 0px 0px" }}
              >
                <Typography
                  sx={{
                    color: constants.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  Help us to setup your account by filling in your details
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  color: constants.text,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </Grid>
              </Grid>
              {activeStep === 0 && (
                <>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: {
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "5px",
                        xl: "5px",
                      },
                      margin: {
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "20px",
                        xl: "20px",
                      },
                      maxWidth: {
                        lg: "440px",
                        xl: "440px",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "center",
                        xl: "center",
                      },
                    }}
                  >
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!image && (
                        <>
                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <AccountCircleIcon
                              sx={{
                                fontSize: "50px",
                                background: "white",
                                color: "#cccccc",
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      {image && (
                        <>
                          <img
                            src={image}
                            alt="Uploaded"
                            style={{ maxWidth: "100%", maxHeight: "50px" }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        p: "20px 0px 20px 0px",
                      }}
                    >
                      <Button
                        size="small"
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        sx={{ fontSize: "8px", width: "200px" }}
                      >
                        Upload Photo
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </Button>
                      {image && (
                        <>
                          <Grid sx={{ p: "2px 0px 0px 0px" }}>
                            <Button
                              size="small"
                              component="label"
                              variant="outlined"
                              sx={{ fontSize: "8px" }}
                              fullWidth
                              onClick={handleRemove}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Student ID"
                        name="student_id"
                        value={formData.student_id}
                        onChange={handleInputChange}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RecentActorsIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <Grid></Grid>
                      <FormControl sx={{ width: "280px" }}>
                        <InputLabel>Class</InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.graduating_class}
                          label="graduating_class"
                          name="graduating_class"
                          onChange={handleInputChange}
                        >
                          {Object.keys(classData).map((cls) => (
                            <MenuItem key={cls} value={cls}>
                              {cls && cls === "Freshman" && (
                                <img
                                  src={freshman}
                                  alt={cls}
                                  style={{ width: "15px", marginRight: "10px" }}
                                />
                              )}
                              {cls && cls === "Sophmore" && (
                                <img
                                  src={sophmore}
                                  alt={cls}
                                  style={{ width: "15px", marginRight: "10px" }}
                                />
                              )}
                              {cls && cls === "Junior" && (
                                <img
                                  src={junior}
                                  alt={cls}
                                  style={{ width: "15px", marginRight: "10px" }}
                                />
                              )}
                              {cls && cls === "Senior" && (
                                <img
                                  src={senior}
                                  alt={cls}
                                  style={{ width: "15px", marginRight: "10px" }}
                                />
                              )}
                              {cls}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          p: "0px 0px 0px 0px",
                        }}
                      >
                        <FormControl sx={{ width: "280px" }}>
                          <InputLabel id="demo-simple-select-label">
                            Class of
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.class_year}
                            label="Class year"
                            name="class_year"
                            onChange={handleInputChange}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <TextField
                        sx={{ width: "280px" }}
                        size="small"
                        id="outlined-multiline-flexible"
                        label="Bio (Optional)"
                        value={formData.bio}
                        name="bio"
                        multiline
                        rows={4}
                        fullWidth
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ p: "20px 80px 20px 80px" }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          borderRadius: "10px",
                          borderColor: constants.tertiary,
                          border: "1px solid #FE8D02",

                          color: "white",
                          background:
                            "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                          backgroundSize: "cover",
                          padding: isMobile ? "6px 16px" : "8px 24px",
                          "&:hover": {
                            background:
                              "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                          },
                        }}
                        fullWidth
                        onClick={handleNextStep}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: {
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "5px",
                        xl: "5px",
                      },
                      margin: {
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "20px",
                        xl: "20px",
                      },
                      maxWidth: {
                        lg: "440px",
                        xl: "440px",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "center",
                        xl: "center",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: constants.text,
                        fontWeight: "bold",
                        p: "20px 0px 10px 0px",
                      }}
                    >
                      School Details
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          p: "0px 0px 0px 0px",
                        }}
                      >
                        <FormControl sx={{ width: "280px" }}>
                          <InputLabel id="demo-simple-select-label">
                            School Name
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.school}
                            name="school"
                            label="School Name"
                            onChange={handleInputChange}
                          >
                            {Object.keys(schoolAdd).map((school) => (
                              <MenuItem key={school} value={school}>
                                {school && school === "Jones College Prep" && (
                                  <img
                                    src={jonesp}
                                    alt="school logo"
                                    style={{
                                      width: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                                {school &&
                                  school ===
                                    "Whitney M. Young Magnet Highschool" && (
                                    <img
                                      src={whitneyp}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school &&
                                  school ===
                                    "Lane Tech College Prep High School" && (
                                    <img
                                      src={lanep}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school &&
                                  school ===
                                    "Walter Payton College Prepratory Highschool" && (
                                    <img
                                      src={walterp}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school &&
                                  school === "Northside College Prep" && (
                                    <img
                                      src={northsidep}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school &&
                                  school === "Latin School Of Chicago" && (
                                    <img
                                      src={latin}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school &&
                                  school === "Francis W Parker School" && (
                                    <img
                                      src={francisp}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school &&
                                  school ===
                                    "New Trier Township Highschool" && (
                                    <img
                                      src={newtrierp}
                                      alt="school logo"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                {school}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {formData.school === "Other School" && (
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          p: "20px 2px 0px 0px",
                        }}
                      >
                        <FormControl>
                          <TextField
                            size="small"
                            id="outlined-basic"
                            label="School Name"
                            variant="outlined"
                            name="other_school"
                            onChange={handleInputChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SchoolIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {formData.school !== "Other School" ? (
                      <>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            p: "20px 0px 0px 0px",
                          }}
                        >
                          <TextField
                            sx={{ width: "280px" }}
                            size="small"
                            id="outlined-multiline-flexible"
                            label={
                              formData.school_address
                                ? ""
                                : "School Address Line 1"
                            }
                            value={schoolAdd[formData.school]}
                            name="school_address"
                            // multiline
                            // rows={4}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            p: "20px 0px 0px 0px",
                          }}
                        >
                          <TextField
                            sx={{ width: "280px" }}
                            size="small"
                            id="outlined-multiline-flexible"
                            label={
                              formData.school_address2
                                ? ""
                                : "School Address Line 2(optional)"
                            }
                            value={formData.school_address2}
                            name="school_address2"
                            // multiline
                            // rows={4}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            p: "20px 0px 0px 0px",
                          }}
                        >
                          <TextField
                            sx={{ width: "280px" }}
                            size="small"
                            id="outlined-multiline-flexible"
                            label="School Address Line 1"
                            name="school_address"
                            // multiline
                            // rows={4}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            p: "20px 0px 0px 0px",
                          }}
                        >
                          <TextField
                            sx={{ width: "280px" }}
                            size="small"
                            id="outlined-multiline-flexible"
                            label="School Address Line 2(Optional)"
                            name="school_address2"
                            // multiline
                            // rows={4}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}
                    {/* <Grid
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        p: {
                          xs: "20px 5px 0px 55px",
                          sm: "20px 5px 0px 55px",
                          md: "20px 5px 0px 80px",
                          lg: "20px 5px 0px 80px",
                          xl: "20px 5px 0px 80px",
                        },
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "12px", color: constants.text }}
                      >
                        State
                      </Typography>
                    </Grid> */}
                    {/* <Grid xs={6} sm={6} md={6} lg={6} xl={6} sx={{}}>
                      <Typography
                        sx={{ fontSize: "12px", color: constants.text }}
                      >
                        State
                      </Typography>
                    </Grid> */}
                    <Grid
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        p: {
                          xs: "20px 5px 0px 55px",
                          sm: "20px 5px 0px 55px",
                          md: "20px 5px 0px 80px",
                          lg: "20px 5px 0px 80px",
                          xl: "20px 5px 0px 80px",
                        },
                      }}
                    >
                      <FormControl sx={{ width: "285px" }}>
                        <InputLabel id="demo-simple-select-label">
                          State
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.state}
                          label="State"
                          name="state"
                          onChange={handleInputChange}
                        >
                          {Object.keys(stateCity)
                            .sort((a, b) => a.localeCompare(b))
                            .map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        p: {
                          xs: "20px 55px 0px 5px",
                          sm: "20px 55px 0px 5px",
                          md: "20px 80px 0px 5px",
                          lg: "20px 80px 0px 5px",
                          xl: "20px 80px 0px 5px",
                        },
                      }}
                    >
                      <FormControl sx={{ width: "255px" }}>
                        <InputLabel id="demo-simple-select-label">
                          City
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.city}
                          label="City"
                          name="city"
                          onChange={handleInputChange}
                        >
                          {stateCity[formData.state] &&
                            stateCity[formData.state]
                              .sort((a: string, b: string) =>
                                a.localeCompare(b)
                              )
                              .map((city: string) => (
                                <MenuItem key={city} value={city}>
                                  {city}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        p: "20px 0px 0px 0px",
                      }}
                    >
                      <TextField
                        sx={{ width: "280px" }}
                        size="small"
                        id="outlined-multiline-flexible"
                        label="zipCode"
                        value={formData.zipCode}
                        name="zipCode"
                        type="number"
                        // multiline
                        // rows={4}
                        fullWidth
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        p: "20px 20px 20px 0px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        variant="text"
                        sx={{
                          color: constants.tertiary,
                          padding: isMobile ? "6px 16px" : "8px 24px",
                          "&:hover": {
                            background:
                              "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                          },
                        }}
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          borderRadius: "10px",
                          borderColor: constants.tertiary,
                          border: "1px solid #FE8D02",

                          color: "white",
                          background:
                            "linear-gradient(360deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                          backgroundSize: "cover",
                          padding: isMobile ? "6px 16px" : "8px 24px",
                          "&:hover": {
                            background:
                              "linear-gradient(180deg, #FE8D02 11.51%, #FFF6EC 233.99%)",
                          },
                        }}
                        onClick={handleSave}
                      >
                        Finish Setup
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default StudentFormComponentV2;
