import { Grid } from "@mui/material";
import React from "react";
import constants from "../constants/constant";
import AdSenseComponent from "../components/AdSenseComponent";

const AdMenu = () => {
  return (
    <>
      <Grid>
        <Grid
          sx={{
            background: constants.seconday,
            borderRadius: "8px",
            margin: "10px",
            height: "300px",
            justifyContent: "center",
          }}
        >
          <AdSenseComponent />
        </Grid>
      </Grid>
    </>
  );
};

export default AdMenu;
