import React, { useEffect } from "react";

const AdSenseComponent = () => {
  useEffect(() => {
    try {
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Error loading adsbygoogle:", e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: " block" }}
      data-ad-client="ca-pub-6300637563870711"
      data-ad-slot="3754097814"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default AdSenseComponent;
