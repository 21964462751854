import { Divider, Grid, } from "@mui/material";
import React from "react";
import SummaryAwardsList from "../pages/SummaryAwardsList";
import SummaryCompetitionList from "../pages/SummaryCompetitionList";
import SummaryCsList from "../pages/SummaryCsList";
import SummaryGradesList from "../pages/SummaryGradesList";
import SummaryTestList from "../pages/SummaryTestList";

const SummaryDetailedViewComponent = ({ data }: any) => {
  const searchData = data;
  const awardsDetails = searchData?.awards || [];
  const ecaDetails = searchData?.eca || [];
  const CsDetails = searchData?.communityService || [];
  const examDetails = searchData?.exam || [];
  const testDetails = searchData?.test || [];

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        {examDetails && (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 0px 10px 0px" }}
            >
              <SummaryGradesList gradesData={examDetails} />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {testDetails && (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 0px 10px 0px" }}
            >
              <SummaryTestList testData={testDetails} />
            </Grid>
          </>
        )}

        {awardsDetails && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 0px 10px 0px" }}
            >
              <SummaryAwardsList awdData={awardsDetails} />
            </Grid>
          </>
        )}
        {ecaDetails && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 0px 10px 0px" }}
            >
              <SummaryCompetitionList ecaData={ecaDetails} />
            </Grid>
          </>
        )}
        {CsDetails && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ p: "10px 0px 10px 0px" }}
            >
              <SummaryCsList actData={CsDetails} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default SummaryDetailedViewComponent;
